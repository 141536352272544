import axios from "axios";
import TokenService from "./token.service";

const { REACT_APP_BASE_URL } = process.env;

const instance = axios.create({
  baseURL: REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  config => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
      // config.headers['Content-Type'] = 'application/json'
      if (config.url.includes("download?id=")) {
        config.responseType = "blob";
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;

    if (originalConfig.url !== "login" && err.response) {
      // Access Token was expired
      // eslint-disable-next-line eqeqeq
      if (err.response.status == 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("refreshtoken", {
            refresh_token: TokenService.getLocalRefreshToken(),
          });
          const access_token = rs.data.data.access_token;
          TokenService.updateLocalAccessToken(access_token);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
