import React, { useState, useEffect } from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import Logo from "../../img/logo.svg";
import axios from 'axios';
import { isMobile } from 'react-device-detect';


const Ijazahverify = () => {
    const [isvalid, setIsvalid] = useState(true)
    const [notFound, setNotFound] = useState(false)
    const [signer, setSigner] = useState({});
    const { id } = useParams();

    const getSignerIjazah = async () => {
        try {
            const response = await axios.post('docverify-siacloud', { hashpegawai: id })
            if (response.data.data !== null) {
                setSigner(response.data.data)
            } else {
                setIsvalid(false)
                setNotFound(true)
            }
        } catch (err) {
            console.log(err.response)
        }
    }

    useEffect(() => {
        getSignerIjazah()
    }, [])



    return (
        <Container>
            <Row
                className="justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Col md={8}>
                    <div className="text-center" style={{ marginTop: "20px" }}>
                        <img src={Logo} alt="SEVIMA" style={{ marginBottom: "9px" }} />
                        <p
                            style={{
                                fontWeight: "400",
                                marginBottom: "40px",
                                //   padding: "0px",
                                marginTop: "-10px",
                            }}
                        >
                            e-Sign System
                        </p>
                        <p style={{ fontWeight: "600", fontSize: "20px" }}>
                            Verifikasi Tanda Tangan Ijazah Elektronik
                        </p>
                    </div>
                    {isvalid ? (
                        <Card
                            style={{
                                border: "1px solid #DEE2E6",
                                borderRadius: "8px",
                                marginBottom: "20px",
                            }}
                        >
                            <Card.Body>
                                <div style={{ marginBottom: "12px" }}>
                                    <div className='text-center mb-3' style={{ fontWeight: '600', fontSize: '16px' }}>
                                        <span>
                                            Detail penandatangan:
                                        </span>
                                    </div>
                                    <div className='d-block' style={isMobile ? ({ margin: '0' }) : ({ margin: '0px 50px' })}>
                                        <Card>
                                            <Card.Body>
                                                <Row>
                                                    <Col md={5} xs={5}>
                                                        <span style={{ fontWeight: '600' }}>Nama</span>
                                                    </Col>
                                                    <Col>: {signer.nama_dosen}</Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md={5} xs={5}>
                                                        <span style={{ fontWeight: '600' }}>NIP</span>
                                                    </Col>
                                                    <Col>: {signer.nip}</Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md={5} xs={5}>
                                                        <span style={{ fontWeight: '600' }}>Jabatan</span>
                                                    </Col>
                                                    <Col>: {signer.role_dosen}</Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col md={5} xs={5}>
                                                        <span style={{ fontWeight: '600' }}>Nama Universitas</span>
                                                    </Col>
                                                    <Col>: {signer.nama_univ}</Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>

                                <p className='text-center mt-3'>Anda juga bisa verifikasi dokumen ini <br /> dengan cara upload file di <a href="http://verification.peruri.co.id" target="_blank" rel="noopener noreferrer">verification.peruri.ac.id</a></p>
                                <div
                                    className="text-center"
                                    style={{ fontStyle: "italic" }}
                                >
                                    <p>
                                        Tanda tangan telah terverifikasi oleh sistem Peruri
                                        sebagai otoritas penyedia layanan tanda tangan elektronik.
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    ) : (
                        <Alert className="text-center" variant="warning">
                            {notFound ? (
                                <>
                                    <Alert.Heading>Data tidak ditemukan!</Alert.Heading>
                                </>
                            ) : (
                                <>
                                    <Alert.Heading>Halaman masih dalam perbaikan!</Alert.Heading>
                                    <p>Silahkan verifikasi dokumen anda di <a href="http://verification.peruri.co.id" target="_blank" rel="noopener noreferrer">verification.peruri.ac.id</a></p>
                                </>
                            )}
                        </Alert>
                    )}
                </Col>
            </Row>
        </Container >
    )
}

export default Ijazahverify