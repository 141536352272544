import api from './api';

const getDocumentAll = () => {
    return api.get('document/all').then((response) => {
        return response
    })
}

const getDocumentProcess = () => {
    return api.get('document/all/proses')
}

const getDocumentTunda = () => {
    return api.get('document/all/tunda')
}

const sendDocument = (dokumen) => {
    return api.post('document/create', dokumen).then((response) => { return response })
}

const changeIsReadDocument = (id) => {
    return api.get(`document/read?status=true&id=${id}`).then((response) => { return response })
}

const getBase64Document = (id) => {
    return api.get(`document/view?id=${id}`).then((response) => {
        return response
    })
}

const getDocType = () => {
    return api.get('document/doctype').then((response) => {
        return response
    })
}

const createMeterai = (id, data) => {
    return api.post(`materai/create?id=${id}`, data).then((response) => {
        return response
    })
}

const sendDocumentToPeople = (id, data) => {
    return api.post(`document/send/doc?id=${id}&actiontype=sirkulir`, data).then((response) => {
        return response
    })
}

const deleteDocument = (id) => {
    return api.delete(`document?id=${id}`).then((response) => {
        return response
    })
}

const downloadDocument = (id) => {
    return api.get(`download?id=${id}`).then((response) => {
        return response
    })
}

const getSigner = (id) => {
    return api.get(`document/view/${id}/signer`).then((response) => {
        return response
    })
}

const setSignatureLocation = (id, data) => {
    return api.post(`setsignloc?id=${id}`, data).then((response) => {
        return response
    })
}

const getDocumentInformation = (id) => {
    return api.get(`document/view/${id}/information`).then((response) => {
        return response
    })
}

const handleChangePage = (id, page) => {
    return api.get(`document/read?id=${id}&page=${page}`).then((response) => {
        return response
    })
}

const DocumentService = {
    getDocumentProcess,
    getDocumentTunda,
    sendDocument,
    changeIsReadDocument,
    getBase64Document,
    getDocType,
    createMeterai,
    sendDocumentToPeople,
    getDocumentAll,
    deleteDocument,
    downloadDocument,
    getSigner,
    setSignatureLocation,
    getDocumentInformation,
    handleChangePage
}



export default DocumentService;