import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./components/register";
import Home from "./pages/home";
import Upload from "./pages/upload";
import Document from "./pages/document";
import axios from "axios";
import Success from "./pages/Success";
import Detail from "./pages/document/detail";
import Sign from "./pages/sign/index";
import Index from "./pages/login";
import Verify from "./pages/verify";
import Notfound from "./pages/notfound";
import Kontak from "./pages/kontak";
import Ekyc from "./pages/ekyc";
import Waiting from "./pages/ekyc/waiting";
import SignByEmail from "./pages/sign/signByEmail";
import Lupapassword from "./pages/login/lupaPassword";
import Ijazahverify from "./pages/verify/ijazahverify";
import Test from "./pages/test";

const { REACT_APP_BASE_URL } = process.env;
axios.defaults.baseURL = REACT_APP_BASE_URL;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/register/*" element={<Register />} />
        <Route path="/home" element={<Home />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/document" element={<Document />} />
        <Route path="/verification" element={<Success />} />
        <Route path="/document/details" element={<Detail />} />
        <Route path="/sign" element={<Sign />} />
        <Route path="/sign/seamless/*" element={<SignByEmail />} />
        <Route path="/contact" element={<Kontak />} />
        <Route path="/verify/:id" element={<Verify />} />
        <Route path="/verify-ijazah/:id" element={<Ijazahverify />} />
        <Route path="/*" element={<Notfound />} />
        <Route path="/ekyc" element={<Ekyc renew={false} />} />
        <Route path="/ekyc-renew" element={<Ekyc renew={true} />} />
        <Route path="/waiting" element={<Waiting />} />
        <Route path="/resetpassword/*" element={<Lupapassword />} />
        <Route path="/test/" element={<Test />} />
      </Routes>
    </Router>
  );
}

export default App;
