/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import NavbarUser from "../../components/layout/NavbarUser";
import "./Home.css";
import { useForm } from "../../hooks";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import { Badge, Avatar, List } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsPlusSquare } from "react-icons/bs";
import hand from "../../img/hand.svg";
import kirim from "../../img/dikirim_empty.svg";
import Document from "../../img/document-text.svg";
import Navbarpenandatangan from "../../components/layout/navbarpenandatangan";
import FormatDate from "../../components/formatDate";
import jwt_decode from "jwt-decode";
import CountExpired from "../../components/countExpired";
import DocumentService from "../../api/document.service";
import UserService from "../../api/user.service";
import TokenService from "../../api/token.service";


const defaultData = {
  iduser: 0,
  base64document: "",
  status: "tunda",
  actiontype: "sirkulir",
};

const Home = () => {
  // token
  const [payload] = useState(localStorage.getItem("user"));
  // const token = localStorage.getItem("token");
  const navigation = useNavigate();
  const [documentTunda, setDocumentTunda] = useState([])

  const [documentBase, setDocument] = useForm(defaultData);
  const { base64document, iduser } = documentBase;
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState("");
  const [upload, isUpload] = useState(false);
  const [doc, setDoc] = useState("");
  const [confirmLoading, setConfirmloading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [pending, setPending] = useState([]);
  const [notPdf, setNotPDF] = useState(false)
  const [user, setUser] = useState('')
  const [docUser, setDocUser] = useState([])

  useEffect(() => {
    if (payload) {
      const decodeBase64 = jwt_decode(payload);
      if (decodeBase64.isVerified === "0") {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  });



  const getRole = () => {
    UserService.getUserMe().then((response) => {
      if (response.data.data[0].role === "0") {
        setIsAdmin(true);
        documentAll();
      } else {
        username()
      }
    })
  };

  // sorting document
  const NotRead = []
  const doneRead = []
  const sortNotRead = arr => {
    const sorter = (a, b) => {
      return new Date(b.created_at) - new Date(a.created_at)
    }
    arr.sort(sorter);
  };

  const sortDone = arr => {
    const sorter = (a, b) => {
      return new Date(a.created_at) - new Date(b.created_at)
    }
    arr.sort(sorter);
  };

  const splitData = (data) => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].isread === '0') {
        NotRead.push(data[i])
      } else {
        doneRead.push(data[i])
      }
    }
    sortNotRead(NotRead);
    sortDone(doneRead);
  }

  const getDoc = () => {
    DocumentService.getDocumentProcess().then((response) => {
      const dokumen = response.data.data.document;
      setPending(dokumen);
      splitData(dokumen);
      setDocUser([...NotRead, ...doneRead])
    })
  };

  const username = () => {
    const getUserName = TokenService.getUser()
    const nameOfuser = jwt_decode(getUserName.access_token)
    setUser(nameOfuser.name)
  }


  useEffect(() => {
    getRole();
    getDoc()
  }, []);

  // use effect trigger send document
  useEffect(() => {
    if (upload === true) {
      sendDocument(documentBase);
    }
  }, [upload]);

  // send document
  const sendDocument = (dokumen) => {
    DocumentService.sendDocument(dokumen).then((response) => {
      setConfirmloading(true);
      setTimeout(() => {
        setConfirmloading(false);
        navigate("/upload", { state: { iddocument: response.data.data.iddocument } });
      }, 3000);
    }, (err) => {
      setConfirmloading(false)
      console.log(err.response.data["message"]);
      setTextError(err.response.data["message"]);
      setError(true);
      isUpload(false);
    })
  };
  // console.log(documentBase);
  const handleDocument = (e) => {
    setNotPDF(false)
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event) => {
        const doc = event.target.result.split(",");
        if (doc[0] === 'data:application/pdf;base64') {
          setDocument("base64document", doc[1]);
          isUpload(true);
        } else {
          setNotPDF(true);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // get document all
  const documentAll = async () => {
    DocumentService.getDocumentTunda().then((response) => {
      const dataDocument = response.data.data.document;
      setDocumentTunda(dataDocument)
    })
  };
  // for title page
  useEffect(() => {
    document.title = "Home";
  }, []);

  const changeIsread = async (param) => {
    DocumentService.changeIsReadDocument(param)
  }

  return (
    <>
      {confirmLoading ? (
        <div
          style={{
            zIndex: "1",
            width: "100%",
            height: "100%",
            position: "fixed",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(10, 10, 10, 0.6)",
          }}
        >
          <div className="text-center" style={{ color: "whitesmoke" }}>
            <Spinner
              animation="grow"
              variant="light"
              style={{ width: "50px", height: "50px", marginBottom: "20px" }}
            />
            <p style={{ fontWeight: "600", fontSize: "24px", margin: "0px" }}>
              Dokumen sedang dipersiapkan
            </p>
            <p>Mohon tunggu sebentar . . .</p>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Container fluid>
        {isAdmin ? <NavbarUser /> : <Navbarpenandatangan name={user} />}
        <Container fluid>
          <Row className="justify-content-center">
            <Col md={8}>
              {/* <Container fluid className="bg-white"> */}
              {notPdf ? (
                <Alert variant='danger' onClose={() => setNotPDF(false)} style={{ marginTop: '20px' }} dismissible>File harus ber-ekstensi PDF</Alert>
              ) : (<></>)}
              <Card
                style={{
                  border: "1px solid #E9ECEF",
                  borderRadius: "8px",
                  marginTop: "48px",
                }}
              >
                <Card.Body className="d-flex">
                  <div
                    className="text-wrap"
                    style={{
                      width: "245px",
                      fontSize: "20px",
                      lineHeight: "30px",
                    }}
                  >
                    Selamat datang,
                    <br />
                    <span style={{ fontWeight: "600" }}>
                      {isAdmin ? "Administrator" : user}
                    </span>
                  </div>
                  {isAdmin ? (
                    <div className="ms-auto d-flex align-items-center">
                      {!confirmLoading ? (
                        <Button
                          style={{
                            background: "#293DB6",
                            border: "0px",
                            fontSize: "16px",
                          }}
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                        >
                          Upload Dokumen{" "}
                          <span>
                            <BsPlusSquare />
                          </span>
                        </Button>
                      ) : (
                        <Button
                          style={{
                            background: "#293DB6",
                            border: "0px",
                            fontSize: "16px",
                          }}
                          disabled
                        >
                          Upload Dokumen{" "}
                          <span>
                            <BsPlusSquare />
                          </span>
                        </Button>
                      )}

                      <input
                        type="file"
                        name="base64document"
                        id="file-input"
                        value={doc}
                        style={{ display: "none" }}
                        accept="application/pdf"
                        onChange={handleDocument}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </Card.Body>
              </Card>

              <div className="d-flex" style={{ marginTop: "40px" }}>
                <p
                  style={{
                    fontSize: "20px",
                    color: "#212529",
                    fontWeight: "600",
                  }}
                >
                  {isAdmin
                    ? "Dokumen dalam proses"
                    : "Dokumen menunggu ditandatangani"}
                </p>
                <p
                  className="ms-auto"
                  style={{ color: "#293DB6", cursor: "pointer" }}
                  onClick={() => navigation("/document")}
                >
                  Lihat semua dokumen
                </p>
              </div>

              <div>
                {isAdmin ? (
                  <Tabs>
                    <Tab eventKey="penandatangan" title="Penandatangan">
                      <Card
                        style={{
                          borderRadius: "0px 0px 8px 8px",
                          borderTop: "0px",
                        }}
                        className="mb-3"
                      >
                        {pending.length === 0 ? (
                          <Card.Body>
                            <div className="text-center">
                              <img src={hand} alt="hand" />
                              <p>Semua dokumen telah ditandatangani</p>
                            </div>
                          </Card.Body>
                        ) : (
                          // <Card.Body>
                          <List
                            itemLayout="horizontal"
                            pagination={{ pageSize: 5 }}
                            style={{
                              marginBottom: "20px",
                              // borderBottom: "1px solid #DEE2E6",
                            }}
                            dataSource={pending}
                            renderItem={(item, idx) => (
                              <List.Item
                                key={idx}
                                style={{
                                  paddingLeft: "20px",
                                  // cursor: "pointer",
                                }}
                              >
                                <List.Item.Meta
                                  avatar={
                                    <Avatar
                                      src={Document}
                                      style={{ margin: "5px", borderRadius: '0px' }}
                                    />
                                  }
                                  title={
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        // fontSize: "16px",
                                      }}
                                    >
                                      {item.filename}
                                    </span>
                                  }
                                  description={
                                    <div className='row'>
                                      <Col>
                                        Dikirim : {FormatDate(item.created_at)}
                                      </Col>
                                      <Col className='d-flex align-items-center'>
                                        <span>{item.signerStatus}</span>
                                      </Col>
                                      <Col>
                                        <span>{CountExpired(item.expired_at)}</span>
                                      </Col>

                                    </div>
                                  }
                                />
                                <div className="pe-4">
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color: "#293DB6",
                                    }}
                                    onClick={() => {
                                      localStorage.setItem(
                                        "iddokumen",
                                        item.iddocument
                                      );
                                      navigate("/document/details", { state: { iddocument: item.iddocument } });
                                    }}
                                  >
                                    Lihat Dokumen
                                  </span>
                                </div>
                              </List.Item>
                            )}
                          />
                        )}
                      </Card>
                    </Tab>
                    <Tab eventKey="belumdikirim" title="Belum dikirim">
                      <Card
                        style={{
                          borderTop: "0px",
                          borderRadius: "0px 0px 8px 8px",
                        }}
                      >
                        {documentTunda.length !== 0 ? (
                          <List
                            itemLayout="horizontal"
                            dataSource={documentTunda}
                            pagination={{ pageSize: 5 }}
                            style={{
                              marginBottom: "40px",
                              // borderBottom: "1px solid #DEE2E6",
                            }}
                            renderItem={(item, idx) => (
                              <List.Item
                                key={idx}
                                style={{
                                  paddingLeft: "20px", borderRadius: '0px'
                                }}
                              >
                                <List.Item.Meta
                                  avatar={
                                    <Avatar
                                      className="m-auto"
                                      src={Document}
                                    // style={{ margin: "4px" }}
                                    />
                                  }
                                  title={
                                    <span
                                      style={{
                                        fontWeight: "600", padding: "auto", fontSize: '16px'
                                      }}
                                      className="p-auto"
                                    >
                                      {item.filename}
                                    </span>
                                  }
                                />
                                <div className="pe-4">
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color: "#293DB6",
                                    }}
                                    onClick={() => {
                                      if (item.filename) {
                                        localStorage.setItem("step", 1);
                                      } else {
                                        localStorage.setItem("step", 0);
                                      }
                                      localStorage.setItem(
                                        "iddokumen",
                                        item.iddocument
                                      );
                                      localStorage.setItem(
                                        "nama dokumen",
                                        item.filename
                                      );
                                      navigate("/upload", { state: { iddocument: item.iddocument } });
                                    }}
                                  >
                                    Lihat Dokumen
                                  </span>
                                </div>
                              </List.Item>
                            )}
                          />
                        ) : (
                          <Card.Body>
                            <div className="text-center">
                              <img src={kirim} alt="hand" />
                              <p>Semua dokumen telah dikirim</p>
                            </div>
                          </Card.Body>
                        )}
                      </Card>
                    </Tab>
                  </Tabs>
                ) : (
                  <Card className="mb-3">
                    {docUser.length === 0 ? (
                      <Card.Body>
                        <div className="text-center">
                          <img src={hand} alt="hand" />
                          <p>Semua dokumen telah ditandatangani</p>
                        </div>
                      </Card.Body>
                    ) : (
                      // <Card.Body>
                      <List
                        itemLayout="horizontal"
                        dataSource={docUser}
                        // .filter(doc => {
                        //   const day = 1000 * 3600 * 24
                        //   const count = Math.round(Math.round(new Date('2022-06-05').getTime() - new Date(doc.expired_at).getTime()) / (day))
                        //   if (count < 0 || count === 0) {
                        //     return doc
                        //   }
                        // })
                        pagination={{ pageSize: 6 }}
                        style={{
                          marginBottom: "20px",
                          // borderBottom: "1px solid #DEE2E6",
                        }}
                        renderItem={(item, idx) => (
                          <List.Item key={idx} style={{ paddingLeft: "20px" }}>
                            <List.Item.Meta
                              avatar={
                                <>

                                  <Avatar
                                    src={Document}
                                    style={{ margin: "4px", borderRadius: '0px' }}
                                  />

                                </>
                              }
                              title={
                                <span
                                  style={{ fontWeight: "600", padding: "auto", fontSize: '16px' }}
                                >
                                  {item.filename} {item.isread === '0' && <Badge count='Baru'></Badge>}
                                </span>
                              }
                              description={
                                <Row>
                                  <Col md={4}>
                                    <span>Diterima : {FormatDate(item.created_at)}</span>
                                  </Col>
                                  <Col>
                                    <span>{CountExpired(item.expired_at)}</span>
                                  </Col>
                                </Row>

                              }
                            />
                            <div className="pe-4">
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: "#293DB6",
                                }}
                                onClick={() => {
                                  localStorage.setItem(
                                    "iddokumen",
                                    item.iddocument
                                  );
                                  navigate("/sign", { state: { iddocument: item.iddocument } });
                                  if (item.isread === '0') {
                                    changeIsread(item.iddocument)
                                  }
                                }}
                              >
                                Lihat Dokumen
                              </span>
                            </div>
                          </List.Item>
                        )}
                      />
                    )}
                  </Card>
                )}
              </div>


            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Home;
