import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import Navbar from "../layout/navbar";
import { BoxUpload, Imagepreview } from "./style";
import CloseIcon from "../../img/CloseIcon.svg";
import KTP from "../../img/ktp.svg";
import NPWP from "../../img/npwp.svg";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { BiErrorCircle } from "react-icons/bi";

const { Dragger } = Upload;

const Foto = ({ formData, setForm, navigation }) => {
  const [ktp, setKtp] = useState("");
  const [npwp, setNpwp] = useState("");
  const [ktpUpload, setKtpUpload] = useState(false);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false)
  const [npwpUpload, setNpwpUpload] = useState(false);

  // console.log(formData);
  const { ktpphoto, npwpphoto } = formData;
  const handleKtp = (e) => {
    // console.log(e.file);
    if (e.file.size < 1000000) {
      if (e.file.originFileObj && e.fileList[0]) {
        let reader = new FileReader();
        reader.onload = (event) => {
          const replace = event.target.result.split(",");
          setForm("ktpphoto", replace[1]);
          // console.log(formData);
          setKtp(event.target.result);
          setKtpUpload(true);
        };
        reader.readAsDataURL(e.file.originFileObj);
      }
    } else {
      setAlert(true)
    }
  };
  function handleNpwp(e) {
    if (e.file.size < 1000000) {

      if (e.file.originFileObj && e.fileList[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          const baseReplace = e.target.result.split(",");
          setForm("npwpphoto", baseReplace[1]);
          setNpwp(e.target.result);
          setNpwpUpload(true);
        };
        reader.readAsDataURL(e.file.originFileObj);
      }
    } else {
      setAlert(true)
    }
  }
  // console.log(formData);

  useEffect(() => {
    if (ktpphoto === "") {
      setError(false);
    } else {
      setError(true);
    }
  });

  useEffect(() => {
    setForm("step", 1);
  }, []);
  // const [isUploaded, setIsUploaded] = useState(false);
  // function handleKTPImage(e) {
  //   if (e.target.files && e.target.files[0]) {
  //     let reader = new FileReader();
  //     reader.onload = function (e) {
  //       setKtp(e.target.result);
  //       setIsUploaded(true);
  //     };
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // }

  return (
    <>
      <Card
        style={{
          marginBottom: "30px",
          // padding: "10px",
          border: "1px solid #F8F9FA",
        }}
      >
        <Card.Body>
          {alert ? (

            <Alert variant="danger" onClick={() => setAlert(false)} dismissible>{<BiErrorCircle />}File terlalu besar</Alert>
          ) : (<></>)}
          <h5
            style={{
              fontWeight: "600",
              marginTop: "20px",
              marginBottom: "28px",
            }}
          >
            Upload Foto KTP <span className="text-danger">*</span>
          </h5>
          {/* <BoxUpload>
            <div className="image-upload"> */}
          {!ktpUpload ? (
            <>
              {/* <label htmlFor="input-ktp">
                <img
                  src={KTP}
                  alt="imagepreview"
                  draggable={"false"}
                  style={{
                    height: "200px",
                  }}
                />
                <p style={{ color: "#c4c4c4" }} className="text-center">
                  Klik untuk Upload KTP
                </p>
              </label>
              <input
                id="input-ktp"
                type="file"
                name="ktpphoto"
                accept="image/png"
                onChange={handleKtp}
              /> */}
              <Dragger
                accept="image/*"
                onChange={handleKtp}
                name="ktpphoto"
                multiple={false}
                style={{ borderRadius: "8px" }}
              >
                <p className="ant-upload-drag-icon">
                  <img
                    src={KTP}
                    alt="imagepreview"
                    draggable={"false"}
                    style={{
                      width: "200px",
                    }}
                  />
                </p>
                <p className="ant-upload-text" style={{ color: "#6C757D" }}>
                  Pilih atau geser file ke area ini untuk mengupload. Ukuran
                  maksimum 1 MB.
                </p>
                {/* <p></p> */}
              </Dragger>
            </>
          ) : (
            <Imagepreview
              style={{
                border: "1px dashed #DEE2E6",
                borderRadius: "8px",
              }}
              className="d-flex justify-content-center p-4"
            >
              <img
                className="close-icon"
                src={CloseIcon}
                alt="CloseIcon"
                onClick={() => {
                  setKtpUpload(false);
                  setKtp(null);
                  setForm("ktpphoto", "");
                }}
              />
              <img
                src={ktp}
                id="upload-image"
                style={{ width: "300px", height: "168px" }}
                draggable={false}
                alt="ktp"
              />
            </Imagepreview>
          )}
          {/* </div>
          </BoxUpload> */}
          {formData.npwp !== "" ? (
            <>
              <h5 style={{ fontWeight: "600", marginTop: "50px" }}>
                Foto Kartu NPWP <span className="text-danger">*</span>
              </h5>
              {/* <BoxUpload>
                  <div className="image-upload"> */}
              {!npwpUpload ? (
                <>
                  <Dragger
                    accept="image/*"
                    onChange={handleNpwp}
                    name="npwpphoto"
                    multiple={false}
                    style={{ borderRadius: "8px" }}
                  >
                    <p className="ant-upload-drag-icon">
                      <img src={NPWP} style={{ width: "200px" }} alt="" />
                    </p>
                    {/* <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p> */}
                    <p
                      className="ant-upload-text"
                      style={{ color: "#6C757D" }}
                    >
                      Pilih atau geser file ke area ini untuk mengupload.
                      Ukuran maksimum 2 MB.
                    </p>
                    {/* <div className="d-flex justify-content-center">
                        <img
                          src={NPWP}
                          alt="imagepreview"
                          draggable={"false"}
                          // style={{
                          //   width: "252px",
                          //   height: "168px",
                          // }}
                        />
                        <p>
                          Pilih atau geser file ke area ini untuk mengupload.
                          Ukuran maksimum 2 MB.
                        </p>
                      </div> */}
                  </Dragger>
                </>
              ) : (
                <Imagepreview
                  style={{
                    border: "1px dashed #DEE2E6",
                    borderRadius: "8px",
                  }}
                  className="d-flex justify-content-center p-4"
                >
                  <img
                    className="close-icon"
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={() => {
                      setNpwpUpload(false);
                      setNpwp(null);
                      setForm("npwpphoto", "");
                    }}
                  />
                  <img
                    src={npwp}
                    style={{ width: "300px", height: "168px" }}
                    id="upload-image"
                    draggable={false}
                    alt="ktp"
                  />
                </Imagepreview>
              )}
              {/* </div>
                </BoxUpload> */}
            </>
          ) : (
            <></>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "39px",
            }}
          >
            {!error ? (
              <Button
                style={{ background: "#084298", border: "0px" }}
                disabled
              // onClick={console.log(formData)}
              >
                Simpan & Lanjutkan
              </Button>
            ) : (
              <Button
                style={{ background: "#084298", border: "0px" }}
                onClick={() => navigation.next()}
              // onClick={console.log(formData)}
              >
                Simpan & Lanjutkan
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      {/* <Navbar />
      <Container>
        <Row>
          <Col className="d-flex justify-content-center align-items-center Login-attribute mt-5">
            
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

export default Foto;
