import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import Materai from "./materai";
import Navbar from "../../components/layout/navbar";
import Sign from "./sign";
import { useForm } from "../../hooks";
import { useStep } from "react-hooks-helper";
import Penerima from "./penerima";
import Success from "./success";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Review from "./review";
import NavbarUser from "../../components/layout/NavbarUser";
import { Card, Col, Container, Row } from "react-bootstrap";
import Letak from "./letak";


const dataEsign = {
  step: 0,
  idDocument: 0,
  docfilename: "",
  docname: "",
  location: "",
  docnumber: "",
  base64document: "",
  page: "",
  x: "",
  y: "",
};

const { Step } = Steps;

const steps = [
  { id: "review", no: 0 },
  { id: "penerima", no: 1 },
  { id: "ttd", no: 2 },
  { id: "success", no: 3 },
];
const Index = () => {
  const { state } = useLocation()
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const payload = localStorage.getItem("payload");
  // const dokumen = localStorage.getItem("nama dokumen");
  const [form, setForm] = useForm(dataEsign);


  useEffect(() => {
    if (state == null) {
      navigate(-1)
    }
  }, [])

  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  useEffect(() => {
    if (payload) {
      const decodeBase64 = atob(payload);
      const check = JSON.parse(decodeBase64);
      if (check.isVerified === "0") {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  });

  useEffect(() => {
    document.title = "upload";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Container fluid>
        <NavbarUser />
        <Row className="justify-content-center">
          <Col md={8}>
            <Card
              style={{
                marginTop: "48px",
                marginBottom: "30px",
                border: "1px solid #E9ECEF",
              }}
            >
              <Card.Body>
                {step.no !== 3 ? (
                  <>
                    <Container>
                      <Steps
                        labelPlacement="vertical"
                        size="small"
                        responsive={true}
                        current={step.no}
                      >
                        <Step title="Isi Kelengkapan Dokumen" />
                        <Step title="Tambah Kontak Tujuan" />
                        <Step title="Letak Tandatangan" />
                      </Steps>
                    </Container>
                    <hr className="s" />
                  </>
                ) : (
                  <></>
                )}

                {(() => {
                  // eslint-disable-next-line default-case
                  switch (step.id) {
                    // case "sign":
                    //   return <Sign form={form} setForm={setForm} navigation={navigation} />;

                    // case "materai":
                    //   return <Materai form={form} setForm={setForm} navigation={navigation} />;
                    case "review":
                      return (
                        <Review
                          form={form}
                          setForm={setForm}
                          navigation={navigation}
                        />
                      );

                    case "penerima":
                      return (
                        <Penerima
                          form={form}
                          setForm={setForm}
                          navigation={navigation}
                        />
                      );
                    case "ttd":
                      return (
                        <Letak
                          form={form}
                          setForm={setForm}
                          navigation={navigation}
                        />
                      );

                    case "success":
                      return <Success form={form} setForm={setForm} />;
                  }
                })()}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
