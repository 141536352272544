import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Select } from "antd";

const { Option } = Select


const FormDiri = ({ formData, setForm, navigation }) => {
  const [disable, setDisable] = useState(true);
  const [confirm, setConfirm] = useState("");
  const [nextStep, setNextStep] = useState(false);
  const [valEmail, setValEmail] = useState("");
  const [valPass, setValPass] = useState("");
  const [valConf, setValConf] = useState("");
  const [date, setDate] = useState('');
  const [disConf, setDisConf] = useState(false);
  const [provs, setProv] = useState([{}]);
  const [Kabupaten, setKab] = useState([{}]);
  const [showPassword, setShowPassword] = useState(false)
  const [nikVal, setNikVal] = useState('')
  const [searchParams] = useSearchParams();
  const [type] = useState(searchParams.get('type'))

  const {
    name,
    gender,
    placeofbirth,
    dateofbirth,
    province,
    address,
    city,
    phone,
    password,
    email,
    ktp,
    npwp,
    orgunit,
    workunit,
    position
  } = formData;

  // handle input
  const handleInput = (e) => {
    setForm(e.target.name, e.target.value);
  };

  const handleConfirm = () => {
    const validemail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email !== "" || password !== "") {
      if (validemail.test(email) === false) {
        setValEmail("invalid email");
      } else {
        if (
          validemail.test(email) === true &&
          password.length >= 8 &&
          confirm === password
        ) {
          navigation.next();
        }
      }
      if (password.length < 8) {
        setValPass("minimal 8 karakter");
      } else {
        if (
          validemail.test(email) === true &&
          password.length >= 8 &&
          confirm === password
        ) {
          navigation.next();
        }
      }
      if (confirm !== password) {
        setValConf("password tidak sama");
      } else {
        if (
          validemail.test(email) === true &&
          password.length >= 8 &&
          confirm === password
        ) {
          navigation.next();
        }
      }
    }
  };
  // setForm("step", 0);
  // const prov = {};
  const provinces = async () => {
    try {
      const response = await axios.get("area");
      const resp = response.data.data.nama_kota;
      setProv(resp);
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    provinces();
  }, []);

  const checkNikandEmail = async (params, type) => {
    try {
      const response = await axios.post(`cek/personalreg?type=${type}`, params)
      console.log(response)
      setValEmail('')
      setNikVal('')
    } catch (err) {
      console.log(err.response)
      if (type === 'email') {
        setValEmail(err.response.data.message)
      } else {
        setNikVal(err.response.data.message)
      }
    }
  }
  // const dataprov = [];

  // for (var i = 0; i < provs.length; i++) {
  //   dataprov.push({ id: provs[i].id, name: provs[i].name });
  // }

  const getKabupaten = (val) => {
    const getKota = async () => {
      try {
        const response = await axios.get(`area?nameKabupaten=${val}`);
        // console.log(response.data.data.nama_kota);
        const tempKab = response.data.data.nama_kota;
        setKab(tempKab);
      } catch (error) {
        console.log(error.response);
      }
    };
    getKota();
  };
  // console.log(provs);
  // const dataKabupaten = [];
  // for (var j = 0; j < kab.length; j++) {
  //   dataKabupaten.push({ nama: kab[j].name });
  // }

  const checkUmur = (e) => {
    const dob = new Date(e.target.value);
    const today = new Date();
    const age = Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));
    if (age <= 17) {
      setDate('umur minimal 17 tahun')
      setForm('dateofbirth', '')
    } else {
      setDate('')
    }
  }

  // console.log(dataKabupaten);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (type !== 'existing') {
      if (
        name === "" ||
        gender === "" ||
        placeofbirth === "" ||
        dateofbirth === "" ||
        province === "" ||
        address === "" ||
        city === "" ||
        phone === "" ||
        password === "" ||
        ktp === "" ||
        confirm === "" || nikVal !== "" || valEmail !== "" || date !== "" || orgunit === "" || workunit === "" || position === ""
      ) {
        setDisable(true);
      } else {
        setDisable(false);
      }
      if (password.length < 8) {
        setDisConf(true);
      } else {
        setDisConf(false);
      }
    } else {
      setConfirm(password)
      if (
        name === "" ||
        gender === "" ||
        placeofbirth === "" ||
        dateofbirth === "" ||
        province === "" ||
        address === "" ||
        city === "" ||
        phone === "" ||
        password === "" ||
        ktp === "" || nikVal !== "" || valEmail !== "" || date !== "" || orgunit === "" || workunit === "" || position === ""
      ) {
        setDisable(true);
      } else {
        setDisable(false);
      }
      if (password.length < 8) {
        setDisConf(true);
      } else {
        setDisConf(false);
      }

    }
  });
  return (
    <>
      <Card
        style={{
          marginBottom: "30px",
          // padding: "10px",
          // width: "500px",
          border: "1px solid #F8F9FA",
        }}
      >
        <Card.Body>
          {/* data diri */}
          <div>
            <h5
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "28px",
              }}
            >
              Identitas Diri
            </h5>
            {/* nama */}
            <Form.Group className="mb-3">
              <Form.Label>
                Nama Lengkap (sesuai KTP) <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={name}
                onChange={handleInput}
                placeholder="Nama Lengkap Anda"
              />
            </Form.Group>
            {/* tempat lahir */}
            <Form.Group className="mb-3">
              <Form.Label>
                Tempat Lahir <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="placeofbirth"
                value={placeofbirth}
                onChange={handleInput}
                placeholder="Tempat Lahir Anda"
              />
            </Form.Group>
            {/* tanggal lahir */}
            <Form.Group className="mb-3">
              <Form.Label>
                Tanggal Lahir <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Tanggal Lahir Anda"
                name="dateofbirth"
                value={dateofbirth}
                onChange={handleInput}
                onBlur={(e) => checkUmur(e)}
              />
              <Form.Text className='text-danger'>{date}</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Jenis Kelamin <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select name="gender" placeholder="asad" value={gender} onChange={handleInput}>
                <option>--pilih jenis kelamin--</option>
                <option value="M">Laki Laki</option>
                <option value="F">Perempuan</option>
              </Form.Select>
            </Form.Group>
            {/* ktp */}
            <Form.Group label="NIK" className="mb-3">
              <Form.Label>
                NIK <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="ktp"
                maxLength={16}
                value={ktp}
                onChange={handleInput}
                onBlur={(e) => { if (ktp.length > 0 && ktp.length < 16 || ktp.length === 0) { setNikVal('Minimal 16 digit') } else { checkNikandEmail({ nik: e.target.value }, 'nik') } }}
                placeholder="Nomor Induk Kependudukan"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <Form.Text className='text-danger'>{nikVal}</Form.Text>
            </Form.Group>
            {/* NPWP */}
            <Form.Group className="mb-3">
              <Form.Label>NPWP <i style={{ fontSize: '12px' }}>(optional)</i></Form.Label>
              <Form.Control
                type="text"
                maxLength={15}
                placeholder="Nomor Pokok Wajib Pajak"
                name="npwp"
                value={npwp}
                onChange={handleInput}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Group>
          </div>
          {/* data instansi */}
          <div style={{ marginTop: '45px' }}>
            <h5
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "28px",
              }}
            >
              Data Instansi
            </h5>
            {/* orgunit */}
            <Form.Group className="mb-3">
              <Form.Label>
                Nama Instansi (Perguruan Tinggi) <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="orgunit"
                value={orgunit}
                onChange={handleInput}
                placeholder="Nama Instansi Anda"
              />
            </Form.Group>
            {/* workunit */}
            <Form.Group className="mb-3">
              <Form.Label>
                Unit Kerja <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="workunit"
                value={workunit}
                onChange={handleInput}
                placeholder="Masukkan Unit Kerja"
              />
            </Form.Group>
            {/* position */}
            <Form.Group className="mb-3">
              <Form.Label>
                Jabatan <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="position"
                value={position}
                onChange={handleInput}
                placeholder="Masukkan jabatan"
              />
            </Form.Group>
          </div>
          {/* alamat */}
          <div style={{ marginTop: "45px" }}>
            <h5
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "28px",
              }}
            >
              Alamat
            </h5>

            <Form.Group className="mb-3">
              <Form.Label>
                province <span className="text-danger">*</span>
              </Form.Label>
              <Select className='form-control'
                optionFilterProp="children"
                placeholder="Pilih Provinsi"
                value={!province ? 'Pilih Provinsi' : province}
                onChange={(value) => {
                  // handleInput(value);
                  setForm('province', value)
                  getKabupaten(value);
                }}
                style={{ border: '0px' }}
                showSearch>
                {provs.map((prov, idx) => (
                  <Option value={prov.name}>{prov.name}</Option>
                ))}
              </Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Kota/Kabupaten <span className="text-danger">*</span>
              </Form.Label>
              <Select
                className='form-control'
                optionFilterProp="children"
                value={!city ? 'Pilih Kota' : city}
                onChange={(value) => {
                  // handleInput(value);
                  setForm('city', value)
                }}
                style={{ border: '0px' }}
                showSearch>
                {Kabupaten.map((kota, idx) => (
                  <Option value={kota.name}>{kota.name}</Option>
                ))}
              </Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Alamat <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Masukkan Alamat"
                name="address"
                value={address}
                onChange={handleInput}
                style={{ height: "100px" }}
              />
            </Form.Group>
          </div>

          <div style={{ marginTop: "45px" }}>
            <h5
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "28px",
              }}
            >
              Data Akun
            </h5>
            <Form.Group className="mb-3">
              <Form.Label>
                No. Hp <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={phone}
                onChange={handleInput}
                placeholder="Masukkan Nomor HP"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <Form.Text muted>Nomor HP yang masih aktif</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Email <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={email}
                onChange={handleInput}
                // onBlur={() => checkEmail(email)}
                style={{ background: 'white' }}
                disabled
                placeholder="Masukkan Email"
              />
              {valEmail !== "" ? (
                <Form.Text className="text-danger">{valEmail}</Form.Text>
              ) : (
                <></>
              )}
            </Form.Group>
            {type !== 'existing' &&
              <>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Password <span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup>

                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Buat password akun"
                      name="password"
                      style={{ borderRight: '0px' }}
                      value={password}
                      className='inputpass'
                      onChange={handleInput}
                    />
                    <InputGroup.Text style={{ backgroundColor: 'transparent', borderLeft: '0px' }}><span>{!showPassword ? <FaEyeSlash style={{ cursor: 'pointer' }} onClick={() => setShowPassword(true)} /> : <FaEye style={{ cursor: 'pointer' }} onClick={() => setShowPassword(false)} />}</span></InputGroup.Text>
                  </InputGroup>
                  {valPass !== "" ? (
                    <Form.Text className="text-danger">{valPass}</Form.Text>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                {/* {disConf ? (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Konfirmasi Password <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="confirm"
                      value={confirm}
                      disabled
                      placeholder="Ketik ulang password akun"
                    />
                  </Form.Group>
                ) : ( */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    Konfirmasi Password <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    name="confirm"
                    value={type === 'existing' ? password : confirm}
                    placeholder="Ketik ulang password akun"
                    onChange={(e) => {
                      setConfirm(e.target.value);
                    }}
                    disabled={disConf && true}
                  />
                  {valConf !== "" ? (
                    <Form.Text className="text-danger">{valConf}</Form.Text>
                  ) : (
                    <></>
                  )}
                </Form.Group>
                {/* )} */}
              </>
            }
          </div>
          {disable ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "48px",
              }}
            >
              <Button style={{ background: "#084298", border: "0" }} disabled>
                Simpan & Lanjutkan
              </Button>
              {/* </Link> */}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "48px",
              }}
            >
              <Button
                style={{ background: "#084298", border: "0" }}
                onClick={() => {
                  if (!nextStep) {
                    handleConfirm();
                  }
                }}
              >
                Simpan & Lanjutkan
              </Button>
              {/* </Link> */}
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default FormDiri;
