import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useStep } from 'react-hooks-helper';
import { useSearchParams } from 'react-router-dom';
import { useForm } from '../../hooks';
import SignEmail from './signEmail';
import Success from './success';
import jwt_decode from "jwt-decode";
import Navbarseamless from '../../components/layout/navbarSeamless';
import axios from 'axios';
import { Result } from 'antd';

const steps = [
    { id: 'sign' },
    { id: 'success' }
]

const formSign = {
    docfilename: "",
    docname: "",
    signlocation: "",
    docnumber: "",
    base64document: "",
    otp: "",
    reason: "",
};


const SignByEmail = () => {
    const [searchParams] = useSearchParams();
    const [form, setForm] = useForm(formSign);
    const { step, navigation } = useStep({
        steps,
        initialStep: 0
    })

    const [token] = useState(searchParams.get('token'))
    const [expired, setExpired] = useState(false)
    const decode = jwt_decode(token)
    const [id] = useState(decode.iddocument)
    const [email] = useState(decode.email)
    const [valid, setValid] = useState(true)


    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const getInformation = async () => {
        try {
            const response = await axios.get(
                `seamproc/view/${id}/information`,
                config
            );
            console.log(response)
        } catch (err) {
            console.log(err.response)
            if (err.response.status === 401)
                setValid(false)
        }
    };
    useEffect(() => {
        getInformation();
        if (decode.exp < new Date() / 1000) {
            setExpired(true)
        }
    }, [])

    return (
        <>
            <Container fluid>
                <Navbarseamless />
                <Row className='justify-content-center' style={{ marginTop: "40px" }}>
                    <Col md={8}>
                        {expired ? (
                            <Card className='d-flex align-items-center justify-content-center' style={{ height: '70vh', border: '1px solid #E9ECEF' }}>
                                <Container>
                                    <Result status='warning' title='Token kadaluarsa' extra={<span>Token untuk dokumen ini sudah kadaluarsa. Silahkan hubungi Admin untuk meminta dokumen yang baru jika Anda merasa belum menandatangani dokumen ini.</span>} />
                                </Container>
                            </Card>
                        ) : (
                            <>
                                {valid ?
                                    (() => {
                                        // eslint-disable-next-line default-case
                                        switch (step.id) {
                                            case 'sign':
                                                return <SignEmail form={form} setForm={setForm} navigation={navigation} id={id} email={email} token={token} />
                                            case 'success':
                                                return <Success />
                                        }
                                    })()
                                    : <Result
                                        status="403"
                                        title={<p style={{ fontWeight: '600' }}>Token tidak valid</p>}
                                        subTitle={<p style={{ fontSize: '18px' }}>Maaf, anda tidak bisa ke halaman ini, karena token anda tidak valid</p>}
                                    />}
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SignByEmail