import React, { useEffect, useState } from "react";
import Logo from "../../img/logo.svg";
import { Alert, Card, Col, Container, Row } from "react-bootstrap";
import { Avatar } from "antd";
import { FaRegUser } from "react-icons/fa";
import { useParams } from "react-router-dom";
import axios from "axios";

const Index = () => {
  // get params id
  const { id } = useParams();
  // data verify
  const dataVerif = {
    serialdoc: id,
  };
  // useState
  const [docname, setDocname] = useState("");
  // const [serial, setSerial] = useState("");
  const [signer, setSigner] = useState([]);
  const [isvalid, setIsvalid] = useState(true);

  // get data document
  const getVerifyDoc = async () => {
    try {
      const response = await axios.post("docverify", dataVerif);
      console.log(response);
      setDocname(response.data.document.name);
      setSigner(response.data.signer);
      // setIsvalid(false);
    } catch (err) {
      console.log(err.response);
      setIsvalid(false);
    }
  };

  // useEffect
  useEffect(() => {
    getVerifyDoc();
  }, []);

  useEffect(() => {
    document.title = 'Verifikasi Dokumen'
  }, [])

  return (
    <>
      <Container>
        <Row
          className="justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Col md={8}>
            <div className="text-center" style={{ marginTop: "20px" }}>
              <img src={Logo} alt="SEVIMA" style={{ marginBottom: "9px" }} />
              <p
                style={{
                  fontWeight: "400",
                  marginBottom: "40px",
                  //   padding: "0px",
                  marginTop: "-10px",
                }}
              >
                e-Sign System
              </p>
              <p style={{ fontWeight: "600", fontSize: "20px" }}>
                Verifikasi Tanda Tangan Elektronik
              </p>
            </div>
            {isvalid ? (
              <Card
                style={{
                  border: "1px solid #DEE2E6",
                  borderRadius: "8px",
                  marginBottom: "20px",
                }}
              >
                <Card.Body>
                  <div>
                    <span>Nama Dokumen</span>
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>
                      {docname}
                      {/* {nameFile} */}
                    </p>
                  </div>
                  <div>
                    <span>Nomer Seri</span>
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>
                      {id}
                      {/* {nameFile} */}
                    </p>
                  </div>
                  <div style={{ marginBottom: "12px" }}>
                    <span>
                      Telah tertandatangani melalui SEVIMA e-Sign System oleh:
                    </span>
                  </div>
                  <Row xs={1} md={2} className="g-2">
                    {signer.map((item, idx) => (
                      <Col key={idx}>
                        <Card
                          className="d-flex flex-row p-2"
                          style={{
                            width: "100px !important",
                            height: "83px",
                            // border: "0px",
                          }}
                        >
                          <div className="my-auto">
                            <Avatar icon={<FaRegUser className="my-1" />} />
                          </div>
                          <div className="my-auto ms-2">
                            <span
                              style={{ fontWeight: "600", fontSize: "16px" }}
                            >
                              {item.name}
                            </span>{" "}
                            <br />
                            <span
                              style={{ fontWeight: "400", fontSize: "12px" }}
                            >
                              Pada {item.date}
                            </span>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <div
                    className="text-center"
                    style={{ marginTop: "56px", fontStyle: "italic" }}
                  >
                    <p>
                      Tanda tangan telah terverifikasi oleh sistem Peruri
                      sebagai otoritas penyedia layanan tanda tangan elektronik.
                    </p>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <Alert className="text-center" variant="warning">
                <Alert.Heading>Dokumen tidak ditemukan</Alert.Heading>
                <p>Serial number Anda tidak sesuai!</p>
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
