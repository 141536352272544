import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row, InputGroup, Alert } from 'react-bootstrap'
import Logo from "../../img/logo.svg";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useForm } from "../../hooks";
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { Result } from 'antd';

const dataGantiPassword = {
    passwordBaru: '',
    konfirmPassword: ''
}

const Lupapassword = () => {
    const [showPassword, setShowpassword] = useState(false)
    const [form, setForm] = useForm(dataGantiPassword)
    const { passwordBaru, konfirmPassword } = form
    const [disabled, setDisabled] = useState(false)
    const [same, setSame] = useState(true)
    const [searchParams] = useSearchParams()
    const [alert, setAlert] = useState(false);
    const [infoAPI, setInfoAPI] = useState("");
    const [request, setRequest] = useState(false)
    const navigate = useNavigate()
    const [expired, setExpired] = useState(false)

    const [token] = useState(searchParams.get('token'))
    const decode = jwt_decode(token)
    const [email] = useState(decode.email)
    const [uid] = useState(decode.uid);
    const [isSuccess, setIsSuccess] = useState(false)

    // handle input
    const handleInput = (e) => {
        setForm(e.target.name, e.target.value)
    }

    // check same confirm passowrd with new password
    const checkPass = () => {
        if (passwordBaru !== '' && konfirmPassword !== '')
            if (passwordBaru === konfirmPassword) {
                console.log('cocok')
                setSame(true)
                setRequest(true)
            } else {
                console.log('ga cocok')
                setSame(false)
            }
    }

    // useEffect for disabled button
    useEffect(() => {
        if (passwordBaru === '' || konfirmPassword === '')
            setDisabled(true)
        else
            if (passwordBaru.length < 8 || konfirmPassword.length < 8)
                setDisabled(true)
            else
                setDisabled(false)
    }, [passwordBaru, konfirmPassword])

    // styling input
    const styleInput = {
        borderRight: '0px',
        ':focus': {
            outline: '0px'
        }
    }

    // const send reset password
    const sendResetPassword = async (params) => {
        setDisabled(true)
        try {
            const response = await axios.post('reset-password', { uid: uid, password: params }, { headers: { Authorization: `Bearer ${token}` } })
            console.log(response)
            setAlert(true)
            setInfoAPI('Password berhasil diganti')
            setIsSuccess(true)
            setTimeout(() => {
                navigate('/')
            }, 3000);
        } catch (err) {
            console.log(err.response)
            setAlert(true)
            setRequest(false)
            setInfoAPI(err.response.data.message)
        }
    }

    // useEffect for send request reset password
    useEffect(() => {
        if (request)
            sendResetPassword(passwordBaru)
    }, [passwordBaru, request])

    useEffect(() => {
        if (decode.exp < new Date() / 1000) {
            setExpired(true)
        }
    }, [])
    return (
        <>
            <Container>
                <Row className='align-items-center justify-content-center vh-100'>
                    <Col md={6}>
                        <div className="text-center">
                            <img src={Logo} alt="SEVIMA" style={{ marginBottom: "9px" }} />
                            {!alert ? (
                                <p style={{ fontWeight: "400", marginBottom: "51px" }}>
                                    e-Sign System
                                </p>
                            ) : (
                                <div
                                    className="d-flex justify-content-center"
                                >
                                    <Alert
                                        className="text-start"
                                        style={{ width: "404px" }}
                                        variant={isSuccess ? "success" : "danger"}
                                    >
                                        {infoAPI}
                                    </Alert>
                                </div>
                            )}
                        </div>
                        <div className='d-flex justify-content-center'>
                            <Card className='card-login' style={{ border: "1px solid #E9ECEF" }}>
                                {expired ? (
                                    <Result status='warning' title='Token kadaluarsa' extra={<span>Token sudah tidak dapat digunakan. silahkan request kembali untuk mendapat token yang baru.</span>} />
                                ) : (
                                    <Container>
                                        <h4
                                            className="text-center"
                                            style={{ fontWeight: "600", marginTop: "40px" }}
                                        >
                                            Reset Password
                                        </h4>
                                        <Form style={{ marginTop: "44px" }} onSubmit={(e) => { e.preventDefault(); checkPass() }}>
                                            {/* email disabled */}
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control style={{ background: 'transparent' }} value={email} disabled />
                                            </Form.Group>
                                            {/* end email disabled */}

                                            {/* new password */}
                                            <Form.Group className="mb-3">
                                                <Form.Label>Password baru</Form.Label>
                                                <InputGroup>

                                                    <Form.Control
                                                        type={showPassword ? 'text' : 'password'}
                                                        name="passwordBaru"
                                                        value={passwordBaru}
                                                        onChange={handleInput}
                                                        placeholder="Password baru"
                                                        autoComplete="off"
                                                        autoFocus
                                                        className='inputpass'
                                                        style={styleInput}
                                                    />
                                                    <InputGroup.Text style={{ backgroundColor: 'transparent', borderLeft: '0px' }}><span>{!showPassword ? <FaEyeSlash style={{ cursor: 'pointer' }} onClick={() => setShowpassword(true)} /> : <FaEye style={{ cursor: 'pointer' }} onClick={() => setShowpassword(false)} />}</span></InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                            {/* end new password */}

                                            {/* confirm password */}
                                            <Form.Group className="mb-3">
                                                <Form.Label>Konfirmasi password</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        type={showPassword ? 'text' : 'password'}
                                                        name="konfirmPassword"
                                                        value={konfirmPassword}
                                                        onChange={handleInput}
                                                        placeholder="konfirmasi password baru"
                                                        autoComplete="off"
                                                        className='inputpass'
                                                        style={styleInput}
                                                        aria-describedby="inputGroupPrepend"
                                                    />
                                                    <InputGroup.Text style={{ backgroundColor: 'transparent', borderLeft: '0px' }}><span>{!showPassword ? <FaEyeSlash style={{ cursor: 'pointer' }} onClick={() => setShowpassword(true)} /> : <FaEye style={{ cursor: 'pointer' }} onClick={() => setShowpassword(false)} />}</span></InputGroup.Text>
                                                </InputGroup>
                                                {!same &&
                                                    <Form.Text className='text-danger'>
                                                        Password tidak sama
                                                    </Form.Text>
                                                }
                                            </Form.Group>
                                            {/* end confirm password */}

                                            {/* button send */}
                                            <Button className='form-control'
                                                style={{
                                                    fontSize: "20px",
                                                    fontWeight: "500",
                                                    marginTop: "20px",
                                                    marginBottom: "38px",
                                                    background: '#293DB6',
                                                    border: '0px'
                                                }}
                                                type="submit"
                                                disabled={disabled ? true : false}
                                            >Atur ulang password</Button>
                                            {/* end button send */}

                                        </Form>
                                    </Container>
                                )}
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Lupapassword