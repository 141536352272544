import api from "./api";

const signing = (id, data) => {
    return api.post(`signing?id=${id}`, data).then((response) => {
        return response
    })
}

const getOTP = (orderId) => {
    return api.post('otp', orderId).then((response) => {
        return response
    })
}

const setSignature = (id, data) => {
    return api.post(`document/setsignature?id=${id}`, data).then((response) => {
        return response
    })
}

const SignService = {
    signing,
    getOTP,
    setSignature
}


export default SignService