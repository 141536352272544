import styled from "styled-components";

export const BoxUpload = styled.div`
  display: grid;
  margin-top: 20px;
  place-items: center;
  border: 1px dashed #ADB5BD;
  /* padding: 36px 48px; */
  position: relative;
  height: 232px;
  background: #fbfbff;
  border-radius: 8px;
  box-sizing: border-box;
  .image-upload {
    display: flex;
    flex-wrap: wrap;
    label {
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }
    }
    > input {
      display: none;
    }
  }
`;

export const Imagepreview = styled.div`
  position: relative;
  border-radius:1px dashed #ADB5BD;
  // width: 100px;
  #upload-image {
    border-radius: 10px;
    height: 200px;
  }
  .close-icon {
    background: #000;
    border-radius: 5px;
    opacity: 0.3;
    position: absolute;
    z-index: 10;
    right: 15px;
    top: 20px;
    cursor: pointer;
    :hover {
      opacity: 1;
    }
  }
`;
