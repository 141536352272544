import React, { useEffect } from "react";
import Navbar from "../components/layout/navbar";
import Peruri from "../img/logo-peruri.png";
import "../components/register/success.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Success = () => {
  const token = localStorage.getItem("token");
  const check = atob(token);
  const parse = JSON.parse(check);
  // console.log(parse.email);
  const navigate = useNavigate();

  const checkSertifikat = async () => {
    await axios
      .post("check/sertifikat", parse.email)
      .then((response) => {
        // localStorage.setItem('token',resp)
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response.data["message"]);
      });
  };
  checkSertifikat();
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    if (parse.isVerified === "1") {
      localStorage.clear();
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  });
  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ display: "grid", placeItems: "center", marginTop: "50px" }}
      >
        <img
          src={Peruri}
          alt="peruri"
          style={{ animation: "spin-logo 2s linear infinite", width: "200px" }}
        />
        <p
          style={{ fontWeight: "500", fontSize: "30px", marginTop: "30px" }}
          className="text-center"
        >
          Data Anda masih dalam proses verification. Mohon tunggu sejenak <br />
          (Maksimal 1x24 jam)
        </p>
      </div>
    </>
  );
};

export default Success;
