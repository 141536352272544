import { message, Popconfirm, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
	Container,
	Row,
	Col,
	Tabs,
	Tab,
	Card,
	Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavbarUser from "../../components/layout/NavbarUser";
import "./document.css";
// import axios from "axios";
import Navbarpenandatangan from "../../components/layout/navbarpenandatangan";
import FormatDate from "../../components/formatDate";
import { BsDownload, BsTrash } from "react-icons/bs";
import UserService from "../../api/user.service";
import TokenService from "../../api/token.service";
import jwt_decode from "jwt-decode";
import DocumentService from "../../api/document.service";


const Index = () => {
	const navigate = useNavigate();
	const payload = localStorage.getItem("payload");
	const [data, setData] = useState([]);
	const [isAdmin, setIsAdmin] = useState(false);
	const [search, setSearch] = useState("");
	const [user, setUser] = useState('')

	useEffect(() => {
		if (payload) {
			const decodeBase64 = atob(payload);
			const check = JSON.parse(decodeBase64);
			if (check.isVerified === "0") {
				navigate("/");
			}
		} else {
			navigate("/");
		}
	});
	// get role
	const getRole = async () => {
		UserService.getUserMe().then((response) => {
			if (response.data.data[0].role === "0") {
				setIsAdmin(true);
			} else {
				username()
			}
		})
	};
	const username = () => {
		const getUserName = TokenService.getUser()
		// console.log(getUserName)
		const nameOfuser = jwt_decode(getUserName.access_token)
		setUser(nameOfuser.name)
	}

	useEffect(() => {
		getRole();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// document all
	const documentAll = async () => {
		DocumentService.getDocumentAll().then((response) => {
			const dataDocument = response.data.data.document;
			setData(dataDocument);
		})
	};
	useEffect(() => {
		documentAll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const draft = [];
	const pending = [];
	const done = [];
	const expired = []

	for (var i = 0; i < data.length; i++) {
		if (
			data[i].status === "selesai" ||
			data[i].signerStatus === "semua pihak sudah menandatangani"
		) {
			done.push(data[i]);
		}
		if (
			data[i].status === "proses" &&
			data[i].signerStatus === "belum ditandatangani"
		) {
			pending.push(data[i]);
		}
		if (data[i].status === "draf" || data[i].status === "tunda") {
			draft.push(data[i]);
		}
		if (data[i].status === 'kadaluarsa') {
			expired.push(data[i])
		}
	}
	const columns = [
		{
			title: "Document",
			dataIndex: "filename",
			key: "filename",
			render: (text, record) => (
				<span onClick={() => navigate('details', { state: { iddocument: record.iddocument } })} style={{ cursor: 'pointer' }}>{text}</span>
			),
		},
		{
			title: "Tanggal Upload",
			dataIndex: "created_at",
			key: "upload",
			render: (upload) => (
				<span className="text-center" style={{ fontSize: "14px" }}>
					{FormatDate(upload)}
				</span>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status, record) => (
				<>
					{status === "draf" || status === "tunda" ? (
						<div className="d-flex">
							<Tag
								// bg="warning"
								style={{
									fontWeight: "500",
									background: "#FFEDB7",
									color: "#462B03",
									fontSize: "14px",
									border: "0px",
									borderRadius: "4px",
									display: 'flex',
									alignItems: 'center'
								}}
							>
								Belum dikirim
							</Tag>
							<div className="ms-auto" style={{ zIndex: '1' }}>
								<Popconfirm title='Apakah anda yakin ingin menghapus dokumen ini?' onConfirm={() => deleteDoc(record.iddocument)} okText="Iya" cancelText="Batal">
									<div className="text-danger" style={{ cursor: 'pointer', fontWeight: '500' }}>
										<BsTrash />
									</div>
								</Popconfirm>
							</div>
						</div>
					) : (
						<>
							{status === "proses" ? (
								<div className='d-flex'>
									<Tag
										style={{
											fontWeight: "500",
											color: "#004680",
											fontSize: "14px",
											borderRadius: "4px",
											backgroundColor: "#DBE4FA",
											border: "0px",
											display: 'flex',
											alignItems: 'center'
										}}
									>
										Proses
										{record.iddcoument}
									</Tag>
									{isAdmin ? (

										<div className="ms-auto" style={{ zIndex: '1' }}>
											<Popconfirm title='Apakah anda yakin ingin menghapus dokumen ini?' onConfirm={() => deleteDoc(record.iddocument)} okText="Iya" cancelText="Batal">
												<div className="text-danger" style={{ cursor: 'pointer', fontWeight: '500' }}>
													<BsTrash />
												</div>
											</Popconfirm>
										</div>
									) : (<></>)}
								</div>
							) : (
								<>
									{status === 'kadaluarsa' ? (
										<>
											<div className='d-flex'>
												<Tag
													style={{
														fontWeight: "500",
														color: "#4D5F72",
														fontSize: "14px",
														borderRadius: "4px",
														backgroundColor: "#E7EAEF",
														border: "0px",
														display: 'flex',
														alignItems: 'center'
													}}
												>
													Kadaluarsa
												</Tag>
												{isAdmin &&
													<div className="ms-auto" style={{ borderRadius: '50%' }}>
														{/* <Popover placement="right" overlayInnerStyle={{ background: 'transparent', display: 'flex', alignItems: 'center', boxShadow: 'none' }} content={
															<>
																<Popconfirm title='Apakah anda yakin ingin menghapus dokumen ini?' onConfirm={() => deleteDoc(record.iddocument)} okText="Iya" cancelText="Batal">
																	<div className="p-2 bg-danger text-white" style={{cursor: 'pointer', fontWeight: '500' }}>
																		<BsTrash />
																	</div>
																</Popconfirm>
															</>
														}>
															<BsThreeDotsVertical />
														</Popover> */}
														<Popconfirm title='Apakah anda yakin ingin menghapus dokumen ini?' onConfirm={() => deleteDoc(record.iddocument)} okText="Iya" cancelText="Batal">
															<div className="text-danger" style={{ cursor: 'pointer', fontWeight: '500' }}>
																<BsTrash />
															</div>
														</Popconfirm>
													</div>
												}
											</div>
										</>
									) : (
										<>
											<div className='d-flex'>
												<Tag
													style={{
														fontWeight: "500",
														color: "#058000",
														fontSize: "14px",
														borderRadius: "4px",
														backgroundColor: "#DDF0DD",
														border: "0px",
														display: 'flex',
														alignItems: 'center'
													}}
												>
													Selesai
												</Tag>
												<div className="ms-auto" style={{ borderRadius: '50%' }}>
													<Popconfirm title='Apakah anda yakin ingin mendownload dokumen ini?' onConfirm={() => downloadFile(record.iddocument, record.filename)} okText="Iya" cancelText="Batal">
														<div className="text-primary" style={{ cursor: 'pointer' }}>
															<BsDownload style={{ fontWeight: '600 !important' }} />
														</div>
													</Popconfirm>
												</div>
											</div>
										</>
									)}
								</>
							)}
						</>
					)}
				</>
			),
		},
	];

	const deleteDoc = (params) => {
		DocumentService.deleteDocument(params).then((response) => {
			const array = [...data];
			setData(array.filter(item => item.iddocument !== params))
			message.success('Berhasil menghapus dokumen')
		}, (error) => {
			message.error('Gagal menghapus dokumen')
		})
	}

	const downloadFile = (params, name) => {
		DocumentService.downloadDocument(params).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]), { type: 'application/pdf' })
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', name + '.pdf')
			document.body.appendChild(link)
			link.click()
			link.remove()
			URL.revokeObjectURL(url);
		}, (error) => {
			message.error('Terdapat kesalahan dalam mendownload dokumen')

		})
	}
	useEffect(() => {
		document.title = "Dokumen";
	}, []);

	return (
		<>
			<style type="text/css">
				{`.nav-tabs {
              /* border-radius: 50%; */
              border-bottom: 0px;
              margin-bottom: 12px;
          }

          .nav-tabs .nav-link {
              border: 1px solid #CED4DA;
              border-radius: 32px;
              margin-right: 8px;
              background-color: #fff;
              color: #293DB6;
              margin-bottom: 8px;
          }

          .nav-tabs .nav-link.active {
              background-color: #293DB6;
              border: 0px;
              color: #fff;
              font-weight: 600;
          }

          th.ant-table-cell::before {
              display: none;
          }

          th.ant-table-cell {
              color: #6C757D !important;
              font-size: 14px !important;
              font-weight: 600 !important;
          }

          tbody.ant-table-tbody {
              border: 0px !important
          }`}
			</style>

			<Container fluid>
				{isAdmin ? <NavbarUser /> : <Navbarpenandatangan name={user} />}
				<Container fluid style={{ paddingBottom: "30px" }}>
					<Row className="justify-content-center">
						<Col md={8}>
							<div style={{ marginTop: "40px", marginBottom: "40px" }}>
								<Row>
									<Form.Label
										style={{ fontSize: "20px", fontWeight: "600" }}
										column
										lg={7}
									>
										Dokumen
									</Form.Label>
									<Col>
										<Form.Control
											placeholder="&#xf002; Cari dokumen"
											name="search"
											value={search}
											onChange={(e) => setSearch(e.target.value)}
											style={{ fontFamily: "Poppins,FontAwesome" }}
										/>
									</Col>
								</Row>
							</div>
							<Tabs>
								<Tab eventKey="semua" title="Semua">
									<Card>
										{/* <Card.Body fluid> */}
										<Table
											columns={columns}
											pagination
											dataSource={data.filter((val) => {
												if (search === "") {
													return val;
												} else if (val.filename !== null) {
													if (
														val.filename
															.toLowerCase()
															.includes(search.toLocaleLowerCase())
													) {
														return val;
													}
												}
											})}
											size="small"
										/>
									</Card>
								</Tab>
								{isAdmin ? (
									<Tab eventKey="belumdikirim" title="Belum dikirim">
										<Card>
											<Table
												columns={columns}
												pagination
												dataSource={draft.filter((val) => {
													if (search === "") {
														return val;
													} else if (val.filename !== null) {
														if (
															val.filename
																.toLowerCase()
																.includes(search.toLocaleLowerCase())
														) {
															return val;
														}
													}
												})}
												size="small"
											/>
										</Card>
									</Tab>
								) : (
									<></>
								)}

								<Tab eventKey="proses" title="Proses Tandatangan">
									<Card>
										<Table
											columns={columns}
											pagination
											dataSource={pending.filter((val) => {
												if (search === "") {
													return val;
												} else if (val.filename !== null) {
													if (
														val.filename
															.toLowerCase()
															.includes(search.toLocaleLowerCase())
													) {
														return val;
													}
												}
											})}
											size="small"
										/>
									</Card>
								</Tab>

								<Tab eventKey="selesai" title="Selesai">
									<Card>
										<Table
											columns={columns}
											pagination
											dataSource={done.filter((val) => {
												if (search === "") {
													return val;
												} else if (val.filename !== null) {
													if (
														val.filename
															.toLowerCase()
															.includes(search.toLocaleLowerCase())
													) {
														return val;
													}
												}
											})}
											size="small"
										/>
									</Card>
								</Tab>
								{isAdmin ? (
									<Tab eventKey="expired" title="Kadaluarsa">
										<Card>
											<Table
												columns={columns}
												pagination
												dataSource={expired.filter((val) => {
													if (search === "") {
														return val;
													} else if (val.filename !== null) {
														if (
															val.filename
																.toLowerCase()
																.includes(search.toLocaleLowerCase())
														) {
															return val;
														}
													}
												})}
												size="small"
											/>
										</Card>
									</Tab>
								) : (
									<></>
								)}
							</Tabs>
						</Col>
					</Row>
				</Container>
			</Container>
		</>
	);
};

export default Index;
