import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, List, Input, Space, Empty, Modal, notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Card,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Navbar from "../../components/layout/navbar";
import { BsXLg } from "react-icons/bs";
import { FaPaperPlane } from "react-icons/fa";
import { BsPerson } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../api/user.service";
import DocumentService from "../../api/document.service";

const Penerima = ({ form, setForm, navigation }) => {
  const { state } = useLocation()
  const [idDoc] = useState(state !== null && state.iddocument);
  const [emailPenerima, setEmailPenerima] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { step } = form;
  const [search, setSearch] = useState("");
  const [contact, setContact] = useState([]);
  // const [confirm, setConfirm] = useState(false);
  // useEffect(() => setForm("filename", localStorage.getItem("nama dokumen")));

  // get contact only activated
  const getContact = async () => {
    UserService.getContact().then((response) => {
      setContact(response.data)
    })
  };
  useEffect(() => {
    getContact();
  }, []);
  const localStore = ["base64"];




  const deleteList = (email) => {
    const array = [...emailPenerima];
    array.splice(email, 1);
    setEmailPenerima(array);
  };

  const dataSigner = {
    actiontype: "sirkulir",
    signer: emailPenerima,
  };

  useEffect(() => {
    if (emailPenerima.length !== 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [emailPenerima]);

  // send to people's this document
  const sendToPeople = async () => {
    setConfirmLoading(true)
    DocumentService.sendDocumentToPeople(idDoc, dataSigner).then((response) => {
      localStorage.setItem('terkirim', 1);
      sessionStorage.setItem("orderid", response.data.data.orderid);
      setTimeout(() => {
        localStore.forEach((i) => localStorage.removeItem(i));
        navigation.next();
      }, 2000);
    }, (error) => {
      notification.error({
        message: "Gagal Mengirimkan Dokumen",
        description: error.response.data.message,
        duration: 4,
      });
      setConfirmLoading(false);
    })
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (form.docfilename !== "") {
      localStorage.setItem("nama dokumen", form.docfilename);
    }
  }, []);


  return (
    <>
      {/* <Container fluid> */}
      <p className="m-0" style={{ fontWeight: "600", fontSize: "20px" }}>
        Tambah Kontak Tujuan
      </p>
      <p className="text-secondary">
        Tambahkan kontak orang yang akan menandatangani dokumen ini
      </p>
      <div className="text-secondary">
        Catatan :
        <ul style={{ listStyleType: 'circle' }}>
          <li>Jika terdapat 2 penandatangan, pihak Pertama adalah dari Client dan pihak kedua adalah dari SEVIMA</li>
          <li>Jika terdapat 3 penandatangan, pihak pertama dan kedua adalah dari Client dan pihak ketiga adalah dari SEVIMA</li>
          <li>Untuk pihak pertama diinputkan pertama kali dan diikuti dengan penandatangan selanjutnya</li>
          <li>Pastikan menginputkan nama sesuai dengan urutan penandatangan</li>
        </ul>
      </div>

      <Row>
        {/* <Form onSubmit={onSend}> */}
        <Form.Group style={{ position: "relative" }}>
          <Form.Control
            className="mb-1"
            name="search"
            value={search}
            onChange={handleSearch}
            placeholder="&#xf007; Cari nama kontak terdaftar"
            style={{ fontFamily: "Poppins,FontAwesome" }}
            autoComplete="off"
          />
          {search === "" ? (
            <></>
          ) : (
            <Card
              style={{
                position: "absolute",
                width: "96%",
                display: "block",
                zIndex: "1",
              }}
            >
              <div style={{ padding: "0px 10px 10px 10px" }}>
                <List
                  itemLayout="horizontal"
                  dataSource={contact.filter((val) => {
                    if (search === "") {
                      return val;
                    } else if (
                      val.name.toLowerCase().includes(search.toLowerCase()) ||
                      val.email.toLowerCase().includes(search.toLowerCase()) ||
                      val.orgunit.toLowerCase().includes(search.toLocaleLowerCase())
                    ) {
                      return val;
                    }
                  })}
                  pagination={{ pageSize: 10 }}
                  renderItem={(item) => (
                    <List.Item>
                      <div style={{ width: "100%" }}>
                        <List.Item.Meta
                          onClick={() => {
                            setEmailPenerima([
                              ...emailPenerima,
                              {
                                email: item.email,
                                nama: item.name,
                                unit: item.orgunit,
                              },
                            ]);
                            setSearch("");
                          }}
                          style={{ cursor: "pointer" }}
                          avatar={
                            <div>
                              <Avatar
                                size="large"
                                style={{
                                  backgroundColor: "#9EC5FE",
                                  color: "#0A58CA",
                                  fontWeight: "500",
                                }}
                              >
                                {item.inisial}
                              </Avatar>
                            </div>
                          }
                          title={
                            <div style={{ marginLeft: "-10px" }}>
                              <span style={{ fontWeight: "600" }}>
                                {item.name}
                              </span>{" "}
                              <br />
                              <span style={{ fontWeight: "400" }}>
                                {item.orgunit} - {item.email}
                              </span>
                            </div>
                          }
                        />
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          )}
        </Form.Group>
        {/* </Form> */}
      </Row>
      {/* <Search
          enterButton={
            <>
              <SendOutlined />
            </>
          }
          name="value"
          onSearch={onSend}
          value={valueSearch}
          onChange={(e) => setValueSearch(e.target.value)}
        /> */}

      <div style={{ height: "270px" }}>
        {/* <InfiniteScroll
            dataLength={emailPenerima.length}
            // hasMore={emailPenerima.length < 50}
            scrollableTarget="scrollableDiv" */}
        {/* > */}
        {emailPenerima.length !== 0 ? (
          <>
            <p style={{ fontWeight: "600" }}>Daftar Kontak Tujuan</p>

            <Row md={2} className="g-2">
              {emailPenerima.map((item, idx) => (
                <Col key={idx}>
                  <Card style={{ borderRadius: "8px", padding: "4px" }}>
                    <div className="d-flex align-items-center">
                      <div className='ms-1'>
                        <Avatar
                          size="large"
                          className="d-flex align-items-center"
                          style={{
                            backgroundColor: "#9EC5FE",
                          }}
                        >
                          <div>
                            <strong>{idx + 1}</strong>
                          </div>
                        </Avatar>
                      </div>
                      <div style={{ width: "100%" }} className="ms-2">
                        <span style={{ fontWeight: "600", fontSize: "16px" }}>
                          {item.nama}
                        </span>
                        <br />
                        <span style={{ fontSize: "14px", color: "gray" }}>
                          {item.unit}
                        </span>
                        <br />
                        <span style={{ fontSize: "14px", color: "gray" }}>
                          {item.email}
                        </span>
                      </div>
                      <div className="ms-auto">
                        <BsXLg
                          style={{ cursor: "pointer" }}
                          onClick={() => deleteList(idx)}
                        />
                      </div>
                    </div>

                    {/* <div> */}
                    {/* <span>email</span> */}
                    {/* </div> */}
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "270px" }}
          >
            Daftar kontak tujuan masih kosong
          </div>
        )}

      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "104px",
          // float: "right",
        }}
      >
        <span
          style={{ color: "#293DB6", cursor: "pointer" }}
          onClick={() => {
            navigation.next();
            localStorage.removeItem('step')
            localStorage.setItem("terkirim", "0");
          }}
        >
          Kirim nanti saja
        </span>

        {!confirmLoading ? (
          <>
            {disabled ? (
              <></>
            ) : (
              <Button
                style={{
                  backgroundColor: "#293DB6",
                  border: "0px",
                }}
                className="ms-auto"
                onClick={() => { sendToPeople(); localStorage.removeItem('step') }}
              // onClick={() => console.log(emailPenerima)}
              >
                <span>
                  <FaPaperPlane />
                </span>{" "}
                Kirim Dokumen
              </Button>
            )}
          </>
        ) : (
          <Button
            style={{
              backgroundColor: "#293DB6",
              border: "0px",
            }}
            className="ms-auto"
            disabled
          >
            <div
              className="spinner-border text-secondary"
              style={{ width: "20px", height: "20px" }}
              role="status"
            ></div>
            {""}
            Kirim Dokumen
          </Button>
        )}
      </div>
      {/* </Container> */}
    </>
  );
};

export default Penerima;
