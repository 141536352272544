import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import confirm from "antd/lib/modal/confirm";
import { Popconfirm, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DocumentService from "../../api/document.service";

// const { confirm } = Modal;

const Review = ({ form, setForm, navigation }) => {
  const navigate = useNavigate()
  const { state } = useLocation();
  const [document, setDocument] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [isLanjut, setIslanjut] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [idDoc] = useState(state !== null && state.iddocument)
  const [typeDoc, setTypeDoc] = useState([
    { kode: 'Surat Keterangan', nama: 'Surat Keterangan' },
    { kode: 'Dokumen penerimaan uang (lebih dari 5 juta)', nama: 'Dokumen penerimaan uang (lebih dari 5 juta)' },
    { kode: 'Surat Lainnya', nama: 'Surat Lainnya' },
    { kode: 'Surat Pernyataan', nama: 'Surat Pernyataan' },
    { kode: 'Dokumen Transaksi', nama: 'Dokumen Transaksi' },
    { kode: 'Dokumen pelunasan utang (lebih dari 5 juta)', nama: 'Dokumen pelunasan utang (lebih dari 5 juta)' },
    { kode: 'Surat Berharga', nama: 'Surat Berharga' },
    { kode: 'Akta Pejabat', nama: 'Akta Pejabat' },
    { kode: 'Dokumen lain-lain', nama: 'Dokumen lain-lain' },
    { kode: 'Akta Notaris', nama: 'Akta Notaris' },
    { kode: 'Dokumen Lelang', nama: 'Dokumen Lelang' },
    { kode: 'Surat Perjanjian', nama: 'Surat Perjanjian' },
    { kode: 'Dokumen pernyataan jumlah uang lebih dari 5jt', nama: 'Dokumen pernyataan jumlah uang lebih dari 5jt' },
  ])
  // console.log(state)

  // form
  const { step, location, docfilename, docname, docnumber } = form;
  const temp = localStorage.getItem("step");
  useEffect(() => {
    if (state === null) {
      navigate(-1)
    }
    if (temp === '1') {
      navigation.next();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setForm
  const handleReason = (e) => {
    setForm(e.target.name, e.target.value);
  };

  // config

  // refreshToken
  // const refresh = {
  //   refresh_token: refresh_token,
  // };

  // useEffect(() => {
  //   // if (token) {
  //   //   // setForm("tempId", idDoc);
  //   //   getBaseDoc();
  //   // }
  // }, [token]);

  // useEffect(() => {
  //   setForm("docfilename", nameDoc);
  //   if (idDoc === null) {
  //     go("/home");
  //   }
  // }, []);

  // useEffect button lanjut
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      form.location === "" ||
      form.docfilename === "" ||
      form.docname === "" ||
      form.docnumber === ""
    ) {
      setIslanjut(false);
    } else {
      setIslanjut(true);
    }
  });

  // const refreshToken = (fungsi) => {
  //   const reqToken = async () => {
  //     try {
  //       const response = await axios.post("refreshtoken", refresh, config);
  //       // localStorage.setItem()
  //       console.log(response);
  //       setToken(response.data.data.access_token);
  //       localStorage.setItem("token", response.data.data.access_token);
  //       fungsi();
  //     } catch (error) {
  //       console.log(error.response);
  //     }
  //   };
  //   reqToken();
  // };

  const getBaseDoc = () => {
    DocumentService.getBase64Document(idDoc).then((response) => {
      setDocument(
        "data:application/pdf;base64," + response.data.data.base64document
      );
      setForm("base64document", response.data.data.base64document);
      localStorage.setItem("base64", form.base64Document);
    })
    // try {
    //   const response = await axios.get(`document/view?id=${idDoc}`, config);
    //   setDocument(
    //     "data:application/pdf;base64," + response.data.data.base64document
    //   );
    //   setForm("base64document", response.data.data.base64document);
    //   localStorage.setItem("base64", form.base64Document);
    //   // console.log(response);
    // } catch (error) {
    //   console.log(error.response);
    //   if (
    //     error.response.data["message"] ===
    //     "tidak ada authentikasi, illegal akses"
    //   ) {
    //     refreshToken();
    //   }
    // }
  };

  const getDocType = async () => {
    DocumentService.getDocType().then((response) => {
      setTypeDoc(response.data.data)
    })
    // try {
    //   const response = await axios.get(`document/doctype`, config);
    //   setTypeDoc(response.data.data)
    //   // console.log(response)
    // } catch (err) {
    //   console.log(err.response)
    // }
  }

  useEffect(() => {
    getDocType()
    getBaseDoc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // create e-materai
  const createMaterai = async () => {
    setConfirmLoading(true)
    DocumentService.createMeterai(idDoc, form).then((response) => {
      setTimeout(() => {
        localStorage.setItem("nama dokumen", form.docfilename);
        setConfirmLoading(false);
        navigation.next();
      }, 2000);
    })
    // try {
    //   setConfirmLoading(true);
    //   const response = await axios.post(
    //     `materai/create?id=${idDoc}`,
    //     form,
    //     config
    //   );
    //   console.log(response);
    //   setTimeout(() => {
    //     localStorage.setItem("nama dokumen", form.docfilename);
    //     setConfirmLoading(false);
    //     navigation.next();
    //   }, 2000);
    // } catch (err) {
    //   console.log(err.response);
    //   setConfirmLoading(false);
    // }
  };

  // confirm
  const showConfirm = () => {
    setConfirm(true);
    setPopUp(false);
  };

  // open modal
  // const getReason = () => {
  //   setForm("docfilename", localStorage.getItem("nama dokumen"));
  //   setPopUp(true);
  // };

  // close modal
  const CloseReason = () => {
    setPopUp(false);
  };
  // close confirm
  const closeConfirm = () => {
    setConfirm(false);
    setPopUp(true);
  };


  return (
    <>
      {/* <Navbar /> */}
      {/* <Container fluid> */}
      <p style={{ fontWeight: "600", fontSize: "20px" }}>
        Data Kelengkapan Dokumen
      </p>
      <Row xs={1} md={2} className='g-2'>
        <Col>
          <Form.Group className="mb-3">
            <TextField
              fullWidth
              id="fullWidth"
              name="docnumber"
              value={docnumber}
              onChange={handleReason}
              placeholder="Nomor Dokumen"
              label="Nomor Dokumen"
              variant="outlined"
              style={{ width: '100%' }} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <TextField
              fullWidth
              id="fullWidth"
              variant="outlined"
              name="docfilename"
              value={docfilename}
              onChange={handleReason}
              placeholder="Nama Dokumen"
              label='Nama Dokumen' />

          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <TextField
              fullWidth
              id="fullWidth"
              variant="outlined"
              name="location"
              value={location}
              onChange={handleReason}
              label='Lokasi Penandatangan'
              placeholder="Contoh: Jakarta" />
          </Form.Group>
        </Col>
        <Col>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Pilih jenis dokumen</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="docname"
              value={docname}
              onChange={handleReason}
              label='Pilih jenis dokumen'
            >
              {typeDoc.map((item, key) => (

                <MenuItem value={item.nama}>{item.nama}</MenuItem>
              ))}
              {/* <MenuItem value='Surat Lainnya'>Surat Lainnya</MenuItem> */}

            </Select>
          </FormControl>
        </Col>
      </Row>
      <p className="m-0" style={{ fontWeight: "600", fontSize: "20px" }}>
        Pratinjau
      </p>
      <p style={{ fontSize: "14px" }} className="text-secondary">
        Nama File: {form.docfilename}
      </p>
      <div
        style={{
          border: "1px solid #c4c4c4",
          height: "100vh",
          backgroundColor: "#eee !important",
          // marginBottom: "34px",
        }}
      >
        {document !== "" ? (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
            <Viewer fileUrl={document} defaultScale={SpecialZoomLevel.PageWidth} withCredentials={true} />
          </Worker>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "104px",
          float: "right",
        }}
      // className="ms-auto"
      >
        {isLanjut ? (
          <>
            {!confirmLoading ? (
              <Button
                style={{ border: "0px", background: "#293DB6" }}
                onClick={createMaterai}
              >
                Lanjutkan
              </Button>
            ) : (
              <Button style={{ border: "0px", background: "#293DB6" }} disabled>
                <div
                  className="spinner-border text-secondary"
                  style={{ width: "20px", height: "20px" }}
                  role="status"
                ></div>{" "}
                Lanjutkan
              </Button>
            )}
          </>
        ) : (
          <Button style={{ border: "0px", background: "#293DB6" }} disabled>
            Lanjutkan
          </Button>
        )}
      </div>

      {/* modal for confirm */}
      <Modal show={confirm} onHide={closeConfirm} centered>
        <Modal.Body>
          <Container className="text-center">
            <ExclamationCircleTwoTone
              twoToneColor="#fbb834"
              style={{ fontSize: "100px" }}
            />
            <h5 style={{ marginTop: "20px" }}>Apakah Anda Yakin ?</h5>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ border: "0px", marginTop: "-30px" }}>
          <p
            style={{
              cursor: "pointer",
              color: "#cecece",
              fontWeight: "600",
              marginRight: "20px",
              fontSize: "18px",
            }}
            onClick={closeConfirm}
          >
            Batal
          </p>
          {!confirmLoading ? (
            <Button className="btn-danger button" onClick={createMaterai}>
              Lanjut
            </Button>
          ) : (
            <Button className="btn-danger button" disabled>
              <div
                className="spinner-border text-secondary"
                style={{ width: "20px", height: "20px" }}
                role="status"
              ></div>{" "}
              Lanjut
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Review;
