import React from "react";
import { Col, Container, Row, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import iconSuccess from "../../img/empty.svg";

const Success = () => {
  const navigate = useNavigate();
  // setTimeout(() => {
  //   navigate("/home");
  // }, 5000);
  return (
    <>
      <Container fluid>
        <Card>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "502" }}
          >
            <div className="text-center">
              <img src={iconSuccess} alt="success" />
              <p style={{ fontWeight: "600", fontSize: "24px", margin: "0px" }}>
                Dokumen berhasil ditandatangani
              </p>
              <p>
                Dokumen <b>{localStorage.getItem("nama dokumen")}</b> telah anda
                tandatangani
              </p>
              <Button
                variant="outline-primary"
                onClick={() => navigate("/document")}
              >
                Lihat Semua Dokumen
              </Button>
            </div>
          </div>
        </Card>
      </Container>
    </>
  );
};

export default Success;
