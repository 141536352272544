import api from "./api";
import TokenService from "./token.service";

const register = (data) => {
    return api.post("/auth/signup", data).then((response) => {
        return response
    });
};

const login = (data) => {
    return api
        .post("login", data)
        .then((response) => {
            if (response.data.access_token) {
                TokenService.setUser(response.data);
            }
            return response.data;
        })
};

const check_sertificate = (email) => {
    return api.post('cek/sertifikat', email).then((response) => {
        return response.data
    })
}

const logout = () => {
    return api.post('logout', '').then((response) => {
        localStorage.clear()
        TokenService.removeUser();
        return response
    })
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    register,
    login,
    logout,
    check_sertificate,
    getCurrentUser,
};

export default AuthService;