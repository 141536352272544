import api from "./api"

const getUserMe = () => {
    return api.get('user/me').then((response) => { return response })
}

const getContact = () => {
    return api.get('user/contact').then(((response) => {
        return response
    }))
}

const getContactAll = () => {
    return api.get('user/contact?type=all').then((response) => {
        return response
    })
}

const checkEligibleEmail = (email) => {
    return api.get(`user/eligible?email=${email}`).then((response) => {
        return response
    })
}

const inviteUser = (emails) => {
    return api.post(`user/invitation`, { users: emails }).then((response) => {
        return response
    })
}

const failedRegister = (email) => {
    return api.post(`request/invitationexist`, { users: email }).then((response) => {
        return response
    })
}

const UserService = {
    getUserMe,
    getContact,
    getContactAll,
    checkEligibleEmail,
    inviteUser,
    failedRegister
}

export default UserService;