import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks";
import "./index.css";
import Logo from "../../img/logo.svg";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import jwt_decode from "jwt-decode";
import AuthService from "../../api/auth.service";
import { Result } from "antd";
import UserService from "../../api/user.service";
// login form
const dataLogin = {
  email: "",
  password: "",
};

const Index = () => {
  const [form, setForm] = useForm(dataLogin);
  const { email, password } = form;
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [alert, setAlert] = useState(false);
  const [infoAPI, setInfoAPI] = useState("");
  const [confirmLoading, setConfirmloading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [lupaPassword, setLupaPassword] = useState(false);
  const [timeOut, setTimeOut] = useState(20);
  const [resend, setResend] = useState(false);
  const [time, setTime] = useState("");
  const [token] = useState(localStorage.getItem("user"));
  const [failedVerif, setFailedVerif] = useState(false);

  // use navifate
  const navigate = useNavigate();

  // handle form
  const handleInput = e => {
    setForm(e.target.name, e.target.value);
  };

  const handleSubmitLogin = e => {
    localStorage.clear();
    e.preventDefault();
    setConfirmloading(true);
    setIsSubmit(true);
    setAlert(false);
    setFormErrors(validate(form));
  };

  // useEffect to handle error
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (disable) {
        loginTrigger(form);
      } else {
        checkSertif(form);
      }
    } else {
      setConfirmloading(false);
    }
  }, [formErrors]);

  // useEffect change title
  useEffect(() => {
    document.title = "Login";
  }, []);

  // handle send login
  const loginTrigger = formValues => {
    AuthService.login(formValues)
      .then(response => {
        let base64 = response.access_token;
        let base64split = base64.split(".");
        const check = base64split[1];
        localStorage.setItem("payload", check);
        navigate("/home");
        // setConfirmloading(false)\
      })
      .catch(e => {
        setInfoAPI("Password yang dimasukkan salah.");
        setAlert(true);
        setConfirmloading(false);
      });
  };

  // handle validate
  const validate = values => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email tidak boleh kosong";
    } else if (!regex.test(values.email)) {
      errors.email = "Email yang dimasukkan tidak valid";
    }
    if (disable) {
      if (!values.password) {
        errors.password = "Password tidak boleh kosong";
      } else if (values.password.length < 8) {
        errors.password = "Password minimal 8 karakter";
      }
    }
    return errors;
  };

  const checkSertif = params => {
    AuthService.check_sertificate(params).then(
      () => {
        setConfirmloading(false);
        setDisable(true);
      },
      error => {
        if (error.response.data.data.backEndResponse) {
          if (error.response.data.data.backEndResponse.resultCode === "08") {
            setConfirmloading(false);
            setInfoAPI("Akun Anda masih dalam proses verifikasi.");
            setAlert(true);
          } else if (
            error.response.data.data.backEndResponse.resultCode === "04" ||
            error.response.data.data.backEndResponse.resultCode === "07"
          ) {
            localStorage.setItem("name", error.response.data.data.user.name);
            localStorage.setItem("email", error.response.data.data.user.email);

            if (error.response.data.data.backEndResponse.resultCode === "07") {
              setTimeout(() => {
                navigate("/ekyc-renew");
                setConfirmloading(false);
              }, 1000);
            } else {
              setTimeout(() => {
                navigate("/ekyc");
                setConfirmloading(false);
              }, 1000);
            }
          } else if (
            error.response.data.data.backEndResponse.resultCode === "15"
          ) {
            setConfirmloading(false);
            setInfoAPI("Menunggu Verifikasi");
            setAlert(true);
          } else if (
            error.response.data.data.backEndResponse.resultCode === "02"
          ) {
            setConfirmloading(false);
            setInfoAPI("Email Tidak Terdaftar");
            setAlert(true);
          } else if (
            error.response.data.data.backEndResponse.resultCode === "16"
          ) {
            UserService.failedRegister(email).then(response => {
              setConfirmloading(false);
              setInfoAPI("Proses Verifikasi gagal, Silahkan registrasi ulang.");
              setAlert(true);
              setFailedVerif(true);
            });
          }
        } else if (error.response.status === 404) {
          setConfirmloading(false);
          setInfoAPI("Email Tidak Terdaftar");
          setAlert(true);
        } else if (error.response.status === 408) {
          setConfirmloading(false);
          setInfoAPI("Masalah Koneksi");
          setAlert(true);
        } else {
          setConfirmloading(false);
          setInfoAPI("Internal Server Error. Coba sekali lagi");
          setAlert(true);
        }
      }
    );
  };

  const styleInput = {
    borderRight: "0px",
    ":focus": {
      outline: "0px",
    },
  };

  // set time to resend email forgot password
  const updateTimer = () => {
    setTimeOut(timeOut - 1);
    const sec = Math.floor(timeOut % 60);
    setTime(`${sec}`);
    if (timeOut === 0) {
      setResend(true);
    }
  };

  useEffect(() => {
    if (lupaPassword && timeOut >= 0) {
      const intervalTimer = setInterval(() => {
        updateTimer();
      }, 1000);
      return () => clearTimeout(intervalTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOut, lupaPassword]);

  // request reset password
  const resetpassword = async params => {
    setLupaPassword(true);
    setTimeOut(20);
    setResend(false);
    try {
      const response = await axios.post("request/forget-password", {
        email: params,
      });
    } catch (err) {}
  };

  // useEffect for check token
  useEffect(() => {
    if (token) {
      const decode = jwt_decode(token);
      const expired = decode.exp;
      if (expired > new Date() / 1000) {
        navigate(-1);
      } else {
        localStorage.clear();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <Row
          className="justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Col md={6}>
            <div className="text-center">
              <img src={Logo} alt="SEVIMA" style={{ marginBottom: "9px" }} />
              {!alert ? (
                <p style={{ fontWeight: "400", marginBottom: "51px" }}>
                  e-Sign System
                </p>
              ) : (
                <div className="d-flex justify-content-center">
                  <Alert
                    className="text-start"
                    style={{ width: "404px" }}
                    variant="danger"
                  >
                    {infoAPI}
                  </Alert>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <Card
                className="card-login"
                style={{ border: "1px solid #E9ECEF" }}
              >
                <Container>
                  <h4
                    className="text-center"
                    style={{ fontWeight: "600", marginTop: "48px" }}
                  >
                    LOGIN
                  </h4>
                  <Form
                    style={{ marginTop: "44px" }}
                    onSubmit={handleSubmitLogin}
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Email terdaftar"
                        value={email}
                        onChange={handleInput}
                        autoFocus
                        className="inputpass"
                        style={disable ? { background: "white" } : {}}
                        disabled={disable ? "disable" : ""}
                      />
                      <span className="text-danger">{formErrors.email}</span>
                    </Form.Group>
                    {disable ? (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label>Password</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={password}
                              onChange={handleInput}
                              placeholder="Password akun"
                              autoComplete="off"
                              autoFocus
                              className="inputpass"
                              style={styleInput}
                            />
                            <InputGroup.Text
                              style={{
                                backgroundColor: "transparent",
                                borderLeft: "0px",
                              }}
                            >
                              <span>
                                {!showPassword ? (
                                  <FaEyeSlash
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setShowpassword(true)}
                                  />
                                ) : (
                                  <FaEye
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setShowpassword(false)}
                                  />
                                )}
                              </span>
                            </InputGroup.Text>
                          </InputGroup>
                          <span className="text-danger">
                            {formErrors.password}
                          </span>
                        </Form.Group>
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            resetpassword(email);
                            localStorage.setItem("email", email);
                          }}
                        >
                          Lupa password?
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    {!confirmLoading ? (
                      <Button
                        className="form-control"
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          marginTop: "20px",
                          marginBottom: "38px",
                          background: "#293DB6",
                          border: "0px",
                        }}
                        type="submit"
                      >
                        {!disable ? "Lanjutkan" : "Login"}
                        {/* Login */}
                      </Button>
                    ) : (
                      <Button
                        className="form-control"
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          marginTop: "20px",
                          marginBottom: "38px",
                          background: "#293DB6",
                          border: "0px",
                        }}
                        disabled
                      >
                        <div
                          className="spinner-border text-white"
                          style={{ width: "20px", height: "20px" }}
                          role="status"
                        ></div>{" "}
                        {/* Login */}
                        {!disable ? "Lanjutkan" : "Login"}
                      </Button>
                    )}
                  </Form>
                  {/* <hr className="m-0" />
                  <p
                    className="text-center"
                    style={{ marginTop: "28px", marginBottom: "24px" }}
                  >
                    Belum memiliki akun ?
                  </p>
                  <Button
                    variant="outline-primary"
                    className="form-control"
                    style={{ marginBottom: "26px" }}
                    onClick={() => navigate("/register")}
                  >
                    Buat Akun
                  </Button> */}
                </Container>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={lupaPassword} centered>
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{ height: "80px" }}
        >
          <div className="text-center">
            <img src={Logo} alt="logo" /> <br />
            <span style={{ fontSize: "24px" }}>Atur ulang kata sandi</span>
          </div>
        </Container>
        <Modal.Body>
          <div className="text-center">
            <p>
              Kami telah mengirimkan token untuk atur ulang kata sandi ke email{" "}
              <b>{email}</b>, silahkan cek kotak masuk email anda.
            </p>
          </div>
          <hr />
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex align-items-center">
              <span>Belum menerima email?</span>
            </div>
            {resend ? (
              <Button
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  background: "#293DB6",
                  border: "0px",
                }}
                onClick={() => resetpassword(email)}
                className="ms-3"
              >
                Kirim Ulang
              </Button>
            ) : (
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#293DB6",
                }}
                className="ms-3"
              >
                Kirim Ulang {time}
              </span>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={failedVerif} centered>
        <Result
          status="warning"
          title="Proses verifikasi data anda ditolak."
          extra={<span>Silahkan cek email anda untuk registrasi ulang</span>}
        />
      </Modal>
    </>
  );
};

export default Index;
