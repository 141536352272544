import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Card, Alert, Container, Row, Col } from 'react-bootstrap';
import Webcam from "react-webcam";
import EkycIcon from '../../img/EkycIcon.jpg'

const Ekyc = ({ formData, setForm, navigation }) => {
    const webCamRef = useRef(null);
    const mediaRecorder = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordChunks, setRecordChunks] = useState([]);
    const [showRecord, setShowRecord] = useState(true);
    const [activeCam, setActiveCam] = useState(false);
    const [vid, setVid] = useState('');
    const { base64video } = formData;
    const [time, setTime] = useState(8)
    const [activeButton, setActiveButton] = useState(false)

    const handleSetCapture = useCallback(() => {
        setCapturing(true);
        setRecordChunks([])
        mediaRecorder.current = new MediaRecorder(webCamRef.current.stream, {
            mimeType: 'video/webm'
        });
        mediaRecorder.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorder.current.start()
        setTimeout(() => {
            handleStop()
        }, 8000);
    }, [webCamRef, setCapturing, mediaRecorder]);

    const handleDataAvailable = useCallback(({ data }) => {
        if (data.size > 0) {
            setRecordChunks((prev) => prev.concat(data));
        }
    }, [setRecordChunks]);

    const handleStop = useCallback(() => {
        mediaRecorder.current.stop();
        setCapturing(false);
        setShowRecord(false)
        handleDownload()
    }, [mediaRecorder, webCamRef, setCapturing]);

    const handleDownload = useCallback(() => {
        if (recordChunks.length) {
            const blob = new Blob(recordChunks, { type: 'video/webm' });
            blobToBase64(blob)
        }
    }, [recordChunks])

    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
                const split = reader.result.split(',');
                setVid(reader.result);
                setForm('base64video', split[1]);
                localStorage.setItem("video", split[0]);
            };
        });
    };

    useEffect(() => {
        handleDownload()
    }, [recordChunks.length])

    useEffect(() => {
        if (capturing) {
            const timer =
                time > 0 && setInterval(() => setTime(time - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [time, capturing])

    useEffect(() => {
        setTimeout(() => {
            setActiveButton(true)
        }, 6000);
    }, [activeCam])
    useEffect(() => {
        setForm("step", 2);
    }, []);

    return (
        <>
            <style type="text/css">
                {`#videoElement {
                            transform: rotateY(180deg);
                            -webkit-transform:rotateY(180deg);
                            -moz-transform:rotateY(180deg);
                }`}
            </style>
            <Card
                style={{
                    marginBottom: "30px",
                    border: "1px solid #F8F9FA",
                }}
            >
                <Card.Body>
                    <h5
                        style={{
                            fontWeight: "600",
                            marginTop: "20px",
                            marginBottom: "28px",
                        }}
                    >
                        Video Verifikasi
                    </h5>
                    <Container fluid className='d-flex justify-content-center'>
                        {activeCam ? (
                            <Row className='justify-content-center'>
                                <Col md={10} sm={12}>
                                    {showRecord ? (
                                        <>
                                            <Alert>Ucapkan kalimat, “
                                                <b>Saya {formData.name} setuju untuk membuat akun."</b>, Silakan menoleh ke kiri dan ke kanan secara perlahan</Alert>
                                            <Webcam mirrored={true} style={{ maxWidth: '100%', borderRadius: '10px', maxHeight: '500px' }} ref={webCamRef} />
                                        </>
                                    ) : (
                                        <video style={{ maxWidth: '100%', borderRadius: '10px', maxHeight: '500px' }} id='videoElement' src={vid} autoPlay></video>
                                    )}
                                </Col>
                            </Row>

                        ) : (
                            <Row className='justify-content-center'>
                                <Col md={8} sm={10}>
                                    <Card className='d-flex justify-content-center' style={{ borderRadius: '10px', padding: '10px' }}>
                                        <div>
                                            <img src={EkycIcon} className='d-block m-auto' style={{ maxWidth: '200px' }} alt="icon" />
                                            <p className='text-center'>Kami akan merekam wajah anda selama 8 detik untuk kepentingan aktivasi tanda tangan elektronik. Mohon untuk tidak menggunakan aksesoris pada wajah anda saat melakukan perekaman (seperti: kacamata, masker, dll). Silahkan mengikuti instruksi yang muncul dilayar kamera. Klik aktifkan kamera untuk mulai merekam</p>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Container>
                    <div className='d-flex justify-content-center mt-2'>
                        {activeCam ? (
                            <>
                                {capturing ? (
                                    <Button style={{ background: "#084298", border: "0px" }} disabled>sedang merekam {time}</Button>
                                ) : (
                                    <>
                                        {recordChunks.length ? (
                                            <>
                                                <div>

                                                    <div className='d-flex justify-content-center'>
                                                        <Button variant='outline-primary' onClick={() => { setRecordChunks([]); setActiveCam(false); setShowRecord(true); setVid(''); setTime(8) }}>Rekam Ulang</Button>
                                                    </div>
                                                    <Button
                                                        className='mt-1'
                                                        style={{ background: "#084298", border: "0px" }}
                                                        onClick={() => navigation.next()}
                                                    >
                                                        Simpan & Lanjutkan
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <Button onClick={handleSetCapture} style={{ background: '#084298', border: '0px' }} disabled={activeButton ? false : true}>Mulai rekam</Button>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <Button onClick={() => setActiveCam(true)} style={{ background: '#084298', border: '0px' }}>Aktifkan kamera</Button>
                        )}
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}

export default Ekyc;