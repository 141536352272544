import React from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import Navbar from '../../components/layout/navbar'

const Waiting = () => {
    return (
        <>
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col md={6}>
                        <Navbar />

                        <Card className="text-center" style={{ marginTop: "40px", border: '0px' }}>
                            <Alert variant="white">
                                <Alert.Heading>Akun sedang dalam proses verifikasi</Alert.Heading>
                                <p>
                                    Proses verifikasi akun berlangsung sekitar 1 x 24 jam. Silahkan hubungi Admin Aplikasi apabila dalam 1 x 24 jam tidak ada digital certificate yang dikirim ke email anda
                                </p>
                                <hr />
                                <p style={{ fontWeight: "600", color: "#293DB6" }}>
                                    Informasi Tentang Verifikasi
                                </p>
                            </Alert>
                            {/* <p style={{ fontWeight: "600", fontSize: "24px" }}>
            </p>
            */}
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Waiting