import { Avatar, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import NavbarUser from "../../components/layout/NavbarUser";
import {
  InfoCircleTwoTone,
  UserOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import axios from "axios";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import Navbarpenandatangan from "../../components/layout/navbarpenandatangan";
import Check from "../../img/check.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { RenderCurrentPageLabelProps } from "@react-pdf-viewer/page-navigation";
import FormatDate from "../../components/formatDate";
import disableScrollPlugin from "../../components/disableScrollPlugin";
import CountExpired from "../../components/countExpired";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import UserService from "../../api/user.service";
import DocumentService from "../../api/document.service";

function Detail() {
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const { CurrentPageLabel } = pageNavigationPluginInstance;
  const disableScrollPluginInstance = disableScrollPlugin();

  const { GoToNextPage, GoToPreviousPage } =
    pageNavigationPluginInstance;

  const payload = localStorage.getItem("payload");
  const [document, setDocument] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate()
  const [signer, setSigner] = useState([]);
  const [idDoc] = useState(state !== null && state.iddocument);
  const [nameFile, setNameFile] = useState("");
  const [dateFile, setDateFile] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [details, setDetails] = useState(false);
  const [donesign, setDoneSign] = useState(false);
  const [wait, setWaiting] = useState(false);
  const Navigate = useNavigate();
  const [isExpired, setExpired] = useState(false)

  // config for auth
  useEffect(() => {
    if (payload) {
      const decodeBase64 = atob(payload);
      const check = JSON.parse(decodeBase64);
      if (check.isVerified === "0") {
        Navigate("/");
      }
    } else {
      Navigate("/");
    }
  });

  // use effect ger role user
  useEffect(() => {
    if (state === null) {
      navigate(-1)
    }
    getRole();
    console.log(state)
  }, []);

  // get role of user
  const getRole = () => {
    UserService.getUserMe().then((response) => {
      if (response.data.data[0].role === "0") {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
        setDetails(true);
      }
    })
  };

  // view document
  const viewDoc = () => {
    DocumentService.getBase64Document(idDoc).then((response) => {
      setDocument(
        "data:application/pdf;base64," + response.data.data.base64document
      );
    })
  };

  // get information document
  const informationDoc = () => {
    DocumentService.getDocumentInformation(idDoc).then((response) => {
      const tanggalExp = CountExpired(response.data.data.expired_at)
      if (tanggalExp === 'Sudah Kadaluarsa')
        setExpired(true)
      setDateFile(FormatDate(response.data.data.created));
      setNameFile(response.data.data.title);
      localStorage.setItem("nama dokumen", response.data.data.title);
    })
  };

  // get signer
  const getSigner = async () => {
    DocumentService.getSigner(idDoc).then((response) => {
      const signDoc = response.data.signer;
      setSigner(signDoc);
      // console.log(signDoc[0].email === signerEmail);
      for (var i = 0; i < signDoc.length; i++) {
        if (signDoc[i].email === sessionStorage.getItem('email')) {
          if (signDoc[i].issigned === '1') {
            setDoneSign(true)
          } else {
            if (i !== 0) {
              if (signDoc[i - 1].issigned === '0') {
                setWaiting(true)
              } else {
                setDoneSign(false)
              }
            }
          }
        }
      }
    })
  };

  // run function with useEffect view document, get signer and information document
  useEffect(() => {
    viewDoc();
    getSigner();
    informationDoc();
  }, []);

  return (
    <>
      <Container fluid>
        {isAdmin ? <NavbarUser /> : <Navbarpenandatangan />}
        <Container fluid>
          <Row className="justify-content-center" style={{ marginTop: "40px" }}>
            <Col md={8}>
              <Card style={{ borderRadius: '8px', border: '0px' }}>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <div>
                        <span>Nama Dokumen</span>
                        <p style={{ fontWeight: "600", fontSize: "16px" }}>
                          {nameFile}
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <span>Tanggal Kirim</span>
                        <p style={{ fontWeight: "600", fontSize: "16px" }}>
                          {dateFile}
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <span>Pihak yang menandatangani</span>
                        <div>
                          <Row md={2} sm={1} className='g-2' style={{ marginTop: "8px" }}>
                            {signer.map((item, idx) => (
                              <Col>
                                <Card
                                  key={idx}
                                  style={{
                                    width: "100px !important",
                                    border: "0px",
                                  }}
                                >
                                  <div className='d-flex'>

                                    <div className='d-flex align-items-center'>{idx + 1}.</div>
                                    <div className="my-auto ms-1">
                                      <Avatar
                                        // className="mr-3"
                                        icon={<UserOutlined className="p-1" />}
                                      />
                                    </div>
                                    <div className="ms-1">
                                      <span style={{ fontWeight: "600" }}>
                                        {item.name}
                                      </span>
                                      <br />
                                      <span>{item.orgunit}</span>
                                    </div>
                                    <div className='mt-1' style={{ right: '0', position: 'absolute' }}>
                                      {item.issigned === "0" ? (
                                        <Tag
                                          style={{
                                            fontWeight: "500",
                                            color: "#004680",
                                            fontSize: "14px",
                                            borderRadius: "4px",
                                            backgroundColor: "#DBE4FA",
                                            border: "0px",
                                          }}
                                        >
                                          Belum
                                        </Tag>
                                      ) : (
                                        <Tag
                                          style={{
                                            fontWeight: "500",
                                            color: "#058000",
                                            fontSize: "14px",
                                            borderRadius: "4px",
                                            backgroundColor: "#DDF0DD",
                                            border: "0px",
                                          }}
                                        >
                                          Selesai
                                        </Tag>
                                      )}
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8}>
              <Card
                style={{
                  marginTop: "13px",
                  marginBottom: "42px",
                }}
              >
                <div>
                  <div
                    style={{
                      height: "150vh",
                      position: "relative",
                      display: "flex",
                    }}
                  >
                    {document !== "" ? (
                      <>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                          <Viewer
                            fileUrl={document}
                            withCredentials={true}
                            plugins={[
                              pageNavigationPluginInstance,
                              disableScrollPluginInstance
                            ]}
                            defaultScale={SpecialZoomLevel.PageFit}
                          />
                        </Worker>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      backgroundColor: "transparent",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "4px",
                    }}
                  >
                    <div>
                      <GoToPreviousPage>
                        {(props: RenderGoToPageProps) => (
                          <div
                            style={{
                              color: props.isDisabled ? "#96ccff" : "#357edd",
                              border: "none",
                              borderRadius: "4px",
                              // color: "#ffffff",
                              cursor: props.isDisabled ? "not-allowed" : "pointer",
                              padding: "8px",
                            }}
                            disabled={props.isDisabled}
                            onClick={props.onClick}
                          >
                            <span className="p-auto">
                              <FaArrowLeft /> Halaman Sebelumnya
                            </span>
                          </div>
                        )}
                      </GoToPreviousPage>
                    </div>
                    <CurrentPageLabel>
                      {(props: RenderCurrentPageLabelProps) => (
                        <span>{`${props.currentPage + 1} of ${props.numberOfPages
                          }`}</span>
                      )}
                    </CurrentPageLabel>
                    <div>
                      <GoToNextPage>
                        {(props: RenderGoToPageProps) => (
                          <div
                            style={{
                              color: props.isDisabled ? "#DADEDF" : "#357edd",
                              border: "none",
                              borderRadius: "4px",
                              cursor: props.isDisabled ? "not-allowed" : "pointer",
                              padding: "8px",
                            }}
                            className="ms-auto"
                            disabled={props.isDisabled}
                            onClick={props.onClick}
                          >
                            <span>
                              Halaman Selanjutnya <FaArrowRight />
                            </span>
                          </div>
                        )}
                      </GoToNextPage>
                    </div>
                  </div>
                </div>
                {details ? (
                  <div
                    style={{ height: "103px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {wait ? (
                      <>
                        {isExpired ? (
                          <>
                            <WarningTwoTone twoToneColor="red" style={{ fontSize: "22px" }} />
                            <span>&nbsp;Dokumen sudah kadaluarsa</span>
                          </>
                        ) : (
                          <span>
                            <InfoCircleTwoTone style={{ fontSize: "22px" }} />
                            <span className="ms-2">
                              Menunggu Penandatangan pertama selesai tandatangan
                            </span>
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {donesign ? (
                          <>
                            <img src={Check} alt="ini check" />
                            <span className="ms-2">
                              Dokumen telah Anda beri tandatangan
                            </span>
                          </>
                        ) : (
                          <>
                            {isExpired ? (
                              <>
                                <WarningTwoTone twoToneColor="red" style={{ fontSize: "22px" }} />
                                <span>&nbsp;Dokumen sudah kadaluarsa</span>
                              </>
                            ) : (
                              <Button
                                style={{ border: "0px", background: "#293DB6" }}
                                onClick={() => Navigate("/sign")}
                              >
                                Ke Halaman tanda tangani
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Detail;
