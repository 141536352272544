import WebViewer from "@pdftron/webviewer";
import React, { useEffect } from "react";

const Test = () => {
  const viewer = React.useRef(null);
  useEffect(() => {
    WebViewer(
      {
        path: "/webviewer",
        initialDoc: "/pppp.pdf",
      },
      viewer.current
    ).then(instance => {
      const { documentViewer, annotationManager } = instance.Core;
      documentViewer.addEventListener("documentLoaded", () => {
        const doc = documentViewer.getDocument();

        annotationManager.addEventListener(
          "annotationChanged",
          (annotations, action) => {
            if (["add", "modify", "delete"]) {
              //   console.log("action: ", action);
              //   const x = 0;
              //   const y = 0;

              //   const pageNumber = 1;

              //   const pdfCoords = doc.getPDFCoordinates(pageNumber, x, y);

              //   const viewerCoords = doc.getViewerCoordinates(
              //     pageNumber,
              //     pdfCoords.x,
              //     pdfCoords.y
              //   );
              //   console.log("viewerCoords", viewerCoords);
              //   console.log("pdfCoords", pdfCoords);
              annotationManager.getAnnotationsList().map(ann => {
                if (ann.Subject === "Image") {
                  console.log("image");
                  console.log("x:", ann.getX());
                  console.log("y:", ann.getY());
                }
              });
            }
          }
        );
      });
      // you can now call WebViewer APIs here...
    });
  }, []);
  return (
    <div className="MyComponent">
      <div className="header">
        <p>Testtt</p>
      </div>
      <div className="webviewer" ref={viewer} style={{ height: "100vh" }}></div>
    </div>
  );
};

export default Test;
