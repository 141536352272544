/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
} from "react-bootstrap";
import Navbar from "../../components/layout/navbar";
import VideoIcon from "../../img/video-icon.svg";
import VideoThumbnail from "react-video-thumbnail";
import CloseIcon from "../../img/CloseIcon.svg";
import { Imagepreview } from "./style";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { BiErrorCircle } from "react-icons/bi";
import axios from "axios";
import Webcam from "react-webcam";
import EkycIcon from "../../img/EkycIcon.jpg";
import { useForm } from "../../hooks";
import { useNavigate } from "react-router-dom";

const { Dragger } = Upload;

const dataekyc = {
  email: "",
  base64video: "",
};

const Ekyc = ({ renew = false }) => {
  const [form, setForm] = useForm(dataekyc);
  const [video, setVideo] = useState("");
  const [ekyc, setEkyc] = useState(false);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [infoErr, setErr] = useState(false);
  const { base64video } = form;
  const email = localStorage.getItem("email");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [textError, setTextError] = useState("");
  const webCamRef = useRef(null);
  const mediaRecorder = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordChunks, setRecordChunks] = useState([]);
  const [showRecord, setShowRecord] = useState(true);
  const [activeCam, setActiveCam] = useState(false);
  const [vid, setVid] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [time, setTime] = useState(8);
  const [url, setUrl] = useState("video/verify");

  useEffect(() => {
    setForm("email", email);
    if (renew) {
      setUrl("video/renew");
    } else {
      setUrl("video/verify");
    }
  }, []);

  const sendEkyc = async () => {
    try {
      setConfirmLoading(true);
      const response = await axios.post(url, form);
      if (response.status === 200) {
        setConfirmLoading(false);
        setSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (err) {
      console.log(err.response);
      setConfirmLoading(false);
      if (err.response.data.success === false) {
        // setError(true);
        setErr(true);
        setTextError(err.response.data.data.backEndResponse.resultDesc);
      } else if (
        err.response.data.data.backEndResponse.data.errorMessage ===
        "Face Does Not Match"
      ) {
        setErr(true);
        setTextError(err.response.data.data.backEndResponse.resultDesc);
        setEkyc(false);
      }
      if (err.response.data.data.backEndResponse) {
        if (
          err.response.data.data.backEndResponse.resultCode === "1040" ||
          err.response.data.data.backEndResponse.resultDoc === "1013"
        ) {
          navigate("/waiting");
        } else {
          setErr(true);
          setTextError(err.response.data.data.backEndResponse.resultDesc);
          setEkyc(false);
        }
      }
    }
  };
  const handleSetCapture = useCallback(() => {
    setCapturing(true);
    setRecordChunks([]);
    mediaRecorder.current = new MediaRecorder(webCamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorder.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorder.current.start();
    setTimeout(() => {
      handleStop();
    }, 8000);
  }, [webCamRef, setCapturing, mediaRecorder]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordChunks(prev => prev.concat(data));
      }
    },
    [setRecordChunks]
  );

  const handleStop = useCallback(() => {
    mediaRecorder.current.stop();
    setCapturing(false);
    setShowRecord(false);
    handleDownload();
  }, [mediaRecorder, webCamRef, setCapturing]);

  const handleDownload = useCallback(() => {
    if (recordChunks.length) {
      const blob = new Blob(recordChunks, { type: "video/webm" });
      console.log(blob);
      blobToBase64(blob);
    }
  }, [recordChunks]);

  const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
        const split = reader.result.split(",");
        setVid(reader.result);
        setForm("base64video", split[1]);
        localStorage.setItem("video", split[0]);
      };
    });
  };

  useEffect(() => {
    handleDownload();
  }, [recordChunks.length]);

  useEffect(() => {
    if (capturing) {
      const timer = time > 0 && setInterval(() => setTime(time - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [time, capturing]);

  return (
    <>
      <style type="text/css">
        {`#videoElement {
                            transform: rotateY(180deg);
                            -webkit-transform:rotateY(180deg);
                            -moz-transform:rotateY(180deg);
                }`}
      </style>
      <Container fluid>
        <Row className="justify-content-center" style={{ height: "100vh" }}>
          <Col md={6}>
            <Navbar />

            <Card
              style={{
                marginBottom: "30px",
                border: "1px solid #F8F9FA",
                marginTop: "30px",
              }}
            >
              <Card.Body>
                {infoErr ? (
                  <Alert
                    variant="danger"
                    onClick={() => setErr(false)}
                    dismissible
                  >
                    {textError}
                  </Alert>
                ) : (
                  <>
                    {success ? (
                      <Alert variant="success">
                        Verifikasi video berhasil!
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <h5
                  style={{
                    fontWeight: "600",
                    marginTop: "20px",
                    marginBottom: "28px",
                  }}
                >
                  Video Verifikasi
                </h5>
                <Container fluid className="d-flex justify-content-center">
                  {activeCam ? (
                    <Row className="justify-content-center">
                      <Col md={10} sm={12}>
                        {showRecord ? (
                          <>
                            <Alert className="mt-1">
                              Ucapkan kalimat, “
                              <b>
                                Saya {localStorage.getItem("name")} setuju untuk
                                membuat akun."
                              </b>
                              , Silakan menoleh ke kiri dan ke kanan secara
                              perlahan
                            </Alert>
                            <Webcam
                              mirrored={true}
                              style={{
                                maxWidth: "100%",
                                borderRadius: "10px",
                                maxHeight: "500px",
                              }}
                              ref={webCamRef}
                            />
                          </>
                        ) : (
                          <video
                            style={{
                              maxWidth: "100%",
                              borderRadius: "10px",
                              maxHeight: "500px",
                            }}
                            id="videoElement"
                            src={vid}
                            autoPlay
                          ></video>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <Row className="justify-content-center">
                      <Col md={8} sm={10}>
                        <Card style={{ borderRadius: "10px", padding: "10px" }}>
                          <div>
                            <img
                              src={EkycIcon}
                              className="d-block m-auto"
                              style={{ maxWidth: "50%" }}
                              alt="icon"
                            />
                            <p className="text-center">
                              Kami akan merekam wajah anda selama 8 detik untuk
                              melakukan verifikasi terhadap referensi biometrik
                              untuk kepentingan aktivasi tanda tangan
                              elektronik. Mohon untuk tidak menggunakan
                              aksesoris pada wajah anda saat melakukan perekaman
                              (seperti: kacamata, masker, dll). Pada saat
                              melakukan perekaman, silahkan mengikuti instruksi
                              yang muncul dilayar kamera. Klik aktifkan kamera
                              untuk mulai merekam
                            </p>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Container>
                <div className="d-flex justify-content-center mt-2">
                  {activeCam ? (
                    <>
                      {capturing ? (
                        <Button
                          style={{ background: "#084298", border: "0px" }}
                          onClick={handleSetCapture}
                          disabled
                        >
                          sedang merekam {time}
                        </Button>
                      ) : (
                        <>
                          {recordChunks.length ? (
                            <>
                              <div>
                                <div className="d-flex justify-content-center">
                                  <Button
                                    variant="outline-primary"
                                    onClick={() => {
                                      setRecordChunks([]);
                                      setActiveCam(false);
                                      setShowRecord(true);
                                      setVid("");
                                    }}
                                  >
                                    Rekam Ulang
                                  </Button>
                                </div>
                                <Button
                                  className="mt-1"
                                  style={{
                                    background: "#084298",
                                    border: "0px",
                                  }}
                                  onClick={() => sendEkyc()}
                                  disabled={confirmLoading ? "disable" : ""}
                                >
                                  {confirmLoading ? (
                                    <>
                                      <div
                                        className="spinner-border text-white"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        role="status"
                                      ></div>{" "}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  Lanjutkan
                                </Button>
                              </div>
                            </>
                          ) : (
                            <Button
                              style={{ background: "#084298", border: "0px" }}
                              onClick={handleSetCapture}
                            >
                              Mulai rekam
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        style={{ background: "#084298", border: "0px" }}
                        onClick={() => setActiveCam(true)}
                      >
                        Aktifkan kamera
                      </Button>
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Ekyc;
