import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Upload } from "antd";
import KTP from "../../img/ktp.svg";
import Webcam from "react-webcam";
import { isMobile } from "react-device-detect";

const { Dragger } = Upload;

const Ringkasan = ({ formData, setForm, navigation, token }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(localStorage.getItem("video"));
  const [video, setVideo] = useState(type + "," + formData.base64video);
  const [modalId, setModalId] = useState(false);
  const [provs, setProv] = useState([{}]);
  const [Kabupaten, setKab] = useState([{}]);
  const [modalAlamat, setModalAlamat] = useState(false);
  const [modalProfile, setModalProfile] = useState(false);
  const [modalInstansi, setModalInstansi] = useState(false);
  const [checkEkyc, setEkyc] = useState(true);
  const [error, setError] = useState(false);
  const [ktp, setKtp] = useState("");
  const [textError, setTextError] = useState("");
  const [isCheck, setCheck] = useState(false);
  const webCamRef = useRef(null);
  const mediaRecorder = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordChunks, setRecordChunks] = useState([]);
  const [time, setTime] = useState(8)
  const navigate = useNavigate()
  const [nikVal, setNikVal] = useState('')
  const [emailVal, setValEmail] = useState('')
  const [errorModal, setErrorModal] = useState('')
  const [date, setDate] = useState('')
  const [searchParams] = useSearchParams();
  const [existing] = useState(searchParams.get('type'))
  // console.log(formData);

  // handle ekyc
  const handleSetCapture = useCallback(() => {
    setCapturing(true);
    setRecordChunks([])
    mediaRecorder.current = new MediaRecorder(webCamRef.current.stream, {
      mimeType: 'video/webm'
    });
    mediaRecorder.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorder.current.start()
    setTimeout(() => {
      handleStop()
    }, 8000);
  }, [webCamRef, setCapturing, mediaRecorder]);

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordChunks((prev) => prev.concat(data));
    }
  }, [setRecordChunks]);

  const handleStop = useCallback(() => {
    mediaRecorder.current.stop();
    setCapturing(false);
    handleDownload()
  }, [mediaRecorder, webCamRef, setCapturing]);

  const handleDownload = useCallback(() => {
    if (recordChunks.length) {
      const blob = new Blob(recordChunks, { type: 'video/webm' });
      console.log(blob)
      blobToBase64(blob)
    }
  }, [recordChunks])

  const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
        const split = reader.result.split(',')
        // setVid(reader.result)
        setForm('base64video', split[1])
        localStorage.setItem("video", split[0]);
      };
    });
  };

  useEffect(() => {
    handleDownload()
  }, [recordChunks.length])

  useEffect(() => {
    if (capturing) {
      const timer =
        time > 0 && setInterval(() => setTime(time - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [time, capturing])
  // end handle ekyc

  const provinces = async () => {
    try {
      const response = await axios.get("area");
      const resp = response.data.data.nama_kota;
      setProv(resp);
    } catch (err) {
      console.log(err.response);
    }
  };

  const handleKtp = (e) => {
    if (e.file.originFileObj && e.fileList[0]) {
      let reader = new FileReader();
      reader.onload = (event) => {
        const replace = event.target.result.split(",");
        setForm("ktpphoto", replace[1]);
      };
      reader.readAsDataURL(e.file.originFileObj);
    }
  };

  useEffect(() => {
    provinces();
  }, []);

  const getKabupaten = (val) => {
    const getKota = async () => {
      try {
        const response = await axios.get(`area?nameKabupaten=${val}`);
        // console.log(response.data.data.nama_kota);
        const tempKab = response.data.data.nama_kota;
        setKab(tempKab);
      } catch (error) {
        console.log(error.response);
      }
    };
    getKota();
  };

  // handleSubmit
  const handleSubmit = async () => {
    // e.preventDefault();
    try {
      setConfirmLoading(true);
      setError(false);
      const response = await axios.post(existing === 'existing' ? "retry-register" : "register", formData, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      // handleLanjut();
      console.log(response);
      if (response.data.success !== true) {
        if (response.data.data.backEndResponse) {
          setConfirmLoading(false);
          setError(true);
          setTextError(response.data.data.backEndResponse.resultDesc);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      } else {
        sendEkyc();
      }
      // sendEkyc();
      // navigation.next();
    } catch (err) {
      console.log(err.response);
      setConfirmLoading(false);
      if (err.response.data.data.backEndResponse) {
        setError(true);
        setTextError(err.response.data.data.backEndResponse.resultDesc);
      } else {
        setError(true)
        setTextError(err.response.data.message)
      }
    }
  };
  // end handleSubmit

  const handleCheck = (e) => {
    setCheck(e.target.checked)
  }

  const handleInput = (e) => {
    setForm(e.target.name, e.target.value);
  };

  // send ekyc
  const sendEkyc = async () => {
    try {
      setConfirmLoading(true);
      const response = await axios.post("video/verify", formData);
      console.log(response);
      // checkSertif(formData);
      const resp = response.data.data.backEndResponse;
      if (response.data.data.backEndResponse) {
        if (resp.resultCode === '0') {
          setTimeout(() => {
            setConfirmLoading(false);
            navigation.next()
          }, 1000);
        }
      }
    } catch (err) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setConfirmLoading(false);
      if (err.response.data.success === false) {
        setError(true);
        setTextError(err.response.data.data.backEndResponse.resultDesc);
        setEkyc(false)
      } else if (
        err.response.data.data.backEndResponse.data.errorMessage ===
        "Face Does Not Match"
      ) {
        setError(true);
        setTextError(err.response.data.data.backEndResponse.resultDesc);
        setEkyc(false);
      }
      if (err.response.data.data.backEndResponse) {
        if (err.response.data.data.backEndResponse.resultCode === '1040' || err.response.data.data.backEndResponse.resultDoc === '1013') {
          navigate('/waiting')
        } else {
          setError(true);
          setTextError(err.response.data.data.backEndResponse.resultDesc);
          setEkyc(false);
        }
        setError(true);
        setTextError(err.response.data.data.backEndResponse.data.errorMessage)
        setEkyc(false)
      }
    }
  };

  useEffect(() => {
    setForm("step", 3);
  }, []);

  // check email and nik 
  const checkNikandEmail = async (params, type) => {
    setErrorModal('')
    try {
      const response = await axios.post(`cek/personalreg?type=${type}`, params)
      setNikVal('')
      setValEmail('')
      console.log(response)
      setModalId(false)
    } catch (err) {
      console.log(err.response)

      if (type === 'nik')
        setNikVal(err.response.data.message)
      else
        setValEmail(err.response.data.message)
    }
  }

  // check validation of age
  const checkUmur = (e) => {
    const dob = new Date(e.target.value);
    const today = new Date();
    const age = Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));
    if (age <= 17) {
      setDate('umur minimal 17 tahun')
      setForm('dateofbirth', '')
    } else {
      setDate('')
      console.log('tes')
    }
  }

  // cek validasi modal data diri
  const dataDiriModal = () => {
    if (
      formData.name !== '' && formData.placeofbirth !== '' && formData.dateofbirth !== '' && formData.gender !== '' && formData.ktp !== '' && formData.orgunit !== ''
    ) {
      if (nikVal !== '')
        setErrorModal(nikVal)
      else
        checkNikandEmail({ nik: formData.ktp }, 'nik')
    } else {
      setErrorModal('Dimohon untuk mengisikan semua data')
    }
  }

  // cek validasi modal profile
  const checkModalProfile = () => {
    if (FormData.phone !== '' && formData.email !== '') {
      if (emailVal !== '') {
        setErrorModal(emailVal)
      } else {
        setModalProfile(false)
      }
    } else {
      setErrorModal('Dimohon untuk mengisikan semua data')
    }
  }
  return (
    <>
      <Card
        style={{
          marginBottom: "30px",
          border: "1px solid #F8F9FA",
          // padding: "10px",
        }}
      >
        <Card.Body>
          {error && <Alert variant="danger">{textError}</Alert>}
          <Container>
            <h5
              style={{
                fontWeight: "600",
                marginTop: "20px",
                marginBottom: "28px",
              }}
            >
              Ringkasan
            </h5>
            <Alert variant="warning">
              <Alert.Heading>Silakan periksa kembali.</Alert.Heading>
              <p>
                Pastikan Data Diri, Foto KTP, NPWP, dan Video Verifikasi Anda
                sudah benar dan terlihat jelas, agar akun dapat terverifikasi
                nantinya.
              </p>
              <hr />
              <p className="mb-0">
                Jika ingin mengubah data, tekan pilihan “Ubah”
              </p>
            </Alert>
            <hr className="s" />
            <div>
              {/* data Diri */}
              <div
                className="position-relative"
                style={{
                  fontSize: "20px",
                }}
              >
                <span
                  style={{ fontWeight: "600" }}
                  className="position-absolute start-0"
                >
                  Identitas Diri
                </span>
                <span
                  style={{
                    fontWeight: "400",
                    color: "#293DB6",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => setModalId(true)}
                  className="position-absolute end-0"
                >
                  Ubah
                </span>
              </div>
              <div className="container">
                <Row
                  style={{
                    fontSize: "14px",
                    // display: "relative",
                    paddingTop: "50px",
                  }}
                >
                  <Col>
                    <div className="mb-3">
                      <span style={{ fontWeight: "600" }}>Nama</span>
                      <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                        {formData.name}
                      </p>
                    </div>
                    <div className="mb-3">
                      <span style={{ fontWeight: "600" }}>Jenis Kelamin</span>
                      {formData.gender === "M" ? (
                        <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                          Laki Laki
                        </p>
                      ) : (
                        <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                          Perempuan
                        </p>
                      )}
                    </div>
                    <div className="mb-3">
                      <span style={{ fontWeight: "600" }}>NIK</span>
                      <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                        {formData.ktp}
                      </p>
                    </div>
                    {/* <div className="mb-3">
                      <span style={{ fontWeight: "600" }}>Nama Instansi</span>
                      <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                        {formData.orgunit}
                      </p>
                    </div> */}
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <span style={{ fontWeight: "600" }}>Tempat Lahir</span>
                      <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                        {formData.placeofbirth}
                      </p>
                    </div>
                    <div className="mb-3">
                      <span style={{ fontWeight: "600" }}>Tanggal Lahir</span>
                      <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                        {formData.dateofbirth}
                      </p>
                    </div>
                    <div className="mb-3">
                      <span style={{ fontWeight: "600" }}>NPWP</span>
                      <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                        {formData.npwp === "" ? (
                          <span>-</span>
                        ) : (
                          <span>{formData.npwp}</span>
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <hr className="s" />
              {/* data Diri */}
              <div
                className="position-relative"
                style={{
                  fontSize: "20px",
                }}
              >
                <span
                  style={{ fontWeight: "600" }}
                  className="position-absolute start-0"
                >
                  Data Instansi
                </span>
                <span
                  style={{
                    fontWeight: "400",
                    color: "#293DB6",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => setModalInstansi(true)}
                  className="position-absolute end-0"
                >
                  Ubah
                </span>
              </div>
              <div className="container">
                <Row
                  style={{
                    fontSize: "14px",
                    // display: "relative",
                    paddingTop: "50px",
                  }}
                  xs={1}
                  md={2}
                  className='g-4'
                >
                  <Col>
                    <div className="mb-3">
                      <span style={{ fontWeight: "600" }}>Nama Instansi (Perguruan Tinggi)</span>
                      <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                        {formData.orgunit}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <span style={{ fontWeight: "600" }}>Unit Kerja</span>
                      <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                        {formData.workunit}
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <span style={{ fontWeight: "600" }}>Jabatan</span>
                      <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                        {formData.position}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <hr className="s" />
              {/* alamat */}
              <>
                <div
                  className="position-relative"
                  style={{ fontSize: "20px", marginTop: "10px" }}
                >
                  <span
                    style={{ fontWeight: "600" }}
                    className="position-absolute start-0"
                  >
                    Alamat
                  </span>
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#293DB6",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => setModalAlamat(true)}
                    className="position-absolute end-0"
                  >
                    Ubah
                  </span>
                </div>
                <div className="container">
                  <Row style={{ fontSize: "14px", paddingTop: "50px" }}>
                    <Col>
                      <div className="mb-3">
                        <span style={{ fontWeight: "600" }}>Provinsi</span>
                        <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                          {formData.province}
                        </p>
                      </div>
                      <div className="mb-3">
                        <span style={{ fontWeight: "600" }}>Kota</span>
                        <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                          {formData.city}
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <span style={{ fontWeight: "600" }}>Alamat</span>
                        <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                          {formData.address}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
              <hr className="s" />
              {/* nomor identitas */}

              {/* profile */}
              <>
                <div
                  className="position-relative"
                  style={{ fontSize: "20px", marginTop: "10px" }}
                >
                  <span
                    style={{ fontWeight: "600" }}
                    className="position-absolute start-0"
                  >
                    Data Akun
                  </span>
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#293DB6",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => setModalProfile(true)}
                    className="position-absolute end-0"
                  >
                    Ubah
                  </span>
                </div>
                <div className="container">
                  <Row style={{ fontSize: "14px", paddingTop: "50px" }}>
                    <Col>
                      <div className="mb-3">
                        <span style={{ fontWeight: "600" }}>Email</span>
                        <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                          {formData.email}
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <span style={{ fontWeight: "600" }}>Phone</span>
                        <p style={{ fontWeight: "600", color: "#a9a9a9" }}>
                          {formData.phone}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
              <hr className="s" />
              <>
                <div className="position-relative" style={{ fontSize: "20px" }}>
                  <span
                    style={{ fontWeight: "600" }}
                    className="position-absolute top-0 start-0"
                  >
                    Foto KTP
                  </span>
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#293DB6",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    className="position-absolute top-0 end-0"
                    onClick={() => setForm("ktpphoto", "")}
                  >
                    Ubah
                  </span>
                </div>
                <div
                  className="container"
                  style={{
                    placeItems: "center",
                    display: "grid",
                    marginTop: "45px",
                  }}
                >
                  {formData.ktpphoto !== "" ? (
                    <div
                      style={{
                        border: "1px solid #DEE2E6",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "8px",
                        padding: "32px 0",
                      }}
                    >
                      <img
                        src={"data:image/png;base64," + formData.ktpphoto}
                        alt="ktp"
                        style={{
                          width: "200px",
                          // height: "168px",
                          // borderRadius: "10px",
                        }}
                      />
                    </div>
                  ) : (
                    <Dragger
                      accept="image/*"
                      name="ktpphoto"
                      multiple={false}
                      onChange={handleKtp}
                      style={{ borderRadius: "8px" }}
                    >
                      <p className="ant-upload-drag-icon">
                        <img
                          src={KTP}
                          alt="imagepreview"
                          draggable={"false"}
                          style={{
                            width: "200px",
                          }}
                        />
                      </p>
                      <p
                        className="ant-upload-text"
                        style={{ color: "#6C757D" }}
                      >
                        Pilih atau geser file ke area ini untuk mengupload.
                        Ukuran maksimum 2 MB.
                      </p>
                      {/* <p></p> */}
                    </Dragger>
                  )}
                </div>
              </>
              {/* foto NPWP */}
              {formData.npwpphoto !== "" ? (
                <>
                  <div
                    className="position-relative"
                    style={{ fontSize: "20px", marginTop: "10px" }}
                  >
                    <span
                      style={{ fontWeight: "600" }}
                      className="position-absolute start-0"
                    >
                      Foto NPWP
                    </span>
                    <span
                      style={{
                        fontWeight: "600",
                        color: "#293DB6",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      className="position-absolute end-0"
                    >
                      Ubah
                    </span>
                  </div>
                  <div
                    className="container"
                    style={{
                      placeItems: "center",
                      display: "grid",
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #DEE2E6",
                        marginTop: "45px",
                        // width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "8px",
                        // padding: "32px 0",
                      }}
                    >
                      <img
                        src={"data:image/png;base64," + formData.npwpphoto}
                        alt="NPWP"
                        style={{
                          width: "200px",
                          // borderRadius: "10px",
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {/* E-kyc */}
              <>
                <div
                  className="position-relative"
                  style={{ fontSize: "20px", marginTop: "10px" }}
                >
                  <span
                    style={{ fontWeight: "600" }}
                    className="position-absolute start-0"
                  >
                    Video Verifikasi
                  </span>
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#293DB6",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    className="position-absolute end-0"
                    onClick={() => { setForm("base64video", ""); setRecordChunks([]); setTime(8) }}
                  >
                    Ubah
                  </span>
                </div>
                <div
                  className="container"
                  style={formData.base64video === '' ? ({
                    border: '1px dashed #DEE2E6',
                    borderRadius: '8px',
                    placeItems: "center",
                    marginTop: "45px",
                    display: "grid",
                    padding: '10px',
                  }) : ({
                    placeItems: "center",
                    marginTop: "45px",
                    display: "grid",
                    padding: '10px',

                  })}
                >
                  {formData.base64video === "" ? (
                    <>
                      <Alert>Ucapkan kalimat, “
                        <b>Saya {formData.name} setuju untuk membuat akun."</b>, Silakan menoleh ke kiri dan ke kanan secara perlahan</Alert>
                      <Webcam mirrored={true} style={{ maxWidth: '100%', borderRadius: '10px', maxHeight: '400px' }} ref={webCamRef} />
                      {capturing ? (

                        <Button disabled className='mt-2' style={{ background: '#084298', border: '0px' }}>Sedang merekam {time}</Button>
                      ) : (
                        <Button onClick={handleSetCapture} className='mt-2' style={{ background: '#084298', border: '0px' }}>Mulai rekam</Button>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        border: "1px solid #DEE2E6",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "8px",
                        padding: "32px 0",
                      }}
                    >
                      <video style={{ width: "200px" }}>
                        <source src={'data:video/webm;base64,' + formData.base64video} />
                      </video>
                    </div>
                  )}
                </div>
              </>
            </div>
            <div style={{ maxWidth: '100%', marginTop: '45px', display: 'flex', justifyContent: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center', width: '100%', border: '1px solid #293DB6' }}>
                <Form.Group className='text-center'>
                  <Form.Check checked={isCheck} onChange={handleCheck} label={<span style={{ fontSize: '14px' }}>Saya telah membaca dan menyetujui <a target='_blank' href='https://ca.peruri.co.id/ca/legal/' rel="noreferrer">ketentuan
                    legal (CP, CPS, Subscriber Agreement, dan Privacy Policy)</a> dari Peruri CA</span>} />
                </Form.Group>
              </div>
            </div>
            {!isCheck ? (

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "39px",
                }}
              >
                <Button
                  style={{ background: "#084298", border: "0px" }} disabled
                >
                  Daftarkan Akun
                </Button>
              </div>
            ) : (

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "39px",
                }}
              >
                {!confirmLoading ? (
                  <>
                    {checkEkyc ? (
                      <Button
                        style={{ background: "#084298", border: "0px" }}
                        onClick={handleSubmit}
                      >
                        Daftarkan Akun
                      </Button>
                    ) : (
                      <Button
                        style={{ background: "#084298", border: "0px" }}
                        onClick={sendEkyc}
                      >
                        Daftarkan Akun
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    style={{ background: "#084298", border: "0px" }}
                    // onClick={showConfirm}
                    disabled
                  >
                    <div
                      className="spinner-border text-white"
                      style={{ width: "20px", height: "20px" }}
                      role="status"
                    ></div>{" "}
                    Daftarkan Akun
                  </Button>
                )}
              </div>
            )}
          </Container>
        </Card.Body>
      </Card>

      {/* Modal Identitas Diri */}
      <Modal show={modalId}>
        <Modal.Header style={{ border: "0px" }}>
          <span style={{ fontSize: "20px", fontWeight: "600" }}>
            Identitas Diri
          </span>
        </Modal.Header>
        <Container>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                Nama Lengkap (sesuai KTP) <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInput}
                placeholder="Nama Lengkap Anda"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Tempat Lahir <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="placeofbirth"
                value={formData.placeofbirth}
                onChange={handleInput}
                placeholder="Tempat Lahir Anda"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Tanggal Lahir <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Tanggal Lahir Anda"
                name="dateofbirth"
                value={formData.dateofbirth}
                onChange={handleInput}
                onBlur={(e) => checkUmur(e)}
              />
              <Form.Text className='text-danger'>{date}</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Jenis Kelamin <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="gender"
                value={formData.gender}
                onChange={handleInput}
              >
                <option>--pilih jenis kelamin--</option>
                <option value="M">Laki Laki</option>
                <option value="F">Perempuan</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                NIK <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="ktp"
                maxLength={16}
                value={formData.ktp}
                onChange={handleInput}
                onBlur={() => {
                  if (formData.ktp.length > 0) {
                    setNikVal('')
                    if (formData.ktp.length > 0 && formData.ktp.length < 16) {
                      setNikVal('Minimal 16 digit')
                    }
                  } else {
                    setNikVal('NIK tidak boleh kosong')
                  }
                }}
                placeholder="Nomor Induk Kependudukan"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <Form.Text className='text-danger'>{nikVal}</Form.Text>
            </Form.Group>
          </Form>
          <div>
            {errorModal !== '' && <Alert variant="danger">{errorModal}</Alert>}
          </div>
          <div style={{ display: "grid", placeItems: "right" }}>
            <Button
              className="ms-auto"
              style={{
                backgroundColor: "#084298",
                border: "0px",
                marginBottom: "20px",
                marginTop: "15px",
              }}
              onClick={dataDiriModal}
            >
              Simpan
            </Button>
          </div>
        </Container>
      </Modal>

      {/* modal data instansi */}
      <Modal show={modalInstansi}>
        <Modal.Header style={{ border: "0px" }}>
          <span style={{ fontSize: "20px", fontWeight: "600" }}>
            Identitas Diri
          </span>
        </Modal.Header>
        <Container>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                Nama Instansi <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="orgunit"
                value={formData.orgunit}
                onChange={handleInput}
                placeholder="Nama Instansi Anda"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Unit Kerja <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="workunit"
                value={formData.workunit}
                onChange={handleInput}
                placeholder="Masukkan Unit Kerja"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Jabatan <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="position"
                value={formData.position}
                onChange={handleInput}
                placeholder="Masukkan Jabatan Anda"
              />
            </Form.Group>
          </Form>
          <Button
            className="ms-auto"
            style={{
              backgroundColor: "#084298",
              border: "0px",
              marginBottom: "20px",
              marginTop: "15px",
            }}
            onClick={() => {
              (formData.workunit !== '' && formData.orgunit !== '' && formData.position !== '') ? setModalInstansi(false) : setModalInstansi(true)
            }}
          >
            Simpan
          </Button>
        </Container>
      </Modal>

      {/* Modal Alamat */}
      <Modal show={modalAlamat}>
        <Modal.Header style={{ border: "0px" }} closeButton>
          <Container>
            <span style={{ fontSize: "20px", fontWeight: "600" }}>Alamat</span>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>
                  Provinsi <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="province"
                  value={formData.province}
                  // onChange={handleInput}

                  onChange={(e) => {
                    handleInput(e);
                    getKabupaten(e.target.value);
                  }}
                >
                  <option>--pilih provinsi--</option>
                  {provs.map((prov, idx) => (
                    <option key={idx} value={prov.name}>
                      {prov.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Kota/Kabupaten <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  aria-label="Floating label select example"
                  name="city"
                  value={formData.city}
                  onChange={handleInput}
                >
                  <option>--pilih kota--</option>
                  {formData.province !== "" ? (
                    <>
                      {Kabupaten.map((items, idx) => (
                        <option value={items.name}>{items.name}</option>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Alamat <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="alamat"
                  name="address"
                  value={formData.address}
                  onChange={handleInput}
                  style={{ height: "100px" }}
                />
              </Form.Group>
            </Form>
            <div style={{ display: "grid", placeItems: "right" }}>
              <Button
                className="ms-auto"
                style={{
                  backgroundColor: "#084298",
                  border: "0px",
                  marginBottom: "20px",
                  marginTop: "15px",
                }}
                onClick={() => setModalAlamat(false)}
              >
                Simpan
              </Button>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
      {/* Modal Profile */}
      <Modal show={modalProfile} onHide={() => setModalProfile(false)}>
        <Modal.Header style={{ border: "0px" }} closeButton>
          <Container>
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Data Akun
            </span>
          </Container>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>
                  No. Hp <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInput}
                  placeholder="Masukkan Nomor HP"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={formData.email}
                  // onChange={handleInput}
                  // onBlur={(e) => {
                  //   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
                  //   if (!e.target.value) {
                  //     setValEmail("Email tidak boleh kosong")
                  //   } else if (!regex.test(e.target.value)) {
                  //     setValEmail("Email yang dimasukkan tidak valid");
                  //   } else {
                  //     checkEmail(e.target.value)
                  //   }
                  // }}
                  disabled
                  style={{ background: 'white' }}
                  placeholder="Masukkan Email"
                />
                {emailVal !== "" && (
                  <Form.Text className="text-danger">{emailVal}</Form.Text>
                )}
              </Form.Group>
            </Form>
            <div>
              {errorModal !== '' && <Alert variant="danger">{errorModal}</Alert>}
            </div>
            <div style={{ display: "grid", placeItems: "right" }}>
              <Button
                className="ms-auto"
                style={{
                  backgroundColor: "#084298",
                  border: "0px",
                  marginBottom: "20px",
                  marginTop: "15px",
                }}
                onClick={checkModalProfile}
              >
                Simpan
              </Button>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Ringkasan;
