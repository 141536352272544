import React from 'react'

const FormatDate = (param) => {
    const date = new Date(param);
    const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

    const tanggal = date.getDate();
    const xbulan = date.getMonth();
    const xtahun = date.getYear()

    const month = bulan[xbulan]
    const tahun = (xtahun < 1000) ? xtahun + 1900 : xtahun;

    return (tanggal + ' ' + month + ' ' + tahun)
}

export default FormatDate
