import React from 'react'
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap'
import logo from "../../img/navbar-brand.svg";

const Navbarseamless = () => {
    return (
        <>
            <Row className='justify-content-center bg-white'
                style={{ border: "1px solid #E9ECEF" }}
            >
                <Col md={8}>
                    <Navbar collapseOnSelect expand="sm">
                        <Container>
                            <Navbar.Brand>
                                <img src={logo} alt="logo" />
                            </Navbar.Brand>
                        </Container>
                    </Navbar>
                </Col>
            </Row>
        </>
    )
}

export default Navbarseamless