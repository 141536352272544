import React, { useEffect, useState } from "react";
import Navbar from "../../components/layout/navbar";
import { useForm } from "../../hooks";
import { useStep } from "react-hooks-helper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sign from "./sign";
import Success from "./success";
import NavbarUser from "../../components/layout/NavbarUser";
import { Col, Container, Row, Card } from "react-bootstrap";
import Navbarpenandatangan from "../../components/layout/navbarpenandatangan";
import jwt_decode from "jwt-decode";
import AuthService from "../../api/auth.service";

const dataEsign = {
  docfilename: "",
  docname: "",
  signlocation: "",
  docnumber: "",
  base64document: "",
  otp: "",
  reason: "",
};

const steps = [{ id: "sign" }, { id: "success" }];
const Index = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const payload = localStorage.getItem("payload");
  // const dokumen = localStorage.getItem("nama dokumen");
  const [form, setForm] = useForm(dataEsign);
  const [name, setName] = useState()
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  useEffect(() => {
    if (payload) {
      const decodeBase64 = atob(payload);
      const check = JSON.parse(decodeBase64);
      if (check.isVerified === "0") {
        navigate("/verification");
      }
    } else {
      navigate("/");
    }
  });
  useEffect(() => {
    document.title = "Sign";
  }, []);
  const currentUser = () => {
    const userMe = jwt_decode(AuthService.getCurrentUser().access_token)
    setName(userMe.name)
  }

  useEffect(() => {
    currentUser()
  }, [])
  return (
    <>
      <Container fluid>
        <Navbarpenandatangan name={name} />
        <Row className="justify-content-center" style={{ marginTop: "40px" }}>
          <Col md={8}>
            {(() => {
              // eslint-disable-next-line default-case
              switch (step.id) {
                case "sign":
                  return (
                    <Sign
                      form={form}
                      setForm={setForm}
                      navigation={navigation}
                    />
                  );
                case "success":
                  return <Success />;
              }
            })()}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
