import React, { useEffect } from "react";
import Navbar from "../layout/navbar";
import Peruri from "../../img/logo-peruri.png";
import "./success.css";
import { useNavigate } from "react-router-dom";
import { Alert, Card, Container } from "react-bootstrap";

const Success = ({ formData, setForm }) => {
  const navigate = useNavigate();
  const { step } = formData;
  useEffect(() => {
    setForm("step", 4);
  }, []);
  return (
    <>
      <Container fluid>
        <Card className="text-center" style={{ marginTop: "40px", border: '0px' }}>
          <Alert variant="white">
            <Alert.Heading>Akun anda sudah berhasil dibuat</Alert.Heading>
            <p>
              Silakan kembali ke halaman login dan masukkan email dan password sesuai dengan yang didaftarkan.
            </p>
            <hr />
            <p style={{ fontWeight: "600", color: "#293DB6" }}>
              Informasi Tentang Verifikasi
            </p>
          </Alert>
          {/* <p style={{ fontWeight: "600", fontSize: "24px" }}>
            </p>
            */}
        </Card>
      </Container>
    </>
  );
};

export default Success;
