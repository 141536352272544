import React from 'react'

const CountExpired = (expired_at) => {
    const now = new Date()
    const expired = new Date(expired_at)

    const day = 1000 * 3600 * 24

    const count = Math.round(Math.round(now.getTime() - expired.getTime()) / (day))

    if (count < 0) {
        return ('Kadaluarsa ' + Math.abs(count) + ' ' + 'hari lagi')
    } else if (count === 0) {
        return ('Kadaluarsa hari ini')
    } else {
        return ('Sudah Kadaluarsa')
    }
}

export default CountExpired