import { useEffect, useRef, useState } from "react";

export function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const useForm = (initialValue) => {
  const [values, setValues] = useState(initialValue);
  return [
    values,
    (formType, formValue) => {
      if (formType === "reset") {
        return setValues(initialValue);
      }
      if (formType === 'all') {
        return setValues(formValue)
      }
      return setValues({ ...values, [formType]: formValue });
    },
  ];
};
