import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavbarUser from "../../components/layout/NavbarUser";
import iconSuccess from "../../img/empty.svg";

const Success = ({ form, setForm }) => {
  const navigation = useNavigate();
  const [terkirim, setTerkirim] = useState(false);
  const { step } = form;

  useEffect(() => {
    if (localStorage.getItem("terkirim") === "1") {
      setTerkirim(true);
    }
    setForm("step", 4);
  }, []);

  const clearStorage = ['base64', 'terkirim', 'nama dokumen', 'iddokumen']
  return (
    <>
      <Container fluid>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "502" }}
        >
          <div className="text-center">
            <img src={iconSuccess} alt="success" />
            <p style={{ fontWeight: "600", fontSize: "24px", margin: "0px" }}>
              Dokumen berhasil {!terkirim ? "disimpan" : "terkirim"}
            </p>
            <p>
              Dokumen <b>{localStorage.getItem("nama dokumen")}</b> berhasil{" "}
              {terkirim
                ? "dikirim ke kontak tujuan."
                : "disimpan di belum terkirim."}
            </p>
            <Button
              variant="outline-primary"
              onClick={() => {
                clearStorage.forEach((i) => localStorage.removeItem(i))
                navigation("/document");
              }}
            >
              Lihat Semua Dokumen
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Success;
