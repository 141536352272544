import React from "react";
import { Result, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

const Notfound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container fluid>
        <Row>
          <Col
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={
                <Button onClick={() => navigate("/home")} type="primary">
                  Back Home
                </Button>
              }
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Notfound;
