import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  Viewer,
  Worker,
  SpecialZoomLevel,
  LayerRenderStatus,
  PluginOnCanvasLayerRender,
} from "@react-pdf-viewer/core";
import { useLocation } from "react-router-dom";
import Draggable from "react-draggable";
import QR from "../../img/scan-barcode.svg";
import LetakMeterai from "../../img/letakeMeterai.png";
import eMeterai from "../../img/eMeterai.png";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from "@react-pdf-viewer/page-navigation";
import disableScrollPlugin from "../../components/disableScrollPlugin";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./index.css";
import DocumentService from "../../api/document.service";
import UserService from "../../api/user.service";

function Letak({ form, setForm, navigation }) {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const disableScrollPluginInstance = disableScrollPlugin();

  const { GoToNextPage, GoToPreviousPage, CurrentPageInput } =
    pageNavigationPluginInstance;

  const { CurrentPageLabel } = pageNavigationPluginInstance;

  const [PDF, setPDF] = useState("");
  const { state } = useLocation();
  const [pages, setPage] = useState(0);
  const [signer, setSigner] = useState([{ idsignature: "meterai" }]);
  const [idDoc] = useState(state !== null && state.iddocument);
  const [token] = useState(localStorage.getItem("payload"));
  const [isAdmin, setIsAdmin] = useState(false);
  //   const [details, setDetails] = useState(false);
  const [signerEmail, setSignerEmail] = useState("");
  const [postX, setX] = useState(0);
  const [confirmLoading, setConfirmloading] = useState(false);
  const [postY, setY] = useState(0);
  const { x, y } = form;
  const [coordinates, setCoordinates] = useState([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const pdfRef = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("terkirim") === "0") {
      navigation.next();
    }
    setForm("step", 2);
    getRole();
  }, []);

  // get role
  const getRole = () => {
    UserService.getUserMe().then(response => {
      sessionStorage.setItem("email", response.data.data[0].email);
      if (response.data.data[0].role === "0") {
        setIsAdmin(true);
        // setDetails(true);
      } else {
        setSignerEmail(response.data.data[0].email);
        setIsAdmin(false);
        // setDetails(true);
      }
    });
  };

  // view document or get base64 from document
  const viewDoc = () => {
    DocumentService.getBase64Document(idDoc).then(response => {
      setPDF(
        "data:application/pdf;base64," + response.data.data.base64document
      );
    });
  };

  // get signer
  const getSigner = () => {
    DocumentService.getSigner(idDoc).then(response => {
      console.log(response);
      const signDoc = response.data.signer;
      const tempSign = [];
      for (var i = 0; i < signDoc.length; i++) {
        // setSigner([...signer, [signDoc[i]]]);
        tempSign.push(signDoc[i]);
        // console.log(i)
      }
      setSigner(old => [...old, ...tempSign]);
    });
  };
  useEffect(() => {
    viewDoc();
    getSigner();
  }, []);

  useEffect(() => {
    setForm("x", postX);
    setForm("y", postY);
  }, [postX, postY]);

  const dataSignLoc = {
    coordinates,
  };

  // set location of signature's from this document
  const signLoc = () => {
    setConfirmloading(true);
    DocumentService.setSignatureLocation(idDoc, dataSignLoc).then(
      response => {
        setTimeout(() => {
          setConfirmloading(false);
          navigation.next();
        }, 2000);
      },
      error => {
        setConfirmloading(false);
      }
    );
  };

  useEffect(() => {
    console.log(coordinates);
    // console.log(signer)
  }, [coordinates]);

  useEffect(() => {
    if (token) {
      // setForm("tempId", idDoc);
      viewDoc();
    }
  }, [token]);

  const handleCoordinates = (index, idsignature, x, y) => {
    const lly = 842 - (y + 70);
    const urx = idsignature === "meterai" ? x + 71 : x + 170;
    const ury = lly + 71;
    if (coordinates.length !== 0) {
      const dataCoordinate = [...coordinates];
      dataCoordinate[index] = {
        idsignature: idsignature,
        payload: { llx: x, lly: lly, urx: urx, ury: ury, page: pages },
      };
      // console.log(index)
      setCoordinates(dataCoordinate);
      // console.log(coordinates[index].payload.x)
    } else {
      setCoordinates([
        ...coordinates,
        {
          idsignature: idsignature,
          payload: { llx: x, lly: lly, urx: urx, ury: ury, page: pages },
        },
      ]);
    }
  };

  const customCanvasPlugin = (): Plugin => {
    const onCanvasLayerRender = (e: PluginOnCanvasLayerRender) => {
      if (e.status !== LayerRenderStatus.DidRender) {
        return;
      }
      const canvas = e.ele;
      const ctx = canvas.getContext("2d");
      ctx.canvas.height = 842;
      ctx.canvas.width = 595;
      canvas.style.display = "none";
      setHeight(ctx.canvas.height);
      setWidth(ctx.canvas.width);
    };

    return {
      onCanvasLayerRender,
    };
  };

  const customCanvasPluginInstance = customCanvasPlugin();

  useEffect(() => {
    document.body.className.replace("rpv-core__text-layer", "");
    // return () => { document.body.className = ''; }
  }, []);

  return (
    <>
      <style type="text/css">
        {`.rpv-core__textbox{
          width:50px !important
        }
        
        .rpv-core__text-layer-text{
          color:black !important;
          
        }
        .rpv-core__text-layer{
          opacity:1;
          line-heigh:30%;
        }`}
      </style>
      <span style={{ fontWeight: "600", fontSize: "20px" }}>
        Atur Letak Tandatangan
      </span>
      <p>Silakan pilih halaman dan atur letak tanda tangan</p>
      <div className="text-secondary">
        Catatan:
        <ul style={{ listStyleType: "circle" }}>
          <li>
            Geser box area tandantangn ke tempat tandatangan sesuai dengan
            Namanya
          </li>
          <li>Pastikan box tidak saling menumpuk satu sama lain</li>
          <li>
            Letakkan eMeterai seperti gambar berikut, klik{" "}
            <a href onClick={() => setShow(true)} className="text-primary">
              Disini
            </a>
            .
          </li>
        </ul>
      </div>
      <div
        style={{
          alignItems: "center",
          // backgroundColor: "#eeeeee",
          // borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          // display: "flex",
          // justifyContent: "center",
          padding: "4px",
        }}
        className="d-flex justify-content-between"
      >
        <div>
          <GoToPreviousPage>
            {(props: RenderGoToPageProps) => (
              <div
                style={{
                  color: props.isDisabled ? "#96ccff" : "#357edd",
                  border: "none",
                  borderRadius: "4px",
                  // color: "#ffffff",
                  cursor: props.isDisabled ? "not-allowed" : "pointer",
                  padding: "8px",
                }}
                disabled={props.isDisabled}
                onClick={props.onClick}
              >
                <span className="p-auto">
                  <FaArrowLeft /> Halaman Sebelumnya
                </span>
              </div>
            )}
          </GoToPreviousPage>
        </div>
        <div>
          <CurrentPageInput />
          <CurrentPageLabel>
            {(props: RenderCurrentPageLabelProps) => (
              <>
                {setPage(props.currentPage + 1)}
                <span>dari {props.numberOfPages}</span>
              </>
            )}
          </CurrentPageLabel>
        </div>
        <div>
          <GoToNextPage>
            {(props: RenderGoToPageProps) => (
              <div
                style={{
                  color: props.isDisabled ? "#96ccff" : "#357edd",
                  border: "none",
                  borderRadius: "4px",
                  // color: "#ffffff",
                  cursor: props.isDisabled ? "not-allowed" : "pointer",
                  padding: "8px",
                }}
                className="ms-auto"
                disabled={props.isDisabled}
                onClick={props.onClick}
              >
                <span>
                  Halaman Selanjutnya <FaArrowRight />
                </span>
              </div>
            )}
          </GoToNextPage>
        </div>
      </div>

      <div
        style={{
          height: `${height}px`,
          // height: '1335px',
          // borderRadius: "5px",
          width: `${width}px`,
          backgroundColor: "#eee",
          position: "relative",
          // display: "inline-block",
          margin: "auto",
        }}
      >
        {signer.map((item, idx) => (
          <Draggable
            key={idx}
            onStop={(e, d) =>
              handleCoordinates(idx, item.idsignature, d.x, d.y)
            }
            bounds="parent"
          >
            <div
              style={{
                zIndex: "9",
                width: item.idsignature !== "meterai" ? "200" : "70",
                height: "70px",
                border: "2px dashed #0D6EFD",
                marginLeft: "10px",
                marginRight: "10px",
                position: "absolute",
                borderRadius: "8px",
                cursor: "all-scroll",
                boxSizing: "border-box",
              }}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={item.idsignature === "meterai" ? eMeterai : QR}
                style={{ maxWidth: "100px", maxHeight: "60px" }}
                alt=""
              />
              {item.idsignature !== "meterai" && (
                <div className="ms-2">
                  <span
                    style={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    Area Tanda Tangan
                  </span>
                  <br />
                  <span style={{ fontSize: "10px" }}>
                    Geser bagian ini, dan letakkan dibagian <b>{item.name}</b>
                  </span>
                </div>
              )}
            </div>
          </Draggable>
        ))}
        {PDF !== "" ? (
          <>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
              <Viewer
                fileUrl={PDF}
                // withCredentials={true}
                plugins={[
                  pageNavigationPluginInstance,
                  customCanvasPluginInstance,
                  disableScrollPluginInstance,
                ]}
                defaultScale={SpecialZoomLevel.PageFit}
              />
            </Worker>
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{ height: "103px" }}
        className="d-flex align-items-center justify-content-end"
      >
        {!confirmLoading ? (
          <Button
            style={{ border: "0px", background: "#293DB6" }}
            onClick={signLoc}
          >
            Lanjutkan
          </Button>
        ) : (
          <Button style={{ border: "0px", background: "#293DB6" }} disabled>
            <div
              className="spinner-border text-secondary"
              style={{ width: "20px", height: "20px" }}
              role="status"
            ></div>
            {""}
            Lanjutkan
          </Button>
        )}
      </div>
      <Modal centered show={show} onHide={() => setShow(false)}>
        <img src={LetakMeterai} alt="letak meterai" />
      </Modal>
    </>
  );
}

export default Letak;
