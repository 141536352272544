import React, { useState } from "react";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import logo from "../../img/navbar-brand.svg";
import "./navbar.css";
import IconUser from "../../img/icon-user.svg";
import { Avatar, Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthService from "../../api/auth.service";

const Navbarpenandatangan = ({ name }) => {
  const location = useLocation();
  const navigate = useNavigate()
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const logout = () => {
    AuthService.logout().then((response) => {
      navigate('/')
    })
    // try {
    //   const response = await axios.post('logout', '', config)
    //   // console.log(response)
    //   localStorage.clear()
    //   navigate('/')
    // } catch (err) {
    //   console.log(err.response)
    //   refreshToken(logout)
    // }
  }
  const user = (
    <div>
      <p style={{ cursor: 'pointer' }} onClick={logout} className="text-black">
        LogOut
      </p>
    </div >
  );
  return (
    <>
      <Row
        className="justify-content-center bg-white"
        style={{ border: "1px solid #E9ECEF" }}
      >
        <Col md={8}>
          <Navbar collapseOnSelect expand="sm">
            <Container>
              <Navbar.Brand href="/home">
                <img src={logo} alt="logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="text-center"
              >
                <Nav
                  className="ms-auto"
                  style={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    color: "#293DB6 ",
                  }}
                >
                  <Nav.Link
                    as={Link}
                    to="/home"
                    style={
                      splitLocation[1] === "home"
                        ? { fontWeight: "600", color: "#293DB6 " }
                        : { fontWeight: "400", color: "#293DB6 " }
                    }
                  >
                    Beranda
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/document"
                    style={
                      splitLocation[1] === "document"
                        ? { fontWeight: "600", color: "#293DB6 " }
                        : { fontWeight: "400", color: "#293DB6 " }
                    }
                  >
                    Dokumen
                  </Nav.Link>
                </Nav>
                <Nav
                  style={{
                    marginLeft: "40px",
                  }}
                >
                  <Popover
                    content={user}
                    trigger="hover"
                    title={name}
                    placement="bottomRight"
                  >
                    <Avatar
                      style={{
                        backgroundColor: "#9EC5FE",
                        color: "#0A58CA",
                        width: "32px",
                        paddingTop: "6px",
                      }}
                      // className="p-1"
                      icon={<UserOutlined />}
                    />
                  </Popover>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Col>
      </Row>
    </>
  );
};

export default Navbarpenandatangan;
