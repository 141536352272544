import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Alert,
    Spinner,
} from "react-bootstrap";
import "antd/dist/antd.css";
import OTPInput from "otp-input-react";
import {
    Viewer, Worker, SpecialZoomLevel, LayerRenderStatus,
    PluginOnCanvasLayerRender,
} from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import disableScrollPlugin from "../../components/disableScrollPlugin";
import { RenderCurrentPageLabelProps } from "@react-pdf-viewer/page-navigation";
import axios from "axios";
import { Avatar, Tag, Tooltip } from "antd";
import { UserOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import Check from "../../img/check.svg";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { BsFillInfoSquareFill } from "react-icons/bs";
import FormatDate from "../../components/formatDate";
import CountExpired from '../../components/countExpired';
import { isDesktop } from 'react-device-detect';

const SignEmail = ({ form, setForm, token, id, navigation, email }) => {
    const [penandatangan, setPenandatangan] = useState([]);

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const disableScrollPluginInstance = disableScrollPlugin();

    const { GoToNextPage, GoToPreviousPage } =
        pageNavigationPluginInstance;

    const { CurrentPageLabel } = pageNavigationPluginInstance;

    const { otp, reason, signlocation } = form;
    const [timeOut, setTimeOut] = useState(60);
    const [resend, setResend] = useState(false);
    const [document, setDocument] = useState("");
    const [time, setTime] = useState("");
    const [check, setCheck] = useState(false);
    const [wrongOtp, setWrongOtp] = useState(false);
    const [wrongText, setWrongText] = useState("");
    const [temp, setTemp] = useState(0);
    const [disable, setDisabled] = useState(false);
    const [nameFile, setNameFile] = useState("");
    const [dateFile, setDateFile] = useState("");
    const [doneSign, setDoneSign] = useState(false);
    const [wait, setWait] = useState(false);
    const [tombol, setTombol] = useState(true);
    const [nomor, setNomor] = useState("");
    const [checkBox, setCheckBox] = useState(false);
    const [height, setHeight] = useState(0)
    // const [signer, setSigner] = useState([]);
    const [expired, setExpired] = useState('')
    const [yesNext, setYesNext] = useState(false)
    const [page, setPage] = useState(0)
    const [lastPage, setLastPage] = useState(1)

    // modal
    const [popUp, setPopUp] = useState(false);
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [lanjutkan, setLanjutkan] = useState(false)
    const [initialPage, setIntialPage] = useState(0)
    const [anotherSigner, setAnotherSigner] = useState(false)
    const [isExpired, setIsExpired] = useState(false)
    const [notExpired, setYesExpired] = useState(false)

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const handleReason = (e) => {
        setForm(e.target.name, e.target.value);
        // console.log(form);
    };
    const handleCheck = (e) => {
        setCheckBox(e.target.checked);
        if (e.target.checked === true) {
            setForm("reason", e.target.value);
        } else {
            setForm("reason", "");
        }
    };

    const data = {
        id: id,
        varlocation: form.signlocation,
        varreason: form.reason,
    };

    const getSigner = async () => {
        try {
            const response = await axios.get(`seamproc/view/${id}/signer`, config);
            console.log(response);
            const signDoc = response.data.signer;
            setPenandatangan(signDoc);
            for (var i = 0; i < signDoc.length; i++) {
                if (signDoc[i].email === email) {
                    if (i !== 0)
                        setAnotherSigner(true)
                    // setLanjutkan(true)
                    if (signDoc[i].issigned === '1') {
                        setDoneSign(true)
                        setAnotherSigner(true)
                    } else {
                        if (i !== 0) {
                            if (signDoc[i - 1].issigned === '0') {
                                setWait(true)
                            } else {
                                setDoneSign(false)
                            }
                        }
                    }
                }
            }
        } catch (err) {
            // console.log(err.response);
        }
    };

    // get information document
    const getInformation = async () => {
        try {
            const response = await axios.get(
                `seamproc/view/${id}/information`,
                config
            );
            if (response.data.data.expired_at !== null) {
                const tanggalExp = CountExpired(response.data.data.expired_at)
                if (tanggalExp === 'Sudah Kadaluarsa') {
                    setIsExpired(true)
                }
                setExpired(FormatDate(response.data.data.expired_at))
            } else {
                setYesExpired(true)
            }
            setDateFile(FormatDate(response.data.data.updated));
            setNameFile(response.data.data.title);
            localStorage.setItem("nama dokumen", response.data.data.title);
        } catch (err) {

        }
    };


    // set signature
    const setSignature = async () => {
        try {
            setWrongOtp(false)
            setConfirmLoading(true);
            const response = await axios.post(
                `seamproc/setsignature?id=${id}`,
                data,
                config
            );
            console.log(response)
            localStorage.setItem("orderid", response.data.data.orderid);
            const orderid = { orderId: localStorage.getItem("orderid") };
            OTP(orderid);
        } catch (err) {
            console.log(err.response)
            setWrongOtp(true)
            setConfirmLoading(false);
            setWrongText(err.response.statusText)
        }
    };


    // get otp
    const OTP = (param) => {
        const getOTP = async () => {
            try {
                const response = await axios.post("otp", param, config);
                console.log(response);
                localStorage.setItem(
                    "tokenSign",
                    response.data.data.backEndResponse.data.token
                );
                localStorage.setItem(
                    "nomor telephon",
                    response.data.data.backEndResponse.data.phone
                );
                setNomor(localStorage.getItem("nomor telephon"));
                setTimeout(() => {
                    setConfirmLoading(false);
                    setVisible(true);
                    setPopUp(false);
                }, 2000);
            } catch (err) {
                console.log(err.response);
                setConfirmLoading(false);
            }
        };
        getOTP();
    };

    useEffect(() => {
        if (visible && timeOut >= 0) {
            const intervalTimer = setInterval(() => {
                updateTimer();
            }, 1000);
            return () => clearTimeout(intervalTimer);
        }
    }, [timeOut, visible]);

    useEffect(() => {
        if (form.reason === "" || form.signlocation === "") {
            setTombol(true);
        } else {
            setTombol(false);
        }
    });

    // get base64 from document
    const getBaseDoc = async () => {
        try {
            const response = await axios.get(`seamproc/view?id=${id}`, config);
            setDocument(
                "data:application/pdf;base64," + response.data.data.base64document
            );
            setForm("base64document", response.data.data.base64document);
            // localStorage.setItem("base64", form.base64Document);
            setIntialPage(response.data.data.lastread - 1)
        } catch (error) {
            console.log(error.response);

        }
    };

    useState(() => {
        getBaseDoc()
        getSigner();
        getInformation();
    }, []);


    const updateTimer = () => {
        setTimeOut(timeOut - 1);
        const min = Math.floor(timeOut / 60);
        const sec = Math.floor(timeOut % 60);
        setTime(`${min < 10 ? "0" : ""}${min}:${sec < 10 ? "0" : ""}${sec}`);
        // const cTimer = time - 1;
        if (timeOut === 0) {
            setResend(true);
        }
    };

    // data for signing
    const dataSigning = {
        actiontype: "sirkulir",
        orderid: localStorage.getItem("orderid"),
        token: localStorage.getItem("tokenSign"),
        otpcode: form.otp,
    };
    // console.log(dataSigning);

    const dataCheck = {
        orderid: localStorage.getItem("orderid"),
    };

    // signing
    const setSign = async () => {
        try {
            setConfirmLoading(true);
            setWrongOtp(false);
            const response = await axios.post(
                `seamproc/signing/seamless?id=${id}`,
                dataSigning,
                config
            );
            console.log(response)

            if (response.data.success === true) {
                if (
                    response.data.success === true ||
                    response.data.message ===
                    "Berhasil melakukan tanda tangan dan mengirimkan notifikasi email kepada next-signer"
                ) {
                    setConfirmLoading(false);
                    navigation.next();
                } else if (
                    response.data.success === true ||
                    response.data.message === "berhasil update document (download)"
                ) {
                    setConfirmLoading(false);
                    navigation.next();
                } else if (response.data.data.backEndResponse.resultCode === "4026") {
                    setWrongOtp(true);
                    setWrongText("Kode OTP tidak sesuai. Silahkan ulangi.");
                    setConfirmLoading(false);
                    setTimeout(() => {
                        setWrongOtp(false);
                    }, 3000);
                }
            }
        } catch (err) {
            setConfirmLoading(false);
            if (err.response.data.data.backEndResponse) {
                if (err.response.data.data.backEndResponse.resultCode === "4026") {
                    setWrongOtp(true);
                    setWrongText("Kode OTP tidak sesuai. Silahkan ulangi.");
                    setTimeout(() => {
                        setWrongOtp(false);
                    }, 3000);
                    setConfirmLoading(false);
                }
            } else {
                if (
                    err.response.data.message === "user telah menanda tangani document"
                ) {
                    setConfirmLoading(false);
                    setWrongOtp(true);
                    setWrongText("Anda telah menandatangani dokumen ini");
                } else {
                    setConfirmLoading(false);
                    setWrongOtp(true);
                    setWrongText(err.response.statusText);
                }
            }
        }
    };

    useEffect(() => {
        if (check) {
            docCheck(dataCheck);
        }
    }, [check, temp]);

    const dataResend = {
        orderId: localStorage.getItem("orderid"),
    };


    // resend otp
    const resendOTP = async () => {
        setTimeOut(120);
        setResend(false);
        try {
            const response = await axios.post("seamproc/otp", dataResend, config);
            console.log(response);
            localStorage.setItem(
                "tokenSign",
                response.data.data.backEndResponse.data.token
            );
        } catch (err) {
            console.log(err.response);
        }
    };

    const getReason = () => {
        setPopUp(true);
    };

    const CloseReason = () => {
        setPopUp(false);
    };

    // check document status
    const docCheck = async (params) => {
        try {
            setTimeout(() => {
                setTemp(temp + 1);
            }, 5000);
            setCheck(true);
            setConfirmLoading(true);
            const response = await axios.post(`seamproc/cek/docstatus`, params, config);
            // console.log(response);
            if (
                response.data.data.backEndResponse.resultCode === "0" ||
                response.data.data.backEndResponse.resultDesc ===
                "Dokumen sudah ditandatangan"
            ) {
                setTimeout(() => {
                    setConfirmLoading(false);
                    navigation.next();
                }, 1000);
            } else {
                setCheck(true);
            }
        } catch (err) {
            setCheck(true);
        }
    };

    const otpInput = (e) => {
        setForm("otp", e);
    };

    useEffect(() => {
        if (form.otp.length === 6) {
            setSign();
        }
    }, [form.otp.length === 6]);


    // for canvas pdf
    const customCanvasPlugin = (): Plugin => {
        const onCanvasLayerRender = (e: PluginOnCanvasLayerRender) => {
            if (e.status !== LayerRenderStatus.DidRender) {
                return;
            }

            const canvas = e.ele;
            const ctx = canvas.getContext('2d')
            setHeight(ctx.canvas.height)


        };

        return {
            onCanvasLayerRender,
        };
    };

    const customCanvasPluginInstance = customCanvasPlugin();

    const handleNetPage = (e) => {
        setYesNext(e.target.checked);
    };

    const handleNext = (props) => {
        setYesNext(false)
        setIntialPage(page)
        handleChangePage(page, props)
    }

    const handleChangePage = async (page, props) => {

        try {
            const response = await axios.get(`seamproc/read?id=${id}&page=${page}`, config)
            props.onClick()
            window.scrollTo(0, 0)
        } catch (err) {
            console.log(err.response)
        }
    }

    useEffect(() => {
        if (page === lastPage)
            setLanjutkan(true)
        else
            if (anotherSigner)
                if (page === lastPage)
                    setLanjutkan(true)
                else
                    setLanjutkan(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    // next for another signer
    const changePageAnotherSigner = (props) => {
        props.onClick()
        window.scrollTo(0, 0)

    }
    return (
        <>
            <style type='text/css'>
                {`.ant-tooltip-inner{
                    border-radius:4px
                }`}
            </style>

            <p style={{ fontWeight: "600", fontSize: "20px" }}>Pratinjau</p>
            <Card style={{ borderRadius: '8px', border: '0px' }}>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <div>
                                <span>Nama Dokumen</span>
                                <p style={{ fontWeight: "600", fontSize: "16px" }}>
                                    {nameFile}
                                </p>
                            </div>
                        </Col>
                        <Col md={!doneSign || !notExpired ? 3 : 6}>
                            <div>
                                <span>Tanggal Kirim</span>
                                <p style={{ fontWeight: "600", fontSize: "16px" }}>
                                    {dateFile}
                                </p>
                            </div>
                        </Col>
                        {!doneSign &&
                            <>
                                {!notExpired &&
                                    <Col md={3}>
                                        <div>
                                            <span>Kadaluarsa {!isExpired && <Tooltip title='Batas waktu dokumen bisa ditandatangani.'><BsFillInfoSquareFill style={{ borderRadius: '4px' }} /></Tooltip>}</span>
                                            <p style={{ fontWeight: "600", fontSize: "16px" }}>
                                                {!isExpired ? expired : 'Sudah Kadaluarsa'}
                                            </p>
                                        </div>
                                    </Col>
                                }
                            </>
                        }
                        <Col>
                            <div>
                                <span>Pihak yang menandatangani</span>
                                <div>
                                    <Row md={2} sm={1} xs={1} className='g-2' style={{ marginTop: "8px" }}>
                                        {penandatangan.map((item, idx) => (
                                            <Col>
                                                <Card
                                                    key={idx}
                                                    style={{
                                                        width: "100px !important",
                                                        border: "0px",
                                                    }}
                                                >
                                                    <div className='d-flex'>

                                                        <div className='d-flex align-items-center'>{idx + 1}.</div>
                                                        <div className="my-auto ms-1">
                                                            <Avatar
                                                                // className="mr-3"
                                                                icon={<UserOutlined className="p-1" />}
                                                            />
                                                        </div>
                                                        <div className="ms-1">
                                                            <span style={{ fontWeight: "600" }}>
                                                                {item.name}
                                                            </span>
                                                            <br />
                                                            <span>{item.orgunit}</span>
                                                        </div>
                                                        <div className='mt-1 ms-auto'>
                                                            {item.issigned === "0" ? (

                                                                <Tag
                                                                    style={{
                                                                        fontWeight: "500",
                                                                        color: "#004680",
                                                                        fontSize: "14px",
                                                                        borderRadius: "4px",
                                                                        backgroundColor: "#DBE4FA",
                                                                        border: "0px",
                                                                    }}
                                                                >
                                                                    {isDesktop ? 'Belum menandatangani' : 'Belum'}

                                                                </Tag>
                                                            ) : (
                                                                <Tag
                                                                    style={{
                                                                        fontWeight: "500",
                                                                        color: "#058000",
                                                                        fontSize: "14px",
                                                                        borderRadius: "4px",
                                                                        backgroundColor: "#DDF0DD",
                                                                        border: "0px",
                                                                    }}
                                                                >
                                                                    {isDesktop ? 'Selesai menandatangani' : 'Selesai'}
                                                                </Tag>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card
                style={{
                    marginTop: "13px",
                    marginBottom: "20px",
                }}
            >
                <div
                    style={{
                        height: `150vh`,
                        backgroundColor: "#eee",
                    }}
                >
                    {document !== "" ? (
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                            <Viewer fileUrl={document}
                                initialPage={initialPage}
                                plugins={[
                                    pageNavigationPluginInstance,
                                    disableScrollPluginInstance,
                                    customCanvasPluginInstance
                                ]}
                                defaultScale={SpecialZoomLevel.PageFit}

                            />
                        </Worker>
                    ) : (
                        <></>
                    )}
                </div>
                <div
                    style={{
                        alignItems: "center",
                        paddingTop: "20px",
                    }}
                    className="d-flex justify-content-between align-items-center"
                >
                    <div>
                        <GoToPreviousPage>
                            {(props: RenderGoToPageProps) => (
                                <div
                                    style={{
                                        color: props.isDisabled ? "#96ccff" : "#357edd",
                                        border: "none",
                                        borderRadius: "4px",
                                        // color: "#ffffff",
                                        cursor: props.isDisabled ? "not-allowed" : "pointer",
                                        padding: "8px",
                                    }}
                                    disabled={props.isDisabled}
                                    onClick={props.onClick}
                                >
                                    <span className="p-auto">
                                        <FaArrowLeft /> Halaman Sebelumnya
                                    </span>
                                </div>
                            )}
                        </GoToPreviousPage>
                    </div>
                    <div style={!lanjutkan ? { paddingTop: '20px' } : {}}>
                        {!lanjutkan && !anotherSigner &&
                            <div className='text-center' style={{ maxWidth: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px', border: '1px dashed #293DB6', borderRadius: '4px', padding: '10px' }}>
                                {/* <span className='text-center'>Jika Anda setuju dengan isi halaman ini, Silakan centang checkbox dibawah ini.</span> */}
                                <Form.Group className='d-flex justify-content-center'>
                                    <Form.Check checked={yesNext} onChange={handleNetPage} label={<span className='text-center' style={{ fontSize: '14px' }}>Saya telah membaca dan menyetujui isi halaman ini</span>} />
                                </Form.Group>
                                {/* <span className="text-center">Kemudian Klik Halaman Selanjutnya</span> */}
                            </div>
                        }
                        <CurrentPageLabel>
                            {(props: RenderCurrentPageLabelProps) => (
                                <>
                                    {setPage(props.currentPage + 1)}
                                    <span className="d-flex justify-content-center">{`${props.currentPage + 1} dari ${props.numberOfPages}`}</span>
                                    {setLastPage(props.numberOfPages)}
                                </>
                            )}
                        </CurrentPageLabel>
                    </div>
                    {anotherSigner ? (
                        <div>
                            <div>
                                <GoToNextPage>
                                    {(props: RenderGoToPageProps) => (
                                        <div
                                            style={{
                                                color: props.isDisabled ? "#DADEDF" : "#357edd",
                                                border: "none",
                                                borderRadius: "4px",
                                                cursor: props.isDisabled ? "not-allowed" : "pointer",
                                                padding: "8px",
                                            }}
                                            className="ms-auto"
                                            disabled={props.isDisabled}
                                            // onClick={props.onClick}
                                            onClick={() => changePageAnotherSigner(props)}
                                        >
                                            <span>
                                                Halaman Selanjutnya <FaArrowRight />
                                            </span>
                                        </div>
                                    )}
                                </GoToNextPage>
                            </div>
                        </div>
                    ) : (
                        <div style={!yesNext ? { cursor: 'not-allowed' } : {}}>
                            <div style={!yesNext ? { pointerEvents: 'none', opacity: '0.7' } : {}}>
                                <GoToNextPage>
                                    {(props: RenderGoToPageProps) => (
                                        <div
                                            style={{
                                                color: !yesNext ? "DADEDF" : "#357edd",
                                                border: "none",
                                                borderRadius: "4px",
                                                // color: "#ffffff",
                                                cursor: props.isDisabled ? "not-allowed" : "pointer",
                                                padding: "8px",
                                            }}
                                            className="ms-auto"
                                            disabled={props.isDisabled}
                                            onClick={() => handleNext(props)}
                                        >
                                            <span>
                                                Halaman Selanjutnya <FaArrowRight />
                                            </span>
                                        </div>
                                    )}
                                </GoToNextPage>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        minHeight: "50px",
                        justifyContent: "center",
                    }}
                >
                    {wait ? (
                        <>
                            <span>
                                <InfoCircleTwoTone style={{ fontSize: "22px" }} />
                                <span className="ms-2">
                                    Menunggu Penandatangan lain selesai tandatangan
                                </span>
                            </span>
                        </>
                    ) : (
                        <>
                            {doneSign ? (
                                <>
                                    <img src={Check} alt="ini check" />
                                    <span className="ms-2">
                                        Dokumen telah Anda beri tandatangan
                                    </span>
                                </>
                            ) : (
                                <>{!disable ? (
                                    <>
                                        {!confirmLoading ? (
                                            <>
                                                {lanjutkan ? (
                                                    <Button
                                                        style={{ border: "0px", background: "#293DB6", marginBottom: '25px' }}
                                                        onClick={getReason}
                                                    >
                                                        Tandatangan dokumen ini
                                                    </Button>
                                                ) : (
                                                    <></>
                                                )}

                                            </>
                                        ) : (
                                            <Button
                                                style={{ border: "0px", background: "#293DB6" }}
                                                disabled
                                            >
                                                <div
                                                    className="spinner-border text-secondary"
                                                    style={{ width: "20px", height: "20px" }}
                                                    role="status"
                                                ></div>{" "}
                                                Tandatangan dokumen ini
                                            </Button>

                                        )}
                                    </>
                                ) : (
                                    <Button style={{ border: "0px", background: "#293DB6" }} disabled>
                                        Tandatangan dokumen ini
                                    </Button>
                                )}</>
                            )}
                        </>
                    )}

                </div>
            </Card>

            {/* Modal */}
            <Modal show={visible} centered={true} style={{ border: "0px" }}>
                <div style={{ position: "relative" }}>
                    <div
                        style={
                            confirmLoading
                                ? {
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(10, 10, 10, 0.5)",
                                    zIndex: "1",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: '0px'
                                }
                                : {}
                        }
                    >
                        <div
                            style={
                                confirmLoading
                                    ? { display: "block", textAlign: "center" }
                                    : { display: "none" }
                            }
                        >
                            <Spinner
                                animation="grow"
                                variant="light"
                                style={{ width: "50px", height: "50px" }}
                            />
                            <br />
                            <span
                                style={{
                                    fontSize: "18px",
                                    color: "whitesmoke",
                                    fontWeight: "600",
                                    marginTop: "32px",
                                }}
                            >
                                Dokumen sedang dalam proses penandatanganan
                            </span>
                            <br />
                            <span style={{ color: "whitesmoke" }}>
                                Mohon tunggu sebentar ini membutuhkan waktu sedikit lama
                            </span>
                        </div>
                    </div>
                    <Modal.Body>
                        <Container>
                            <p style={{ fontSize: "20px", fontWeight: "600" }}>
                                Setuju untuk menandatangani dokumen?
                            </p>
                            <p>
                                Masukkan 6 digit kode OTP yang dikirimkan ke email{" "}
                                <b>{email}</b> dan nomor <b>{nomor}</b>
                            </p>

                            <Container fluid>
                                <OTPInput
                                    value={otp}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    onChange={(e) => otpInput(e)}
                                    inputStyles={{
                                        borderRadius: "4.8px",
                                        border: "1px solid #c4c4c4",
                                    }}
                                />
                            </Container>
                            {wrongOtp ? (
                                <Alert
                                    variant="danger"
                                    closable
                                    showIcon
                                    style={{ marginTop: "28px" }}
                                    onClose={() => {
                                        setWrongOtp(false);
                                    }}
                                >
                                    {wrongText}
                                </Alert>
                            ) : (
                                <></>
                            )}
                            <div style={{ marginTop: "32px" }}>
                                {!confirmLoading ? (
                                    <>
                                        {!resend ? (
                                            <Button
                                                variant="outline-primary"
                                                style={{
                                                    color: "#c4c4c4",
                                                    fontSize: "16px",
                                                    marginRight: "20px",
                                                }}
                                                disabled
                                            >
                                                Kirim Ulang ({time})
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="outline-primary"
                                                style={{
                                                    color: "#293DB6",
                                                    fontSize: "16px",
                                                    marginRight: "20px",
                                                }}
                                                onClick={resendOTP}
                                            >
                                                kirim ulang
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal>
            {/* modal untuk reason */}
            <Modal show={popUp} onHide={CloseReason} centered={true}>
                <Modal.Body>
                    <Container>
                        <p style={{ fontSize: "20px", fontWeight: "600" }}>
                            Setuju untuk menandatangani dokumen?
                        </p>
                        <p>
                            Berikan alasan dan lokasi daerah tempat Anda memberi tanda tangan.
                        </p>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Lokasi <span style={{ color: "red" }}>*</span>
                            </Form.Label>

                            <Form.Control
                                type="text"
                                name="signlocation"
                                value={signlocation}
                                onChange={handleReason}
                                placeholder="Contoh: Jakarta"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check
                                name="reason"
                                type="checkbox"
                                value="Saya setuju untuk menandatangani dokumen ini"
                                onChange={handleCheck}
                                checked={checkBox}
                                label={<span style={{ cursor: 'pointer' }} onClick={(e) => { if (form.reason === '') { setCheckBox(true); setForm('reason', 'Saya setuju untuk menandatangani dokumen ini') } else { setForm('reason', ''); setCheckBox(false) } }}>Saya setuju untuk menandatangani dokumen ini</span>}
                            />
                        </Form.Group>
                        {wrongOtp ? (
                            <Alert
                                variant="danger"
                                closable
                                showIcon
                                style={{ marginTop: "28px" }}
                                onClose={() => {
                                    setWrongOtp(false);
                                }}
                            >
                                {wrongText}
                            </Alert>
                        ) : (
                            <></>
                        )}
                        <div
                            style={{ marginTop: "32px" }}
                            className="ms-auto d-flex justify-content-right"
                        >
                            <Button
                                variant="outline-primary"
                                onClick={CloseReason}
                                className="ms-auto"
                            >
                                Batal
                            </Button>
                            {!confirmLoading ? (
                                <>
                                    {!tombol ? (
                                        <Button
                                            style={{
                                                border: "0px",
                                                backgroundColor: "#084298",
                                                fontWeight: "600",
                                            }}
                                            onClick={setSignature}
                                            className="ms-2"
                                        >
                                            Ya, saya setuju
                                        </Button>
                                    ) : (
                                        <Button
                                            style={{
                                                border: "0px",
                                                backgroundColor: "#084298",
                                                fontWeight: "600",
                                            }}
                                            disabled
                                            className="ms-2"
                                        >
                                            Ya, saya setuju
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <Button
                                    style={{
                                        border: "0px",
                                        backgroundColor: "#084298",
                                        fontWeight: "600",
                                    }}
                                    className="ms-2"
                                    disabled
                                >
                                    <div
                                        className="spinner-border text-secondary"
                                        style={{ width: "20px", height: "20px" }}
                                        role="status"
                                    ></div>{" "}
                                    Ya, saya setuju
                                </Button>
                            )}
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SignEmail