import React from "react";
import { Link } from "react-router-dom";
import "react-bootstrap";
import logo from "../../img/logo.svg";
import { Container, Navbar } from "react-bootstrap";
import { FaArrowCircleLeft } from "react-icons/fa";

const Nav = () => {
  return (
    <>
      <Navbar>
        <Container fluid>
          <Link to={"/"} onClick={() => localStorage.clear()}>
            <p style={{ color: "#084298" }}>
              <FaArrowCircleLeft />
              <span style={{ fontWeight: "500" }}> Ke halaman Login</span>
            </p>
          </Link>
          <div className="text-center">
            {/* <Navbar.Brand className="text-center"> */}
            <img src={logo} alt="sevima" />
            <p>e-Sign System</p>
            {/* </Navbar.Brand> */}
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Nav;
