import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks";
import Ekyc from "./Ekyc";
import FormDiri from "./formDiri";
import Foto from "./fotoidentitas";
import Ringkasan from "./ringkasan";
import Success from "./Success";
import { useStep } from "react-hooks-helper";
import { Card, Col, Container, Row } from "react-bootstrap";
import Navbar from "../layout/navbar";
import { Steps } from "antd";
import { useNavigate, useSearchParams } from 'react-router-dom';
import "./index.css";
import jwtDecode from "jwt-decode";
import axios from "axios";

const { Step } = Steps;

const defaultData = {
  step: 0,
  name: "",
  gender: "",
  placeofbirth: "",
  dateofbirth: "",
  ktpphoto: "",
  npwpphoto: "",
  base64selfphoto: "",
  base64video: "",
  address: "",
  province: "",
  city: "",
  namaperusahaan: "",
  unitkerja: "",
  posisi: "",
  phone: "",
  email: "",
  password: "",
  ktp: "",
  npwp: "",
  orgunit: '',
  workunit: '',
  position: ''
};

const steps = [
  { id: "formdiri" },
  { id: "foto" },
  { id: "verification" },
  { id: "ringkasan" },
  { id: "success" },
];

const Index = () => {
  const [searchParams] = useSearchParams();
  const [token] = useState(searchParams.get('token'))
  const [type] = useState(searchParams.get('type'))
  const navigate = useNavigate()
  const [form, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  // get user data when users failed verification data
  const getUserTemp = () => {
    return axios.get('usertemp', {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    }).then((response) => {
      return response
    })
  }

  useEffect(() => {
    document.title = "Register";
  }, []);

  // params token
  useEffect(() => {
    if (!token) {
      navigate('/')
    } else {
      const encodeToken = jwtDecode(token)
      setForm('email', encodeToken.email)
      if (type === 'existing') {
        getUserTemp().then((response) => {
          const data = response.data.data
          console.log(response)
          setForm('all', data);
        })
      }
    }
  }, [])

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col md={6}>
          <Navbar />
          {form.step !== 4 ? (
            <>
              <p style={{ fontSize: "24px", fontWeight: "600" }}>Buat Akun</p>
              <Card
                style={{ marginBottom: "30px", border: "1px solid #F8F9FA" }}
              >
                <Card.Body className="d-flex justify-content-center">
                  <Container>
                    <Steps
                      labelPlacement="vertical"
                      size="small"
                      responsive={true}
                      // className="steps"
                      // style={{ backgroundColor: "red" }}
                      current={form.step}
                    >
                      <Step title="Data Diri" />
                      <Step title="Upload Kartu Identitas" />
                      <Step title=" Video Verifikasi" />
                      <Step title="Ringkasan" />
                    </Steps>
                  </Container>
                </Card.Body>
              </Card>
            </>
          ) : (
            <></>
          )}

          {(() => {
            // eslint-disable-next-line default-case
            switch (step.id) {
              case "formdiri":
                return (
                  <FormDiri
                    formData={form}
                    setForm={setForm}
                    navigation={navigation}
                    token={token}
                  />
                );
              case "foto":
                return (
                  <Foto
                    formData={form}
                    setForm={setForm}
                    navigation={navigation}
                    token={token}
                  />
                );
              case "verification":
                // setCurrent(2);
                return (
                  <Ekyc
                    formData={form}
                    setForm={setForm}
                    navigation={navigation}
                    token={token}
                  />
                );
              case "ringkasan":
                // setCurrent(3);
                return (
                  <Ringkasan
                    formData={form}
                    setForm={setForm}
                    navigation={navigation}
                    token={token}
                  />
                );
              case "success":
                return <Success formData={form} setForm={setForm} />;
            }
          })()}
        </Col>
      </Row>
    </Container>
  );
};
export default Index;
