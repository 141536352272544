import { Avatar, List, Skeleton, Input, message, Tag, Result } from "antd";
import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsPerson, BsPlusSquare } from "react-icons/bs";
import axios from "axios";
import { BsXLg } from "react-icons/bs";
import NavbarUser from "../../components/layout/NavbarUser";
import { useForm } from "../../hooks";
import SMS from "../../img/sms-tracking.svg"
import { FaPaperPlane } from "react-icons/fa";
import { SyncOutlined } from "@ant-design/icons";
import UserService from "../../api/user.service";
import AuthService from "../../api/auth.service";

const emailInput = {
  email: "",
};

const Index = () => {
  const payload = localStorage.getItem("payload");
  // const token = localStorage.getItem("token");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const refresh_token = localStorage.getItem("refresh_token");
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [contact, setContact] = useState([]);
  const [invite, setInvite] = useState(false);
  const [form, setForm] = useForm(emailInput);
  const { email } = form;
  const [people, setPeople] = useState([]);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmloading] = useState(false);
  const [checkEmail, setCheckEmail] = useState('')
  const [errorMessageCheck, setErrorMessageCheck] = useState('')
  const [modalError, setModalError] = useState(false);

  // getRole
  const getRole = () => {
    UserService.getUserMe().then((response) => {
      if (response.data.data[0].role === "0") {
        setIsAdmin(true);
      } else {
        navigate('/home')
      }
    })
  };

  // get contact
  const getContact = () => {
    UserService.getContactAll().then((response) => {
      setContact(response.data)
    })
  };

  // cek role and payload
  useEffect(() => {
    getRole();
    getContact();
  }, []);

  useEffect(() => {
    if (payload) {
      const decodeBase64 = atob(payload);
      const check = JSON.parse(decodeBase64);
      if (check.isVerified === "0") {
        navigate("/");
      }
    } else {
      navigate("/");
    }
  });
  useEffect(() => {
    document.title = "Kontak";
  }, []);

  const checkValidEmail = (params) => {
    setError('')
    const validEmail = () => {
      setLoading(true)
      UserService.checkEligibleEmail(params).then((response) => {
        setError('E-mail sudah terdaftar')
        setLoading(false);
      }, (error) => {
        // console.log(error)
        setError('')
        setPeople([...people, { email: params }]);
        setForm("email", "");
        setLoading(false);
      })
    };
    validEmail();
  };

  const handleEmail = (e) => {
    e.preventDefault();
    const validemail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log(validemail.test(form.email));
    if (validemail.test(form.email)) {
      const lastAt = form.email.split('@')
      const splitLastAt = lastAt[1].split('.');
      const domainNeed = ['ac', 'id']
      const emailSevima = ['sevima']
      const checkEmailSevima = emailSevima.every((i) => splitLastAt.includes(i));
      const result = domainNeed.every((i) => splitLastAt.includes(i));
      if (result === false) {
        if (checkEmailSevima) {
          checkValidEmail(form.email)
        } else {
          setError('Gunakan email dengan domain @kampus.ac.id (untuk calon client) dan email @sevima (untuk internal SEVIMA)')
        }
        // checkValidEmail(form.email)
      } else {
        checkValidEmail(form.email)
      }

    } else {
      setError("Alamat Email tidak valid");
    }
  };


  const handleInput = (e) => {
    setForm(e.target.name, e.target.value);
  };
  const deleteList = (email) => {
    const array = [...people];
    array.splice(email, 1);
    setPeople(array);
  };

  // handle invitation
  const handleInvite = () => {
    setConfirmloading(true);
    UserService.inviteUser(people).then((response) => {
      setInvite(false);
      setConfirmloading(false);
      setPeople([]);
      message.success("Email undangan sudah berhasil dikirimkan");
    }, (error) => {
      setConfirmloading(false);
    })
  };

  // check sertifikat
  const checkCertificate = async (params) => {
    setConfirmloading(true)
    AuthService.check_sertificate(params).then((response) => {
      setConfirmloading(false)
      getContact();
    }, (err) => {
      setModalError(true)
      setConfirmloading(false)
      if (err.response.data.data.backEndResponse.resultCode === '15')
        setErrorMessageCheck(`${params.name} (${params.orgunit}) masih menunggu verifikasi. \nJika sudah lebih dari 1x24 jam silahkan hubungi penyedia API.`)
      else if (err.response.data.data.backEndResponse.resultCode === '02')
        setErrorMessageCheck(`${params.name} (${params.orgunit}) Alamat email tidak terdaftar. \nSilahkan masukkan email yang valid.`)
      else if (err.response.data.data.backEndResponse.resultCode === '03')
        setErrorMessageCheck(`${params.name} (${params.orgunit}) Percobaan verifikasi e-KYC sudah mencapai batas maksimum (3 kali)`)
      else if (err.response.data.data.backEndResponse.resultCode === '04')
        setErrorMessageCheck(`${params.name} (${params.orgunit}) ${err.response.data.data.backEndResponse.resultDesc}`)
      else
        setErrorMessageCheck(`${params.name} (${params.orgunit}) ${err.response.data.data.backEndResponse.resultDesc}`)
    })
  }

  return (
    <>
      <Container fluid>
        <NavbarUser />
        <Container fluid style={{ marginTop: "32px" }}>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="d-flex" style={{ marginBottom: "24px" }}>
                <div className="d-flex align-items-center">
                  <span style={{ fontSize: "20px", fontWeight: "600" }}>
                    Kontak
                  </span>
                </div>
                <div className="ms-auto d-flex align-items-center">
                  <Button
                    style={{
                      background: "#293DB6",
                      border: "0px",
                      fontSize: "16px",
                    }}
                    onClick={() => setInvite(true)}
                  >
                    Kirim Undangan{" "}
                    <span>
                      <img src={SMS} alt='icon' />
                    </span>
                  </Button>
                </div>
              </div>
              <Card style={{ marginBottom: "32px" }}>
                <Card.Header className="justify-content-center bg-white">
                  <Form>
                    <Form.Group>
                      <Form.Control
                        // className="mb-3"
                        name="search"
                        value={search}
                        placeholder="&#xf007; Cari nama kontak terdaftar"
                        style={{
                          fontFamily: "Poppins,FontAwesome",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                        onChange={(e) => setSearch(e.target.value)}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form>
                </Card.Header>
                <div style={{ padding: "0px 10px 10px 10px" }}>
                  <List
                    itemLayout="horizontal"
                    // eslint-disable-next-line array-callback-return
                    dataSource={contact.filter((val) => {
                      if (search === "") {
                        return val;
                      } else if (
                        val.name.toLowerCase().includes(search.toLowerCase()) ||
                        val.email.toLowerCase().includes(search.toLowerCase()) ||
                        val.orgunit.toLowerCase().includes(search.toLowerCase())
                      ) {
                        return val;
                      }
                    })}
                    pagination={{ pageSize: 10 }}
                    renderItem={(item, key) => (
                      <List.Item key={key}>
                        <div style={{ width: "100%" }}>
                          <List.Item.Meta
                            avatar={
                              <div>
                                <Avatar
                                  size="large"
                                  style={{
                                    backgroundColor: "#9EC5FE",
                                    color: "#0A58CA",
                                    fontWeight: "500",
                                  }}
                                >
                                  {item.inisial}
                                </Avatar>
                              </div>
                            }
                            title={
                              <div style={{ marginLeft: "-10px" }}>
                                <span style={{ fontWeight: "600" }}>
                                  {item.name}
                                </span>{" "}
                                <br />
                                <span style={{ fontWeight: "400" }}>
                                  {item.orgunit} - {item.email}
                                </span>
                              </div>
                            }
                          />
                        </div>
                        {item.isverified === '0' && (
                          <>
                            {item.status === '0' ? (
                              <Tag
                                color="blue"
                                style={{
                                  fontWeight: "500",
                                  border: '0px',
                                  fontSize: "14px",
                                  height: "24px",
                                  borderRadius: "4px",
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                Undangan Terkirim
                              </Tag>
                            ) : (
                              <>
                                <Tag
                                  color="#FFEDB7"
                                  style={{
                                    fontWeight: "500",
                                    color: "#462B03",
                                    fontSize: "14px",
                                    height: "24px",
                                    borderRadius: "4px",
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  Belum Terverifikasi
                                </Tag>
                                <div style={{ cursor: 'pointer' }} onClick={() => { checkCertificate(item); setCheckEmail(item.email) }}>
                                  {item.email === checkEmail ? <SyncOutlined spin={confirmLoading ? true : false} style={{ fontWeight: 'bolder', fontSize: '20px' }} /> : <SyncOutlined spin={false} style={{ fontWeight: 'bolder', fontSize: '20px' }} />}

                                </div>
                              </>
                            )}
                          </>


                        )}
                      </List.Item>
                    )}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <Modal show={invite} onHide={() => { setInvite(); setError('') }} centered={true} size="md">
        <Modal.Header style={{ borderBottom: '0px' }} closeButton>
          <span style={{ fontSize: "20px", fontWeight: "600" }}>
            Kirim Undangan
          </span>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEmail}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukkkan Email"
                name="email"
                value={email}
                onChange={handleInput}
              />
              <Form.Text className="text-danger">{error}</Form.Text>
            </Form.Group>
          </Form>

          {people.length !== 0 ? (
            <>
              <p style={{ fontWeight: "600", fontSize: "14px" }}>
                Daftar email yang akan di invite
              </p>
              <Row md={1} className="g-2">
                {people.map((item, idx) => (
                  <Col key={idx}>
                    <Card
                      style={{
                        height: "50px",
                        padding: "2px",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ height: "50px" }}
                      >
                        <div className="ms-2">
                          <Avatar
                            className="d-flex align-items-center"
                            style={{ backgroundColor: "#9EC5FE" }}
                          >
                            <div>
                              <BsPerson
                                style={{ color: "#0A58CA", fontSize: "16px" }}
                              />
                            </div>
                          </Avatar>
                        </div>
                        <div className="ms-3">
                          <span style={{ fontWeight: "500" }}>
                            {item.email}
                          </span>
                        </div>
                        <div className="ms-auto p-2">
                          <BsXLg
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteList(idx)}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
              {loading ? (
                <Card
                  style={{ height: "50px", padding: "2px" }}
                  className="mt-2"
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "50px" }}
                  >
                    <div className="ms-2">
                      <Skeleton.Avatar active />
                    </div>
                    <div className="ms-2">
                      <Skeleton.Button
                        active
                        size="small"
                        style={{ width: "300px", height: "10px" }}
                      />
                    </div>
                  </div>
                </Card>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <Card style={{ height: "50px", padding: "2px" }}>
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "50px" }}
                  >
                    <div className="ms-2">
                      <Skeleton.Avatar active />
                    </div>
                    <div className="ms-2">
                      <Skeleton.Button
                        active
                        size="small"
                        style={{ width: "300px", height: "10px" }}
                      />
                    </div>
                  </div>
                </Card>
              ) : (
                <></>
              )}
            </>
          )}
          <div
            className="d-flex jusitfy-content-right"
            style={{ marginTop: "28px" }}
          >
            {confirmLoading ? (
              <Button className="ms-auto" style={{ background: '#293DB6', border: '0' }} disabled>
                <Spinner size="sm" animation="border" /> Kirimkan
              </Button>
            ) : (
              <Button
                className="ms-auto"
                disabled={people.length === 0 ? "disable" : ""}
                onClick={handleInvite}
                style={{ background: '#293DB6', border: '0' }}
              >
                <FaPaperPlane /> Kirimkan
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for error check certificate */}
      <Modal show={modalError} onHide={() => setModalError(false)} centered>
        <Modal.Body>
          <Result
            status="warning"
            title={
              <>
                <p>{checkEmail}</p>
                <p style={{ fontSize: '16px' }}>{errorMessageCheck}</p>
              </>
            }
          />
        </Modal.Body>
      </Modal>
      {/* end modal for error check certificate */}
    </>
  );
};

export default Index;
